import {
  Box,
  Button,
  Text,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  color,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { fundWalletDataAtom } from "../../../../atoms/store";
import { config } from "../../../../components/config";
import { impData } from "../../../../impData";
import cookie from "react-cookies";
const EnterTransPinModal = ({
  setFundedModal,
  transModal,
  closeTransModal,
  setFundFailureModal,
}) => {
  const [transactionPin, setTransacionPin] = useState("");
   
  const [fundingData, setFundingData] = useRecoilState(fundWalletDataAtom);
  useEffect(() => {
    const data = { transactionPin };
    setFundingData(oldFundingData=>{
      return {...oldFundingData, ...data}
    });
    
    console.log(fundingData)
  }, [transactionPin]);
  const transactionHandler = () => {
    axios
      .post(`${config.api_url}/api/v3/w3/topupcliq-fund-wallet`, fundingData, {
        headers: {
          authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then((res) => {
        const transData = res.data;
        console.log(transData)
        if (transData.status === "4000") {
         setFundFailureModal()
        }
        if (transData.status === "2000") {
         setFundedModal()
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <Modal
      w={{ base: "80%", md: "607px" }}
      isOpen={transModal}
      onClose={closeTransModal}
      closeOnOverlayClick={true}
    >
      <ModalOverlay
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.1)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <ModalContent width={{ base: "90%", md: "607px" }} margin={"auto"}>
        <ModalBody>
          <ModalCloseButton
          
            border="1px thin #E9EAEC"
            m="1rem"
            w="50px"
            h="50px"
            borderRadius={50}
            background="#E9EAEC"
          />
          <Box
            m="2.5rem 1rem"
            display={impData.flex}
            flexDirection="column"
            alignItems="center"
            gap="1rem"
          >
            <Text>Enter Transaction PIN</Text>
            <Text color="#21233780">Enter PIN to validate transactions</Text>
            <HStack>
              <PinInput
                focusBorderColor="#212337"
                type="number"
                placeholder=""
                onChange={(value) => setTransacionPin(value)}
              >
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  w="71px"
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
              </PinInput>
            </HStack>
            <Button
              w="full"
              fontSize={18}
              bg="#263B95"
              borderRadius={50}
              h="56px"
              color="#fff"
              fontWeight={600}
              onClick={transactionHandler}
            >
              Continue
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default EnterTransPinModal;
