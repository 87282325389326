import {
  Box,
  Button,
  createStandaloneToast,
  HStack,
  PinInputField,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  PinInput,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { config } from "../../../../components/config";
import { impData } from "../../../../impData";
import cookie from "react-cookies";
import Mtn from "../../../../components/networkPrint.js/mtn";
import Airtel from "../../../../components/networkPrint.js/airtel";
import { PulseLoader } from "react-spinners";

class PrintTransModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionPin: "",
      isLoading: false,
      readyPrint: false,
      readyVouchers: [],
    };

    this.onPrint = this.onPrint.bind(this);
    this.performPrint = this.performPrint.bind(this);
    this.toaster = createStandaloneToast();
    this.ToastContainer = this.toaster.ToastContainer;
    this.toast = this.toaster.toast;
  }

  onPrint() {
    this.setState({ isLoading: true });

    axios
      .post(
        `${config.api_url}/api/v3/w3/topupcliq-print-voucher`,
        {
          transactionPin: this.state.transactionPin,
          requestId: this.props.requestId,
        },
        {
          timeout: 1800000,
          maxContentLength: 1000000000,
          maxBodyLength: 1000000000,
          headers: {
            authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "2000") {
          if (res.data.data !== null) {
            this.setState({
              readyPrint: true,
              readyVouchers: res.data.data,
            });
            setTimeout(() => {
              this.performPrint(0, this.state.transactionPin, "TopUpBox").then(
                (res) => {
                  this.setState({
                    readyPrint: false,
                    readyVouchers: [],
                  });
                }
              );
            }, 400);
          } else {
            this.toast({
              description: res.data.description,
              status: "error",
              position: "top",
              duration: 9000,
              isClosable: false,
            });
            console.log(res.data.description);
          }
        } else {
          console.log(res.data.description);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((res) => {
        this.setState({ isLoading: false });
      });
  }

  performPrint = async (id, pin, description) => {
    if (this.state.readyVouchers[0].network == "MTN") {
      let print = new Mtn(
        id,
        pin,
        description,
        this.state.readyVouchers,
        "TopupCliq"
      );
      print.Print();
    } else if (this.state.readyVouchers[0].network == "AIRTEL") {
      let print = new Airtel(
        id,
        pin,
        description,
        this.state.readyVouchers,
        "TopupCliq"
      );
      print.Print();
    } else if (
      this.state.readyVouchers[0].network == "NINEMOBILE" ||
      this.state.readyVouchers[0].network == "GLO"
    ) {
      let print = new Airtel(
        id,
        pin,
        description,
        this.state.readyVouchers,
        "TopupCliq"
      );
      print.Print();
    }
  };

  render() {
    return (
      <Modal
        w={{ base: "80%", md: "607px" }}
        isOpen={this.props.openTransPinModal}
        onClose={this.props.closeTransPinModal}
        closeOnOverlayClick={true}
      >
        <ModalOverlay
          background="rgba(33, 35, 55, 0.8)"
          style={{
            background: "rgba(33, 35, 55, 0.8)",
            backdropFilter: "blur(3px)",
          }}
          backdropBlur="5px"
        />
        <ModalContent width={{ base: "90%", md: "607px" }} margin={"auto"}>
          <ModalBody>
            <ModalCloseButton
              border="1px thin #E9EAEC"
              m="1rem"
              w="50px"
              h="50px"
              borderRadius={50}
              background="#E9EAEC"
            />
            <this.ToastContainer />
            <Box
              m="2.5rem 1rem"
              display={impData.flex}
              flexDirection="column"
              alignItems="center"
              gap="1rem"
            >
              <Text>Enter Transaction PIN</Text>
              <Text color="#21233780">
                Enter PIN to validate Print for {this.props.narration}
              </Text>
              <HStack>
                <PinInput
                  focusBorderColor="#212337"
                  type="number"
                  placeholder=""
                  value={this.state.transactionPin}
                  onChange={(value) => this.setState({ transactionPin: value })}
                >
                  <PinInputField
                    background="#E9EAEC6B"
                    h="71px"
                    w="71px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    borderRadius={16}
                  />
                  <PinInputField
                    background="#E9EAEC6B"
                    h="71px"
                    w="71px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    borderRadius={16}
                  />
                  <PinInputField
                    background="#E9EAEC6B"
                    h="71px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    w="71px"
                    borderRadius={16}
                  />
                  <PinInputField
                    background="#E9EAEC6B"
                    h="71px"
                    w="71px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    borderRadius={16}
                  />
                </PinInput>
              </HStack>
              {this.state.isLoading === false ? (
                <Button
                  w="full"
                  fontSize={18}
                  bg="#263B95"
                  borderRadius={50}
                  h="56px"
                  color="#fff"
                  fontWeight={600}
                  onClick={(e) => {
                    this.onPrint();
                  }}
                >
                  Continue
                </Button>
              ) : (
                <PulseLoader color="blue" loading={this.state.isLoading} />
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default PrintTransModal;
