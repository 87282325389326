import React from "react";
import {Link as RouterLink} from "react-router-dom"
import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import userVector from "../../assets/illustation-right.png";
import { impData } from "../../impData";
const Intro = () => {
  return (
    <Box
      style={{
        top: 0,
        background: `linear-gradient(to bottom left, rgba(33, 173, 227, 0.15) 16.19%, #fff 73.81%`,
        // background:
        // 	"linear-gradient(44.35deg, rgba(172, 232, 255, 0.21) 16.19%, rgba(38, 59, 149, 0.0462) 163.62%)",
        display:impData.flex,
        minHeight: "80vh",
        alignItems: "center",
      }}
      w="full"
      px={{ base: "10px", md: "20" }}
    >
      <Box
       
        display={impData.flex}
        alignItems="center"
        w="100%"
        flexDirection={{ base: "column", md: "row" }}
        // px="40px"
        // gap="100px"
        justifyContent={{ base: "center", md: "space-between" }}
      >
        <Box
          className="left"
          h={{ base: "", md: "100%" }}
          w={{ base: "100%", md: "55%" }}
        >
          <Text
            fontSize={{ base: "40px", md: "56px" }}
            color="#212337"
            fontWeight="bold"
            letterSpacing="2%"
          >
            Buy, Sell, Connect
          </Text>
          <Text
            fontSize={{ base: "40px", md: "56px" }}
            color="#212337"
            fontWeight="bold"
            letterSpacing="2%"
          >
            Refer,
            <span
              color=""
              style={{
                fontSize: 56,
                color: "#253d95",
              }}
            >
              earn.
            </span>
          </Text>
          <Text
            fontSize="16px"
            width={{ base: "80%", md: "55%" }}
            style={{ letterSpacing: "2%", fontWeight: "500" }}
          >
            Buy and sell on our platform, and also get referral bonuses when you
            tell your friends about us.
          </Text>
          <Link
            style={{
              display: impData.flex,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 50,
              marginTop: "1rem",
              width: "180px",
              height: "56px",
              color: "#fff",
              fontWeight: "600",
              background: "#263B95",
            }}
            as={RouterLink}
            to="/dashboard"
          >
            Get Started
          </Link>
        </Box>
        <Box
          width={{ base: "100%", md: "50%" }}
          h="full"
          display={impData.flex}
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src={userVector}
            alt=""
            w="100%"
            my="auto"
            position={"relative"}
            style={{
              width: "auto",
            }}
            height={{ base: "300px", md: "454.73px" }}
          />
        </Box>
      </Box>
    </Box>
    
  );
};

export default Intro;
