import {
  Box,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalHeader,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import DashModal from "../../../../components/DashModal";
import PrintAirtimeDropDown from "../../../../components/PrintAirtimeDropDown";
import { CloseIcon } from "@chakra-ui/icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { impData } from "../../../../impData";
import AirtimeForm from "./AirtimeForm";
import DataBundlesForm from "./DataBundlesForm";
import { chooseNetworkAtom, purchaseVoucherAtom } from "../../../../atoms/store";
import SMEDataForm from "./SMEDataForm";
const BuyAirtimeModal = ({
  openModal,
  closeModal,
  setOpenModal,
  setOpenTransPinModal,
}) => {
  const networkName = useRecoilValue(chooseNetworkAtom);

  return (
    <DashModal   className=" h-[80vh] overflow-y-auto" openModal={openModal}
      onClose={closeModal}
      closeOnEsc={true}
      closeOnOverlayClick={true}>
      <Box
        as="div"
        w="full"
        px="10px"
       
        display={impData.flex}
        alignItems="center"
        justifyContent="space-between"
      >
        <PrintAirtimeDropDown value={""} />
        <Button
          as="button"
          border="1px thin #E9EAEC"
          m="1rem"
          w="50px"
          h="50px"
          borderRadius={50}
          background="#E9EAEC"
          onClick={closeModal}
        >
          <CloseIcon />
        </Button>
      </Box>
      {/* <Box as="div" w="full"> */}
      <Tabs variant="line" isFitted mt="">
        <TabList>
          <Tab py="1rem" _selected={{ borderBottom: "4px solid #263B95" }}>Airtime</Tab>
          <Tab py="1rem" _selected={{ borderBottom: "4px solid #263B95" }}>
            Data Bundles
          </Tab>
          <Tab py="1rem" _selected={{ borderBottom: "4px solid #263B95" }}>
            SME Data
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AirtimeForm />
          </TabPanel>
          <TabPanel>
            <DataBundlesForm />
          </TabPanel>
          <TabPanel>
            <SMEDataForm />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* </Box> */}
    </DashModal>
  );
};

export default BuyAirtimeModal;
