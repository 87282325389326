import React from "react";
import { Box, Image } from "@chakra-ui/react";
import { impData } from "../../impData";
import leftBg from "../../assets/authLeftBg.png"
const AuthPagesWrapper = ({children}) => {
  return (
    <Box
      fontFamily="clash"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      display={impData.flex}
      flexDirection={{ base: "column", md: "row", lg: "row" }}
    >
      <Box
        h={{ base: "40%", md: "full" }}
        w={{ base: "full", md: "50%", lg: "45%" }}
      >
        <Image src={leftBg} h="full" w="full" />
      </Box>
      {children}
    </Box>
  );
};

export default AuthPagesWrapper;
