import React, { useState } from "react";
import "../index.css";
import { impData } from "../impData";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../assets/Icons/Logo.png";

import {
  useDisclosure,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
  Box,
  HStack,
  Flex,
  LinkBox,
  Link,
  IconButton,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import { useRecoilState } from "recoil";
import { landingpageSideBarAtom } from "../atoms/store";

const Header = ({ setShowSidebar, showSidebar }) => {
  return (
    <>
      <Box w="100vw" h="15%" bg="#fff" py="5" px={{ base: "10px", md: "10" }}>
        <Box
          display={impData.flex}
          gap={{ base: "", md: "8px", lg: "70px" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display={impData.flex} alignItems="center">
            <img src={Logo} style={{ width: "auto", height: "48px" }} alt="" />
          </Box>

          <Box display={{ base: "none", md: "flex" }} flex={1}>
            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box d="flex" flexDir="row">
                {navLinks.map((item, i) => (
                  <Link
                    key={i}
                    href={item.link}
                    className="navlink"
                    _activeLink={{
                      color: "#212337",
                    }}
                    textDecoration="none"
                    style={{
                      fontSize: "16px",
                      textDecoration: "none",
                      color: "#21233760",
                      marginRight: "10px",
                    }}
                  >
                    {item.text}
                  </Link>
                ))}
                <Dropdown />
              </Box>
            </Flex>
          </Box>

          <Box className="nav-right" display={{ base: "none", md: "flex" }}>
            <Link
              px="1em"
              py="0.5em"
              lineHeight="19px"
              fontSize={"16px"}
              fontWeight="500"
              className="navlink-right"
              borderWidth={"1.5px"}
              borderColor="slategray"
              height="50px"
              width="100px"
              color="#212337"
              borderRadius={50}
              display={impData.flex}
              justifyContent="center"
              alignItems="center"
              to="/login"
              as={RouterLink}
              textDecoration="none"
              _active={{
                background: "#253d95",
                color: "#fff",
                border: "none",
              }}
              style={{
                textDecoration: "none",
                border: "1.5px solid  #E9EAEC ",
              }}
            >
              Log in
            </Link>
            <Link
              as={RouterLink}
              to="/register"
              textDecoration="none"
              height="50px"
              width="100px"
              fontWeight="500"
              px="1em"
              display={impData.flex}
              justifyContent="center"
              alignItems="center"
              py="0.5rem"
              fontSize="16px"
              mx="10px"
              color="white"
              bg="#263B95"
              borderRadius={50}
              _active={{
                background: "none",

                border: "1.5px solid  #E9EAEC ",

                color: "#fff",
              }}
              style={{
                textDecoration: "none",
              }}
            >
              Register
            </Link>
          </Box>
          <IconButton
            bg="none"
            border="0"
            outlineColor="none"
            outlineOffset="none"
            size={"md"}
            icon={showSidebar ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ base: impData.flex, md: "none", lg: "none" }}
            onClick={setShowSidebar(true)}
          />
        </Box>
      </Box>
    </>
  );
};

export default Header;

function Dropdown() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Menu isOpen={isOpen}>
      {/* <MenuButton
        variant="ghost"
        mx={1}
        py={[1, 2, 2]}
        px={4}
        borderRadius={5}
        _hover={{ bg: useColorModeValue("gray.100", "gray.700") }}
        aria-label="Courses"
        fontWeight="normal"
        color="#212337"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        More Options {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </MenuButton> */}
      <MenuList
        onMouseEnter={onOpen}
        width="150px"
        marginTop={"5"}
        onMouseLeave={onClose}
      >
        {dropDownItems.map((item, i) => (
          <MenuItem key={i}>
            <Link
              style={{
                textDecoration: "none",
              }}
            >
              {" "}
              {item.text}
            </Link>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

const dropDownItems = [
  {
    link: "",
    text: "Buy Airtime",
  },
  {
    link: "",
    text: "Buy Data Bundle",
  },
  {
    link: "",
    text: "Cable Tv Subscription",
  },
  {
    link: "",
    text: "Electricity Bills",
  },
  {
    link: "",
    text: "Print Recharge Card",
  },
];

const navLinks = [
  {
    link: "/",
    text: "Home",
  },
  {
    link: "/howitworks",
    text: "How It Works",
  },
  {
    link: "/aboutus",
    text: "About Us",
  },
  {
    link: "/faq",
    text: "FAQ",
  },
];
