import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import "./App.css";
import AboutUsPage from "./pages/AboutUs/AboutUsPage";
import FAQ_Page from "./pages/FAQ_Page/FAQ_Page";
import HomePage from "./pages/HomePage/HomePage";
import HowItWorksPage from "./pages/HowItWorks/HowItWorksPage";
import LoginPage from "./pages/Onboardingpages/LoginPage";
import RegisterPage from "./pages/Onboardingpages/RegisterPage";
import ContactUsPage from "./pages/ContactusPage/ContactUsPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import PrintAirtime from "./pages/PrintAirtime/PrintAirtime";
import AirtimeAndBills from "./pages/AirtimeAndBillsPage/AirtimeAndBills";
import SendMoneyPage from "./pages/SendMoney/SendMoneyPage";
import TransactionsPage from "./pages/Transactions/TransactionsPage";
import ReferralPage from "./pages/Referral/ReferralPage";
import SettingsPage from "./pages/Settings/SettingsPage";
import EmailAuthentication from "./pages/Onboardingpages/EmailAuthenticationPage";
import ResetPassword from "./pages/Onboardingpages/ResetPassword";
import cookie from "react-cookies";
import { ChakraProvider } from "@chakra-ui/react";
import Fonts, { theme } from "./components/GlobalStyle";
import ForgetPassword from "./pages/Onboardingpages/ForgetPassword";
function App() {
  return (
    <div className="w-full h-full">
      <ChakraProvider theme={theme}>
        <Fonts />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} exact />
            <Route path="/howitworks" element={<HowItWorksPage />} />
            <Route path="/aboutus" element={<AboutUsPage />} />
            <Route path="/faq" element={<FAQ_Page />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/contactus" element={<ContactUsPage />} />

            {/* dashboards */}
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/airtimeandbills" element={<AirtimeAndBills />} />
            <Route path="/printairtime" element={<PrintAirtime />} />
            <Route path="/sendmoney" element={<SendMoneyPage />} />
            <Route path="/transactions" element={<TransactionsPage />} />
            <Route path="/referrals" element={<ReferralPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/verifyemail" element={<EmailAuthentication />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}

export default App
