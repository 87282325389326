import {
    Box,
    Button,
    Text,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    PinInput,
    PinInputField,
    createStandaloneToast,
    FormControl,
  } from "@chakra-ui/react";
  import axios from "axios";
  import React, { useEffect, useState } from "react";
  import { config } from "../../../components/config";
  import { impData } from "../../../impData";
   import { ToastContainer, toast } from "react-toastify";
  import cookie from "react-cookies";
import { PulseLoader } from "react-spinners";
  const ResetPinModel = ({
  
    showTransPinModal,
    closeTransPinModal,
  }) => {
    const [transactionPin, setTransactionPin] = useState("");
    const [otp, setOtp] = useState('')
    const [loading, setLoading] = useState(false)
    const [requestId, setRequestId] = useState('')
    // const { toast, ToastContainer } = createStandaloneToast();
    useEffect(() => {
        if(showTransPinModal){
            axios
            .post(
              `${config.api_url}/api/v3/w3/topupcliq-forgot-pin`,
              {
              },
              {
                headers: {
                  authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
                },
              }
            )
            .then(res => {
              console.log(res.data);
              if (res.data.status == '2000') {
                toast.success(res.data.description, )
               setRequestId(res.data["data"]["requestId"])
              } else {
                console.log(res.data)
                toast.error(res.data.description)
                setTimeout(() => {
                  toast.error(res.data.description)
                });
              }
            })
            .catch(err => {
              console.log(err);
              toast.error("Error", {
                position: toast.POSITION.TOP_CENTER
              });
            })
            .finally(res => {
              setLoading(false);
            });
        }
       
    }, [showTransPinModal]);
    const chnageTransactionpinhandler = () => {
        setLoading(true);
        axios
          .post(
            `${config.api_url}/api/v3/w3/topupcliq-reset-pin`,
            {
              "otp": otp,
               "pin": transactionPin,
               "requestId": requestId
            },
            {
              headers: {
                authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
              },
            }
          )
          .then(res => {
            console.log(res.data);
            if (res.data.status === '2000') {
              toast.success(res.data.description, )
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            } else {
              console.log(res.data)
              toast.error(res.data.description)
              setTimeout(() => {
                toast.error(res.data.description)
              });
            }
          })
          .catch(err => {
            console.log(err);
            toast.error("Error", {
              position: toast.POSITION.TOP_CENTER
            });
          })
          .finally(res => {
            setLoading(false);
          });
      }
  
  
    return (
      <Modal
        w={{ base: "80%", md: "607px" }}
        isOpen={showTransPinModal}
        onClose={closeTransPinModal}
        closeOnOverlayClick={true}
      >
        <ModalOverlay
          background="rgba(33, 35, 55, 0.1)"
          style={{
            background: "rgba(33, 35, 55, 0.1)",
            backdropFilter: "blur(3px)",
          }}
          backdropBlur="5px"
        />
        <ModalContent width={{ base: "90%", md: "607px" }} margin={"auto"}>
          <ModalBody>
            <ModalCloseButton
              border="1px thin #E9EAEC"
              m="1rem"
              w="50px"
              h="50px"
              borderRadius={50}
              background="#E9EAEC"
            />
            <ToastContainer />
            <Box
              m="2.5rem 1rem"
              display={impData.flex}
              flexDirection="column"
              alignItems="center"
              gap="1rem"
            >
              <Text>Reset Pin</Text>
              <Text color="#21233780">Enter OTP set to your mail with other detaials</Text>
              <div className=" w-full my-3">
              <FormControl>
                <input
                    onChange={(e) => setOtp(e.target.value)}
                    className="py-[10px] border-b-[2px] focus:border-b-[#212337]  border-b-[#212337] placeholder:text-[#212337] placeholder:text-[600] px-[10px]  block w-full "
                    name="otp"
                    value={otp}
                    maxLength={5}
                    placeholder="Otp"
                />
              </FormControl>
              </div>
              <HStack>
                <PinInput
                  focusBorderColor="#212337"
                  type="number"
                  placeholder=""
                  value={transactionPin}
                  onChange={(value) => setTransactionPin(value)}
                >
                  <PinInputField
                    background="#E9EAEC6B"
                    h="71px"
                    w="71px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    borderRadius={16}
                  />
                  <PinInputField
                    background="#E9EAEC6B"
                    h="71px"
                    w="71px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    borderRadius={16}
                  />
                  <PinInputField
                    background="#E9EAEC6B"
                    h="71px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    w="71px"
                    borderRadius={16}
                  />
                  <PinInputField
                    background="#E9EAEC6B"
                    h="71px"
                    w="71px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    borderRadius={16}
                  />
                </PinInput>
              </HStack>
              <Button
                w="full"
                fontSize={18}
                bg="#263B95"
                borderRadius={50}
                h="56px"
                color="#fff"
                fontWeight={600}
                onClick={chnageTransactionpinhandler }
              >
                {
              loading ? <PulseLoader color="#fff" loading={loading} /> : "Change"
            } 
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };
  export default ResetPinModel;
  