import {
  Box,
  Text,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { impData } from "../../impData";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const Referralshistory = () => {
  return (
    <Box
      w="full"
      fontFamily="clash"
      overflowX={{ base: "auto", md: "auto", lg: "hidden" }}
    >
      <Table mt="1rem">
        <Thead>
          <Tr borderBottom="2px solid " fontFamily="clash">
            <Th fontWeight="bold" fontSize={18} fontFamily="clash">
              Users
            </Th>
            <Th fontWeight="bold" fontFamily="clash" fontSize={18}>
              Status
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* <Tr>
            <Td>
              <Box
                fontFamily="clash"
                display={impData.flex}
                alignItems="center"
                gap="10px"
              >
                <div className=" flex justify-center bg-[#E9EAEC] text-[#263B95] font-semibold items-center w-[44px] h-[44px] rounded-full">
                  FE
                </div>
                <div className="flex flex-col gap-1">
                  <h1 className="font-bold text-[18px] ">Freeborn Ehirhere</h1>
                  <Text fontSize={14} fontWeight={600} color="#212337">
                    Freebornehirhere@gmail.com
                  </Text>
                </div>
              </Box>
            </Td>
            <Td>
              <Box display={impData.flex} flexDir="column" gap="10px">
                <h1 className="font-bold text-[18px] flex items-center gap-[10px]">
                  Performed a transaction{" "}
                  <span>
                    <IoCheckmarkCircleOutline color="green" fontSize={20} />
                  </span>
                </h1>
                <Text fontSize={14} fontWeight={600} color="#212337">
                  You earned 25% from this referral
                </Text>
              </Box>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Box
                fontFamily="clash"
                display={impData.flex}
                alignItems="center"
                gap="10px"
              >
                <div className=" flex justify-center bg-[#E9EAEC] text-[#263B95] font-semibold items-center w-[44px] h-[44px] rounded-full">
                  FE
                </div>
                <div className="flex flex-col gap-1">
                  <h1 className="font-bold text-[18px] ">Freeborn Ehirhere</h1>
                  <Text fontSize={14} fontWeight={600} color="#212337">
                    Freebornehirhere@gmail.com
                  </Text>
                </div>
              </Box>
            </Td>
            <Td>
              <Box display={impData.flex} flexDir="column" gap="10px">
                <h1 className="font-bold text-[18px] flex items-center gap-[10px]">
                  Haven’t Performed a transaction
                  <CancelIcon />
                </h1>
                <Text fontSize={14} fontWeight={600} color="#212337">
                  No earnings from this referral yet
                </Text>
              </Box>
            </Td>
          </Tr> */}
        </Tbody>
      </Table>
    </Box>
  );
};

const CancelIcon = ({ props }) => (
  <svg
    width={16}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 15.154c3.667 0 6.667-3 6.667-6.667S11.667 1.82 8 1.82s-6.667 3-6.667 6.667 3 6.667 6.667 6.667ZM6.113 10.373 9.887 6.6M9.887 10.373 6.113 6.6"
      stroke="#FF4040"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Referralshistory;
