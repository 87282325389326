import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import DashHeader from "./DashHeader";
import DashSidebar from "./Templates/DashSideBar/DashSidebar";
 
const DashboardWrapper = ({ children, header, userDetails }) => {
  const [userdetails, setUserDetails] = useState(userDetails);
  const [openSideBar, setOpenSideBar] = useState(false);

  return (
    <>
      <div
        className="w-screen sm:h-full md:h-full clash-font-family"
        style={{
          backgroundColor: " #FAFAFA",
        }}
      >
        <DashSidebar
          userDetails={userDetails}
          openSideBar={openSideBar}
          setOpenSidebar={setOpenSideBar}
        />
        <div className="h-full bg-[#fafafa] overflow-y-visible md:ml-[290px] sm:[ml-0] overflow-x-hidden z-[1000]">
          <DashHeader
            header={header}
            userDetails={userdetails}
            setOpenSidebar={setOpenSideBar}
          />
          {children}
        </div>
      </div>
    </>
  );
};

export default DashboardWrapper;
