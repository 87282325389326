import { Box, FormControl, FormLabel, PinInputField, HStack, PinInput, Button, } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { impData } from "../../../../impData";
import { config } from "../../../../components/config";
import { chooseNetworkAtom } from "../../../../atoms/store";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import { useRecoilValue } from "recoil";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import { PulseLoader } from "react-spinners";

const SMEDataForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [activePlan, setActivePlan] = useState({});
  const [plan, setPlan] = useState([]);
  const network = useRecoilValue(chooseNetworkAtom);
  const [amount, setAmount] = useState(0)
  const [dataPlan2, setDataPlan2] = useState([])
  const netValue =  network == '9mobile' ? 'ETISALAT': network == 'Airtel' ? "AIRTEL":network
  const onSubmit = () => {
    
    setLoading(true);
    const params = {
      network:
        network == '9mobile' ? 'ETISALAT' : network,
      transactionPin: pin,
      plan_id: activePlan?.planId,
      beneficiary: phoneNumber,
      amount: activePlan?.planPrice,
      deviceId: "",
    };
    console.log(params);
  
    axios
      .post(config.api_url + '/api/v3/w3/mobile-sme', params, {
        headers: {
          Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then(res => {
        console.log(res.data);
        if (res.data.status == '2000' || res.data.status == '201') {
          toast.success(res.data.description)
          setTimeout(() => {
            navigate("/dashboard")
          }, 1000);
        } else {
          toast.error(res.data.description);
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("You are unable to buy Data at this moment, Try again late.");
      })
      .finally(res => {
        setLoading(false)
      });
  }

 

  useEffect(() =>{
  
    setLoading(true)
    axios
      .get(config.api_url + '/api/v3/w3/sme-plan', {
        headers: {
          Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      }).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
         setDataPlan2(response.data.data[netValue])
         
         }else{  
        
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Eee failed. Try again',)
          }
    )
  
  
 },[])

  useEffect(() => {
    console.log(activePlan)
  }, [activePlan])

  
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box
        w="full"
        display={impData.flex}
        flexDirection="column"
        gap="2rem"
        p="1rem 1rem"
      >
      
        <FormControl>
          <Select
            onChange={item => setActivePlan(item.value)}
            placeholder={"Select Plan"}
            options={dataPlan2.map((item) => ({ label: item?.planName, value: item }))}
          />
          <FormLabel fontSize={12} color="#263B95"
            className="mt-3">
            Phone number
          </FormLabel>
          <input
            type="text"
            pattern=""
            value={phoneNumber}
            onChange={(e) =>setPhoneNumber(e.target.value)}
            className="pb-3 placeholder:text-[18px] border-0 placeholder:text-[#21233740] outline-none placeholder:font-bold border-b-2 border-b-[#212337] focus:border-b-[#263B95] block w-full "
            placeholder="eg  0900 000 0000"
          />
          <FormLabel fontSize={12} color="#263B95"
            className="mt-3">
            Amount (NGN)
          </FormLabel>
          <input
            type="text"
            value={activePlan?.planPrice}
            disabled={true}
            className="pb-3 placeholder:text-[18px] border-0  placeholder:text-[#21233740] outline-none placeholder:font-bold border-b-2 border-b-[#212337] focus:border-b-[#263B95]  block w-full "
            placeholder="0"
          />

          <FormLabel fontSize={12} color="#263B95"
            className="mt-3"
          >Transaction Pin</FormLabel>
          <HStack>
            <PinInput
              focusBorderColor="#212337"
              type="number"
              manageFocus={true}
              placeholder=""
              value={pin}
              onChange={(value) => setPin(value)}
            >
              <PinInputField
                background="#E9EAEC6B"
                h="50px"
                w="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                h="50px"
                w="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                mr="10px"
                h="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                w="50px"
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                h="50px"
                w="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
            </PinInput>
          </HStack>
        </FormControl>
        <Button
          fontSize={18}
          bg="#263B95"
          borderRadius={50}
          h="56px"
          color="#fff"
          fontWeight={600}
          onClick={onSubmit}
        >
          {
            loading ? <PulseLoader color="#fff" loading={loading} /> : "Buy"
          }
        </Button>
      </Box>
    </>
  );
};

export default SMEDataForm;
