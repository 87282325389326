import { Box, Text, Link } from "@chakra-ui/react";
import React from "react";

import bgImg from "../../../assets/an_bg.png";
const EnquirySection = () => {
  return (
    <Box w="full" px={{base: "", md:"20"}} py="10">
      <Box
        p={{base: "1rem",md: "20"}}
        mt="1rem"
        style={{
          color: "#fff",
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          minHeight: 382,
          display: "block",
        }}
        backgroundRepeat="no-repeat"
        backgroundSize={{
          base: "cover", md:"cover"

        }} 
      >
        <Text fontSize={{base: "50px", md:"56px"}} fontWeight="700">
          Still have questions?
        </Text>
        <Text mt="10px" style={{ fontSize: "18px", fontWeight: "bold" }}>
          Common questions, starightforward answers
        </Text>
        <Link
          d="block"
          bg="#fff"
          mt="1rem"
          fontSize="16px"
          w="180px"
          style={{
            color: "#263B95",
            display: "block",
            width: "fit-content",
            fontWeight: "bold",
          }}
          px="1rem"
          py="0.7rem"
          borderRadius="3xl"
        >
          See our answers
        </Link>
      </Box>
    </Box>
  );
};

export default EnquirySection;
