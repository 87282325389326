
import { Image } from "@chakra-ui/react";
import React from "react";
import companyLocation from "../../assets/companyLocation.png";
import LandingPageWrappper from "../../components/LandingPageWrappper";
import Intro from "./partials/Intro";
import LearnMoreSection from "../HomePage/LearnMoreSection"
import SendMsgComponent from "./partials/SendMsgComponent";
const ContactUsPage = () => {
	return (
		<LandingPageWrappper>
			<Intro />
			<SendMsgComponent />
			<img src={companyLocation} style={{width:"100%",height: 470, marginTop:" 1rem ", marginBottom: "1rem", display:"block"}} />
			<LearnMoreSection/>
		</LandingPageWrappper>
	);
};

export default ContactUsPage;
