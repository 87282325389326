import React from "react";
import jsPDF from "jspdf";
class Airtel {
  constructor(id, pin, description, pins, businessName) {
    console.log(pins);
    console.log(businessName);
    this.state = {
      pins: pins,
      id: id,
      TOTP_Code: pin,
      description: description,
      businessName: businessName,
    };
  }

  Print() {
    var doc = new jsPDF({
      encryption: {
        userPassword: this.state.TOTP_Code,
        ownerPassword: this.state.TOTP_Code,
        userPermissions: ["print", "modify", "annot-forms"],
      },
      orientation: "p",
      unit: "pt",
    });
    //Dimension of A4 in pts: 595 × 842
    doc.setFontSize(6);
    if (
      this.state.pins[0].unitAmount == 100 ||
      this.state.pins[0].unitAmount == 200
    ) {
    } else {
      doc.setCharSpace(2);
    }
    var pageWidth = 595;
    var pageHeight = 842;
    var pageMargin = 20;

    pageWidth -= pageMargin - 10;
    pageHeight -= pageMargin;

    var cellMargin = 5;
    var cellWidth =
      this.state.pins[0].unitAmount == 100 ||
      this.state.pins[0].unitAmount == 200
        ? 140
        : 285;
    var cellHeight = 80;

    var startX = pageMargin - 10;
    var startY = pageMargin;

    function createCard(item, businessName, i) {
      // doc.getTextDimensions(item.Name); turncate or split string if you needed
      if (startY >= pageHeight - 20) {
        doc.addPage();
        startY = pageMargin; // Restart height position
      }
      doc.rect(startX, startY + 10, cellWidth, cellHeight - 10);

      function convertStringToArray(str, maxPartSize) {
        const chunkArr = [];
        let leftStr = str;
        do {
          chunkArr.push(leftStr.substring(0, maxPartSize));
          leftStr = leftStr.substring(maxPartSize, leftStr.length);
        } while (leftStr.length > 0);

        return chunkArr;
      }

      function convertToSpacedPin(pin) {
        let str = convertStringToArray(pin, 6).join("-");
        return str;
      }

      doc.text(businessName, startX + 5, startY + 20);
      doc.text(
        item.unitAmount == 100 || item.unitAmount == 200
          ? "Logical-" + item.network + "                 NGN" + item.unitAmount
          : "Logical-" +
              item.network +
              "                             " +
              "NGN" +
              item.unitAmount,
        startX + 5,
        startY + 28
      );
      var image = new Image();
      image.src = "../../assets" + item.network.toLowerCase() + ".png";
      doc.addImage(
        image,
        "PNG",
        item.unitAmount == 100 || item.unitAmount == 200
          ? startX + 115
          : startX + 230,
        startY + 11,
        25,
        68
      );
      doc.text("S/N: " + item.serial, startX + 5, startY + 36);

      if (item.unitAmount == 100 || item.unitAmount == 200) {
        doc.text("Pin:", startX + 5, startY + 53);
        doc.setFontSize(8).setFont(undefined, "bold");
        doc.text(convertToSpacedPin(item.pin), startX + 16, startY + 48);
      } else {
        doc.setCharSpace(3);
        doc.text("Pin:", startX + 5, startY + 53);
        doc.setFontSize(9).setFont(undefined, "bold");
        doc.text(convertToSpacedPin(item.pin), startX + 23, startY + 48);
      }
      doc.setFontSize(6).setFont(undefined, "normal");
      if (item.unitAmount == 100 || item.unitAmount == 200) {
      } else {
        doc.setCharSpace(2);
      }
      doc.text(
        "Date: " + new Date().toLocaleDateString(),
        startX + 5,
        startY + 60
      );
      doc.text("Dial *555*PIN# to load", startX + 5, startY + 68);
      doc.text("Powered by TopUpBox", startX + 5, startY + 76);

      var nextPosX = startX + cellWidth + cellMargin;

      if (nextPosX > pageWidth) {
        startX = pageMargin - 10;
        startY += cellHeight;
      } else {
        startX = nextPosX;
      }
    }

    for (var i = 0; i < this.state.pins.length; i++) {
      createCard(this.state.pins[i], this.state.businessName, i);
    }

    doc.save(this.state.description + " " + new Date().toString() + ".pdf");
  }
}

export default Airtel;
