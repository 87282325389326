import {
	Box,
	FormControl,
	FormLabel,
	Text,
	HStack,
	Input,
	
	Textarea,
} from "@chakra-ui/react";
import React from "react";
import locationIcon from "../../../assets/location.png";
import sendIcon from "../../../assets/sendMsg.png";
import callIcon from "../../../assets/callIcon.png";


const SendMsgComponent = () => {
	const formInputsStyles = {
    
        borderRadius: "none",
        outline:  "none",
		borderBottom: "1px solid black",
        
	};
	return (
		<Box
			style={{
				display: "flex",
				flexDirection: "row",

				justifyContent: "space-between",
			}}
			gap="20"
			px="20"
		>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "1rem",
					color: "#212337",
				}}
			>
				<Text fontSize={32} color="#263B95">
					Lets get in touch
				</Text>
				<Text>We're open for any suggestion or just to have a chat</Text>
				<HStack>
					<img src={locationIcon} />
					<Text>Address : 163 sinari daranijo, Victotoria island, Lagos</Text>
				</HStack>
				<HStack>
					<img src={sendIcon} />
					<Text>Email: info@topupbox.com</Text>
				</HStack>
				<HStack>
					<img src={callIcon} />
					<Text>Phone : +2346575859903</Text>
				</HStack>
			</Box>
			<Box border="1px solid  #E9EAEC" width="536px" borderRadius={8}>
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
					}}
					gap="1rem"
					m="1.5rem"
				>
					<Text fontSize={32} color="#263B95">
						Send us a message
					</Text>
					<FormControl>
						<FormLabel fontSize={12}>Your name</FormLabel>
						<Input
							type="text"
							placeholder="Alan Walker"
							border="none"
							style={{
								borderRadius: "0",
								outline: "none",
								borderTop: "none",
								borderLeft: "none",
								borderRight: "none",
								borderBottom: "1px solid #263B95",
							}}
						/>
					</FormControl>
					<FormControl>
						<FormLabel fontSize={12}>Your Email</FormLabel>
						<Input
							type="text"
							placeholder="jonDoe@gmail.co"
							style={{
								borderRadius: "0",
								outline: "none",
								borderTop: "none",
								borderLeft: "none",
								borderRight: "none",
								borderBottom: "1px solid #263B95",
							}}
						/>
					</FormControl>
					<FormControl fontSize={12}>
						<FormLabel>Subject</FormLabel>
						<Input
							type="text"
							placeholder="a review"
							style={{
								borderRadius: "0",
								outline: "none",
								borderTop: "none",
								borderLeft: "none",
								borderRight: "none",
								borderBottom: "1px solid #263B95",
							}}
						/>
					</FormControl>
					<Textarea
						placeholder="Message"
						border="1.5px solid #E9EAEC1A"
						p="10px"
						style={{ border: "1.5px solid ##E9EAEC", resize: "none" }}
						rows={5}
						cols={5}
					></Textarea>
				</Box>
			</Box>
		</Box>
	);
};

export default SendMsgComponent;
