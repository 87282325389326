import {
  Box,
  Button,
  FormControl,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { impData } from "../../../impData";
import { config } from "../../../components/config";
import axios from "axios";
import cookie from "react-cookies";
import { PulseLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import ResetPinModel from "./ResetPin";
const ChangeTransactionPin = ({ index }) => {
  const [transactionPin, setTransactionPin] = useState();
  // const [formalPin, setFormalPin] = useState();
  // const { toast, ToastContainer } = createStandaloneToast();
  const [confirmTransactionPin, setConfirmTransactionPin] = useState("");
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const chnageTransactionpinhandler = () => {
    setLoading(true);
    axios
      .post(
        `${config.api_url}/api/v3/w3/topupcliq-change-pin`,
        {
          "oldPin": transactionPin,
          "newPin":confirmTransactionPin
        },
        {
          headers: {
            authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
          },
        }
      )
      .then(res => {
        console.log(res.data);
        if (res.data.status == '2000') {
          toast.success(res.data.description, )
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        } else {
          console.log(res.data)
          toast.error(res.data.description)
          setTimeout(() => {
            toast.error(res.data.description)
          });
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER
        });
      })
      .finally(res => {
        setLoading(false);
      });
  }

  const [idx, setTabIndex] = useState(0);
  useEffect(() => {
    setTabIndex(index);
  }, []);

  return (
    <Box
      bg="#fff"
      fontFamily="clash"
      h="full"
      w="full"
      p={{ base: 0, md: "1rem" }}
      pt="2rem"
      display={idx === 4 ? impData.flex : ""}
      flexDir="column"
      gap="1.5rem"
    >
      <ToastContainer />
      <ResetPinModel
        showTransPinModal={open}
        closeTransPinModal={handleClose}
      />
      <Box
        width={{ base: "100%", md: "100%", lg: "40%" }}
        m={{ base: 0, lg: "1.5rem 0", md: "1rem auto" }}
        display={impData.flex}
        flexDirection="column"
        borderRadius={10}
        gap="2rem"
        p="1.5rem 1rem"
      >
       
        <FormControl>
          <input
            onChange={(e) => setTransactionPin(e.target.value)}
            className="py-[10px] border-b-[2px] focus:border-b-[#212337]  border-b-[#212337] placeholder:text-[#212337] placeholder:text-[600] px-[10px]  block w-full "
            name="text"
            value={transactionPin}
            placeholder="Enter new PIN"
          />
        </FormControl>
        <FormControl>
          <input
            onChange={(e) => setConfirmTransactionPin(e.target.value)}
            className="py-[10px] border-b-[2px] focus:border-b-[#212337]  border-b-[#212337] placeholder:text-[#212337] placeholder:text-[600] px-[10px]  block w-full "
            name="phoneNumber"
            value={confirmTransactionPin}
            placeholder="Confirm PIN"
          />
        </FormControl>
        <Button
          w="full"
          h="56px"
          bg="#263B95"
          borderRadius={56}
          color="#fff"
          fontSize={18}
          fontWeight="bold"
          onClick={chnageTransactionpinhandler}
        >
        {
              loading ? <PulseLoader color="#fff" loading={loading} /> : "Change PIN"
            }
        
        </Button>
        <Text textAlign="right" className=" flex gap-2" fontSize={16}>
          Forgot your old PIN? <p  onClick={handleOpen} className=" underline cursor-pointer">reset here</p>
        </Text>
      </Box>
    </Box>
  );
};

export default ChangeTransactionPin;
