import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import { impData } from "../../impData";
import AvailableAirtime from "./AvailableAirtime/AvailableAirtime";
import PrintAirtimeIntro from "./Intro/PrintAIrtimeIntro";
import PrintAirtimeFormModal from "./Modal/PrintAirtimeFormModal";
import cookie from "react-cookies"
import { useNavigate } from "react-router-dom";
const PrintAirtime = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (
      !cookie.load("TOPUPCLIQ_USER_TOKEN") ||
      cookie.load("TOPUCLIQ_USER_TOKEN") === ""
    ) {
      navigate("/login");
    }
  }, [])
  return (
    <>
      <DashboardWrapper header="Print airtime">
        <Box
          w="full"
          fontFamily="clash"
          h="100%"
          bg="#fafafa"
          top={0}
          p="1rem"
          display={impData.flex}
          flexDirection="column"
        >
          <Box
            h="full"
            w="full"
            bg="#fff"
            m={{ base: 0, md: "10px" }}
            display={impData.flex}
            flexDirection="column"
          >
            <Box
              w="full"
              p={{ base: 0, md: "10px" }}
              h="50%"
              
            >
              <Text mx={{ base: "1rem", md: "" }} d="block" fontSize={24} fontWeight="600">
                Buy airtime vouchers
              </Text>
              <Text
              mx={{ base: "1rem", md: "" }}
                fontSize={18}
                fontWeight={600}
                color="#21233799"
                display="block"
              >
                Choose network
              </Text>
              <Box as="div" my="1rem" w="full" h="full">
                <PrintAirtimeIntro />
              </Box>
              <Box
                w="full"
                background="#fff"
                h="full"
                marginTop="1rem"
                borderTop="1px solid #E9EAEC"
                width="full"
                p="1.5rem 1rem "
              >
                <AvailableAirtime />
              </Box>
            </Box>
          </Box>
        </Box>
        
      </DashboardWrapper>
    </>
  );
};

export default PrintAirtime;
