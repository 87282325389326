import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import DashboardWrapper from "../../components/DashboardWrapper"
import ReferralIntro from './ReferralIntro'
import Referralshistory from './Referralshistory'
import cookie from "react-cookies"
const ReferralPage = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (
      !cookie.load("TOPUPCLIQ_USER_TOKEN") ||
      cookie.load("TOPUCLIQ_USER_TOKEN") === ""
    ) {
      navigate("/login");
    }
  }, [])
  return (
    <DashboardWrapper header="Referrals">

      <Box px="2rem" w="full" h="full" bg="#fff">
        <ReferralIntro />

        {/* Referred users */}
        <Box w="full" h="full" mx="1rem">
          <Text fontFamily="clash">Referred users</Text>

          <Referralshistory />
        </Box>



      </Box>
    </DashboardWrapper>
  )
}

export default ReferralPage