import {
  Box,
  Button,
  Text,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { impData } from "../../../../impData";
import { useSetRecoilState } from "recoil";
import { fundWalletDataAtom } from "../../../../atoms/store";

const EnterPinModal = ({
  setShowOtpModal,
  showPinModal,
  closePinModal,
  setShowPinModal,
}) => {
  const [cardPin, setCardPin] = useState("");
  const setFundingData = useSetRecoilState(fundWalletDataAtom);

  useEffect(() => {
    const data = { cardPin };
    setFundingData({ ...data });
  }, [cardPin]);
  return (
    <Modal
      w={{ base: "80%", md: "607px" }}
      isOpen={showPinModal}
      onClose={closePinModal}
    >
      <ModalOverlay
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.1)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <ModalContent width={{ base: "95%", md: "607px" }} margin={"auto"}>
        <ModalBody>
          <ModalCloseButton
            as="button"
            border="1px thin #E9EAEC"
            m="1rem"
            w="50px"
            h="50px"
            borderRadius={50}
            background="#E9EAEC"
          />
          <Box
            m="2.5rem 1rem"
            display={impData.flex}
            flexDirection="column"
            alignItems="center"
            gap="1rem"
          >
            <Text>Enter card pin</Text>
            <Text color="#21233780">
              Enter card PIN to complete your payment
            </Text>
            <HStack>
              <PinInput
                focusBorderColor="#212337"
                type="number"
                placeholder=""
                onChange={(value) => setCardPin(value)}
              >
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  w="71px"
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
              </PinInput>
            </HStack>
            <Button
              w="full"
              fontSize={18}
              bg="#263B95"
              borderRadius={50}
              h="56px"
              color="#fff"
              fontWeight={600}
              onClick={setShowOtpModal}
            >
              Continue
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default EnterPinModal;
