import { Box, FormControl, FormLabel, PinInputField, HStack, PinInput, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { impData } from "../../../../impData";
import { chooseNetworkAtom, purchaseVoucherAtom } from "../../../../atoms/store";
import { useRecoilValue } from "recoil";
import axios from "axios";
import { config } from "../../../../components/config";
import { ToastContainer, toast } from "react-toastify";
import { PulseLoader } from "react-spinners";
import cookie from "react-cookies";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
const AirtimeForm = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState(0);
  const [pin, setPin] = useState("");
  const networkName = useRecoilValue(chooseNetworkAtom);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    const params = {
      network:
        networkName == '9MOBILE' ? 'NINEMOBILE' : networkName,
      transactionPin: pin,
      amount: amount,
      beneficiary: phoneNumber,
      deviceId: ""
    };
    console.log(params)
    axios
      .post(config.api_url + '/api/v3/w3/topupcliq-airtime', params, {
        headers: {
          Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then(res => {
        console.log(res.data);
        if (res.data.status == '2000') {
          toast.success(res.data.description, {
            position: toast.POSITION.TOP_CENTER
          })
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        } else {
          console.log(res.data)
          setTimeout(() => {
            toast.error(res.data.description)
          });
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("Error while buying Airtime", {
          position: toast.POSITION.TOP_CENTER
        });
      })
      .finally(res => {
        setLoading(false);
      });
  }


  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box w="full" display={impData.flex} flexDirection="column" gap="2rem" px="1rem" mt="1rem">

        <FormControl>

          <FormLabel fontSize={12} color="#263B95">Phone number</FormLabel>
          <input
            onChange={(e) => {
              let text = e.target.value;
              if (isNaN(text)) {
              } else {
                setPhoneNumber(e.target.value)
              }
            }}
            value={phoneNumber}
            type="tel"
            pattern=""
            className="mb-5 py-2 border-0 outline-none placeholder:font-bold border-b-2 border-b-[#212337] focus:border-b-[#263B95]  block w-full "
            placeholder="0900  000  0000"
          />

          <FormLabel fontSize={12} color="#263B95">Amount (NGN)</FormLabel>
          <input
            onChange={(e) => {
              let text = e.target.value;
              if (isNaN(text)) {
              } else {
                setAmount(e.target.value)
              }
            }}
            value={amount}
            type="number"
            className="mb-5 border-0 py-2 outline-none placeholder:font-bold border-b-2 focus:border-b-[#263B95] border-b-[#212337] block w-full "
            placeholder="eg. 10000"
          />
          <FormLabel fontSize={12} color="#263B95">Transaction Pin</FormLabel>
          <HStack>
            <PinInput
              focusBorderColor="#212337"
              type="number"
              manageFocus={true}
              placeholder=""
              value={pin}
              onChange={(value) => setPin(value)}
            >
              <PinInputField
                background="#E9EAEC6B"
                h="50px"
                w="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                h="50px"
                w="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                mr="10px"
                h="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                w="50px"
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                h="50px"
                w="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
            </PinInput>
          </HStack>
        </FormControl>

        <Button
          fontSize={18}
          mt="2rem"
          bg="#263B95"
          borderRadius={50}
          h="56px"
          color="#fff"
          fontWeight={600}
          onClick={onSubmit}
        >
          {
            loading ? <PulseLoader color="#fff" loading={loading} /> : "Buy"
          }
        </Button>
      </Box>
    </>
  );
};

export default AirtimeForm;
