import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  Input,
  createStandaloneToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { impData } from "../../../impData";
import { config } from "../../../components/config";
import cookie from "react-cookies";
const EditProfileTab = ({ index }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const { toast, ToastContainer } = createStandaloneToast();
  const [idx, setTabIndex] = useState(0);
  useEffect(() => {
    setTabIndex(index);
  }, []);
  const handleEditProfile = () => {
    if (
      firstName === "" ||
      contactAddress === "" ||
      lastName === "" ||
      firstName === "" ||
      phoneNumber === ""
    ) {
      toast({
        description: "all input fields must be filled",
        status: "error",
        position: "top",
        duration: 6000,
        isClosable: false,
      });
    }
    axios
      .post(
        `${config.api_url}/api/v3/w3/topupcliq-edit-user`,
        {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          contactAddress: contactAddress.trim(),
          phoneNumber: phoneNumber.trim(),
        },
        {
          headers: {
            authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
          },
        }
      )
      .then((res) => {
        const fetchData = res.data;
        console.log(fetchData)
        if (fetchData.status === "4000") {
          toast({
            description: fetchData.description,
            status: "error",
            position: "top",
            duration: 6000,
            isClosable: false,
          });
        }
        if (fetchData.status === "2000") {
          toast({
            description: fetchData.description,
            status: "success",
            position: "top",
            duration: 6000,
            isClosable: false,
          });
          setContactAddress("");
          setFirstName("");
          setLastName("");
          setPhoneNumber("");
        }
      }).catch(e => console.log(e))
  };

  useEffect(() => {
    axios
      .get(`${config.api_url}/api/v3/w3/topupcliq-get-user`, {
        headers: {
          authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then((res) => {
        if (res.data.status === "2000") {
          const userData = res.data?.data[0];
          console.log(userData)

          setFirstName(userData.firstName);
          setLastName(userData.lastName);
          setContactAddress(userData.contactAddress);
          setPhoneNumber(userData.phoneNumber);


          // user token
        }
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
      });
  }, []);

  return (

    <Box
      bg="#fff"
      fontFamily="clash"
      h="full"
      w="full"
      p={{ base: 0, md: "1rem" }}
      pt="2rem"
      display={impData.flex}
      flexDir="column"
      gap="1.5rem"
    >
      <h1 className="text-[16px] font-[600] text-[#212337]  sm:text-center md:text-center lg:text-left">
        Update your profile information
      </h1>
      <ToastContainer />
      <Box
        w={{ base: "100%", md: "100%", lg: "500px" }}
        m={{ base: 0, lg: "1.5rem 0", md: "1rem auto" }}
        display={impData.flex}
        flexDirection="column"
        borderRadius={10}
        border="2px solid #D9D9D9"
        gap="2rem"
        p="1.5rem 1rem"
        className="sm:max-w-[90%] md:w-[50%] lg:w-[45%] rounded-sm border-[2px] border-solid border-[#D9D9D9] md:mt-[10px] flex flex-col gap-[1rem]"
      >
        <FormControl>
          <FormLabel fontSize={12} color="#263B95">
            First name
          </FormLabel>
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="py-[10px] border-b-[2px] focus:border-b-[#212337] outline-none  border-b-[#21233799] placeholder:text-[#21233799] placeholder:text-[600] px-[10px]  block w-full "
            readOnly={false}
            name="fullName"
            placeholder="Walker"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={12} color="#263B95">
            Last name
          </FormLabel>
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="py-[10px] border-b-[2px] focus:border-b-[#212337] outline-none  border-b-[#21233799] placeholder:text-[#21233799] placeholder:text-[600] px-[10px]  block w-full "
            readOnly={false}
            name="fullName"
            placeholder="Walker"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={12} color="#263B95">
            Contact address
          </FormLabel>
          <input
            value={contactAddress}
            onChange={(e) => setContactAddress(e.target.value)}
            className="py-[10px] text-[] border-b-[2px] outline-none focus:border-b-[#212337]  border-b-[#21233799] placeholder:text-[#21233799] placeholder:text-[600] px-[10px]  block w-full "
            name="email"
            placeholder="No.2 Shomolu street lagos"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={12} color="#263B95">
            Phone number
          </FormLabel>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="py-[10px] border-b-[2px] focus:border-b-[#212337]  outline-none  border-b-[#21233799] placeholder:text-[#21233799] placeholder:text-[600] px-[10px]  block w-full "
            name="phoneNumber"
            placeholder="+234000000000"
          />
        </FormControl>
        <Button
          w="full"
          h="56px"
          bg="#263B95"
          borderRadius={56}
          color="#fff"
          fontSize={18}
          fontWeight="bold"
          onClick={handleEditProfile}
        >
          Save changes
        </Button>
      </Box>
    </Box>

  );
};

export default EditProfileTab;

//
