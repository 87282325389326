import {
  Box,
  Button,
  Text,
  HStack,
  PinInput,
  PinInputField,
  
} from "@chakra-ui/react";
import React from "react";
import DashModal from "../../../../components/DashModal";
import { impData } from "../../../../impData";

const EnterTransactionPinModal = () => {
  return (
    <DashModal width={{base:"80%", md: "40%"}} openModal={false}>
      <Box
        m="2.5rem 3rem"
        fontFamily="clash"
        display={impData.flex}
        flexDirection="column"
        alignItems="center"
        gap="1rem"
      >
        <Text fontFamily="clash" letterSpacing="0.02em" fontSize="32px" fontWeight={600}>Enter transaction PIN</Text>
        <Text color="#21233780">Enter PIN to validate transactions</Text>
        <HStack>
          <PinInput
            focusBorderColor="#212337"
            type="number"
            manageFocus={true}
            placeholder=""
            
          >
            <PinInputField
            mr="10px"
              background="#E9EAEC6B"
              h="71px"
              w="71px"
              _focus={{ color: "#212337", border: "2px solid #263B95" }}
              fontWeight={700}
              fontSize={32}
              borderRadius={16}
            />
            <PinInputField
              background="#E9EAEC6B"
            mr="10px"

              h="71px"
              w="71px"
              _focus={{ color: "#212337", border: "2px solid #263B95" }}
              fontWeight={700}
              fontSize={32}
              borderRadius={16}
            />
            <PinInputField
              background="#E9EAEC6B"
            mr="10px"

              h="71px"
              _focus={{ color: "#212337", border: "2px solid #263B95" }}
              fontWeight={700}
              fontSize={32}
              w="71px"
              borderRadius={16}
            />
            <PinInputField
              background="#E9EAEC6B"
              h="71px"
              w="71px"
              _focus={{ color: "#212337", border: "2px solid #263B95" }}
              fontWeight={700}
              fontSize={32}
              borderRadius={16}
            />
          </PinInput>
        </HStack>
        <Button
          w={{base: "70%", md: "50%"}}
          fontSize={18}
          bg="#263B95"
          borderRadius={50}
          h="56px"
          color="#fff"
          fontWeight={600}
        >
          Continue
        </Button>
      </Box>
    </DashModal>
  );
};

export default EnterTransactionPinModal;
