import { background, Box, Flex, Text, Link } from "@chakra-ui/react"
import React from "react"
import { impData } from "../../../impData"
import AvailableAirtimeTable from "./AvailableAirtimeTable"
const AvailableAirtime = () => {
    return (
        <>

            <Box
                style={{
                    display: impData.flex,
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center"
                }}>


                <h1 style={{fontSize:24, width:impData.fitContent, fontWeight: 500}} >
                    Voucher history
                </h1>
                <Text>
                    
               {/* <Link  textDecoration="underline" fontWeight="500" href="#" color="#263B95" fontSize={16}> See all vouchers
                </Link> */}
                </Text>
            </Box>
            <AvailableAirtimeTable/>

        </>
    )
}
export default AvailableAirtime