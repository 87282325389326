import { CloseIcon,} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Text,
  HStack,
} from "@chakra-ui/react";
import { FaAngleRight } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import DashModal from "../../../../components/DashModal";
import { impData } from "../../../../impData";
import IBEDC from "../../../../assets/IBEDC.png";
import KEDCO from "../../../../assets/kedco.png";
import ikeja from "../../../../assets/ikeja.png";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import cookie from "react-cookies";
import { config } from "../../../../components/config";
import Inputtt from "../../../../components/SelectValue/Inputtt";
import { PulseLoader } from "react-spinners";

const ElectricityBillsModal = ({openModal,
  closeModal,}) => {
  const angleright = <FaAngleRight />;
  const [loading, setLoading] = useState(false)
  const [electricityList, getElectricityList] = useState([])
  const [metreNo, setMetreNo] = useState('')
  const [name, setName] = useState('')
  const [amount, setAmount] = useState('')
  const [id, setId] = useState('')
  const [pin, setPin] = useState('')
  const [phone, setPhone] = useState('')
  const [show,setShow] = useState(true)
  const [data, setData] = useState({})
  const [status, setStatus] = useState(false)
  const token = cookie.load("TOPUPCLIQ_USER_TOKEN");


  useEffect(() =>{
    
    setLoading(true)
    axios
    .get(`/bills/all-billers`, {
      headers: {
        authorization: token,
      },
    })
    .then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
          getElectricityList(response.data.data)
         }else{  
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Login failed. Try again',)
          }
    )

  
 },[token])
 useEffect(() =>{
  if(metreNo.length > 7){
    axios
    .post(`/bills/verify-name-elec`,{
      "service_type": id,
      "account_number" : metreNo,
    }, {
      headers: {
        authorization: token,
      },
    })
    .then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
          
         setName(response.data.data[0].customerName)
         }else{  
         toast.error(response.data.description,)
         }
         
        },
        (error) => {
          
          }
    )
  }
  
},[metreNo,id, token])

const onSubmit = (e) =>{
  e.preventDefault()
  setLoading(true)
  axios
    .post(
      config.api_url + '/api/v3/w3/electricity-request',{
        "service_type":id,
        "account_number": metreNo,
        "amount": amount,
        "phone": phone,
        "agentId": "205",
        "transactionPin":pin
      }, {
      headers: {
        authorization: token,
      },
    }).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
          setShow(true)
          setData(response.data.data)
          toast.success(response.data.description)
          window.location.reload()
          
         }else{  
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Failed. Try again',)
          }
    )
}
  return (
    <DashModal width={{ base: "80%", md: "30%", lg: "450px" }} 
     closeOnEsc={true}
     closeOnOverlayClick={true}
     onClose={closeModal} openModal={openModal}>
     
      <Box
        fontFamily="clash"
        display={impData.flex}
        flexDirection="column"
        alignItems="center"
        w="full"
        gap="1rem"
        p="1rem"
      >
      <ToastContainer/>
        <HStack justifyContent="space-between" w="full">
          <Text fontFamily="clash" fontSize={24} fontWeight={700}>
            Electricity
          </Text>
          <Button
            bg="#2123371A"
            w="44px"
            border={"none"}
            outline="none"
            style={{ border: 0 }}
            borderRadius={50}
            h="44px"
            onClick={closeModal}
          >
            <CloseIcon />
          </Button>
        </HStack>
        {show &&
        <>
        <>
      {/* <p className=' block text-start'>Select Electricity plan</p> */}
      <select value={id} onChange={(e) => setId(e.target.value)} required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select service provider</option>
        {electricityList.map((plan) =>{
          return  <option value={plan.serviceType} key={plan.productId}>{plan.name}</option>
        })}
       </select>
      </>
      <Inputtt type='text'
        value={metreNo}
        onChange={setMetreNo}
       label='Meter number' />
       {name.length>0 &&
       <div className=' my-5'>
            <p>Name: {name}</p>
       </div>
       }
      <Inputtt type='Amount'
        valuee={amount}
        label={'Amount'}
        onChange={setAmount}
       placeholder='Amount' />
       
       <Inputtt type='text'
        value={phone}
        onChange={setPhone}
       label='Phone number' />
        <div className=''></div>
        <Inputtt
        required
        value={pin}
         onChange={setPin}
          type='password' label='PIN'/>  
        <Button
          fontSize={18}
          mt="2rem"
          bg="#263B95"
          h="56px"
          width={"200px"}
          color="#fff"
          fontWeight={600}
          onClick={onSubmit}
        >
          {
            loading ? <PulseLoader color="#fff" loading={loading} /> : "Buy"
          }
        </Button>
        </>
        }
        {!show &&
      <div>
      <h1>Payment successful</h1>
        <p className=' text-center my-3'>Amount: {data?.amount}</p>
        <p className=' text-center my-3'>Token: {data?.creditToken}</p>
        <p className=' text-center my-3'>Value: {data?.amountOfPower}</p>
      </div>
      }
      </Box>
    </DashModal>
  );
};


export default ElectricityBillsModal;
