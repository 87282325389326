import { Box, Flex, Link, Text } from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom"
import React from "react";
import commentIcon from "../../assets/learmmore/comment.png";
import rocketIcon from "../../assets/learmmore/rocket.png";
import topupIcon from "../../assets/learmmore/topup_logo.png";
const LearnMoreSection = () => {
  return (
    <Box my="1rem" w="full" py="10" bg="white" d="block">
      <Flex flexDir={{base: "column", md: "row"}}>
        {content.map((item, i) => (
          <Box
            key={i}
            p="1.5rem 2.5rem"
            m={{base: "1rem", md: "0"}}
            textAlign="center"
            style={{
              border: "1px solid #E9EAEC",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              gap: "1em",
            }}
            borderColor="GrayText"
          >
            <img src={item.icon} style={{ margin: "10px auto" }} />
            <Text wordBreak="break-word" whiteSpace={{base:"break-spaces", md: ""}} fontSize="16px" fontWeight={600}>
              {item.header}
            </Text>
            <Text wordBreak="break-word" fontSize="16px">
              {item.desc}
            </Text>
            <Link
            as={RouterLink}
              to={item.link}
              mx="auto"
              p="0.5rem 1rem"
              my="1rem"
              d="block"
              w={"fit-content" || "-moz-fit-content"}
              border="1px"
              color="#263B95"
              fontSize={16}
              borderRadius="3xl"
              borderColor="#E9EAEC"
              fontWeight={600}
            >
              Learn more
            </Link>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default LearnMoreSection;
const content = [
  {
    icon: commentIcon,
    header: "Contact Us",
    desc: "Our support team is here to help by phone, chat, or email",
    link: "/contactus",
  },
  {
    icon: topupIcon,
    header: "TopupCliq",
    desc: "Satisfaction will always be our goal, and protection—our promise.",
    link: "",
  },
  {
    icon: rocketIcon,
    header: "Start a business",
    desc: "Sell quality products that people use every day.",
    link: "/dashboard",
  },
];
