import {
  Box,
  Button,
  Text,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  color,
  createStandaloneToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { changePwdAtom, fundWalletDataAtom } from "../../../../atoms/store";
import { config } from "../../../../components/config";
import { impData } from "../../../../impData";
import { ToastContainer, toast } from "react-toastify";
import cookie from "react-cookies";
const EnterTransPinModal = ({
  setShowTransPinModal,
  showTransPinModal,
  closeTransPinModal,
}) => {
  const [transactionPin, setTransactionPin] = useState("");
  const [changePwdData, setChangePwd] = useRecoilState(changePwdAtom);
  const { toast, ToastContainer } = createStandaloneToast();
  useEffect(() => {
    setChangePwd((oldData) => {
      return { ...oldData, transactionPin: transactionPin };
    });
  }, [transactionPin]);
  const changePasswordHandler = () => {
    axios
      .post(
        `${config.api_url}/api/v3/w3/topupcliq-change-password`,
        changePwdData,
        {
          headers: {
            authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
          },
        }
      )
      .then((res) => {
        const resData = res.data;
        if (resData.status === "2000") {
          // toast({
          //   description: resData.description,
          //   status: "success",
          //   position: "top",
          //   duration: 9000,
          //   isClosable: false,
          // });
          toast.success(res.data.description, {
            position: toast.POSITION.TOP_CENTER
          })
          setTimeout(() => {
            
          }, 1000);
          setTransactionPin("");
          /* setTransactionPin(() => {
            return false;
          }); */
        }
        if (resData.status === "4000") {
          toast({
            description: resData.description,
            status: "error",
            position: "top",
            duration: 9000,
            isClosable: false,
          });
        }
       
      })
      .catch((e) => console.log(e));
  };

  // const onSubmit = () => {
  //   setLoading(true);
  //   const params = {
  //     "transactionPin": transactionPin,
  //     "newPassword": chan
  //   }
  //   console.log(params)
  //   axios
  //     .post(config.api_url + '/api/v3/w3/topupcliq-change-password', params, {
  //       headers: {
  //         Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
  //       },
  //     })
  //     .then(res => {
  //       console.log(res.data);
  //       if (res.data.status == '2000') {
  //         toast.success(res.data.description, {
  //           position: toast.POSITION.TOP_CENTER
  //         })
  //         setTimeout(() => {
  //           window.location.reload()
  //         }, 1000);
  //       } else {
  //         console.log(res.data)
  //         toast.error(res.data.description)
  //         setTimeout(() => {
  //           toast.error(res.data.description)
  //         });
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       toast.error("Error while buying Airtime", {
  //         position: toast.POSITION.TOP_CENTER
  //       });
  //     })
  //     .finally(res => {
  //       setLoading(false);
  //     });
  // }

  return (
    <Modal
      w={{ base: "80%", md: "607px" }}
      isOpen={showTransPinModal}
      onClose={closeTransPinModal}
      closeOnOverlayClick={true}
    >
      <ModalOverlay
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.1)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <ModalContent width={{ base: "90%", md: "607px" }} margin={"auto"}>
        <ModalBody>
          <ModalCloseButton
            border="1px thin #E9EAEC"
            m="1rem"
            w="50px"
            h="50px"
            borderRadius={50}
            background="#E9EAEC"
          />
          <ToastContainer />
          <Box
            m="2.5rem 1rem"
            display={impData.flex}
            flexDirection="column"
            alignItems="center"
            gap="1rem"
          >
            <Text>Enter Transaction PIN</Text>
            <Text color="#21233780">Enter PIN to validate transactions</Text>
            <HStack>
              <PinInput
                focusBorderColor="#212337"
                type="number"
                placeholder=""
                value={transactionPin}
                onChange={(value) => setTransactionPin(value)}
              >
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  w="71px"
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
              </PinInput>
            </HStack>
            <Button
              w="full"
              fontSize={18}
              bg="#263B95"
              borderRadius={50}
              h="56px"
              color="#fff"
              fontWeight={600}
              onClick={changePasswordHandler}
            >
              Continue
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default EnterTransPinModal;
