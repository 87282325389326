import { CloseIcon,} from '@chakra-ui/icons'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import DashModal from '../../../../components/DashModal'
import { impData } from '../../../../impData'
import { FaAngleRight } from 'react-icons/fa'
import { useState } from 'react'
import { toast,ToastContainer } from 'react-toastify'
import { useEffect } from 'react'
import axios from 'axios'
import { config } from "../../../../components/config";
import cookie from "react-cookies";
import Inputtt from '../../../../components/SelectValue/Inputtt'
import { PulseLoader } from 'react-spinners'
const SchoolBills = ({openModal,
  closeModal,}) => {
    const angleright = <FaAngleRight/>
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')
  const [phone, setPhone] = useState('')
  const [amount, setAmount] = useState(8)
  const [pin, setPin] = useState('')
  const [waecList, setWaecList] = useState([])
  const [waecValue, setWaecValue] = useState('')
  const [waecAva, setWaecAva] = useState('')
  const [jambValue, setJambValue] = useState('')
  const [jambList, setJambList] = useState([])
  const [acc, setAcc] = useState('')
  const token = cookie.load("TOPUPCLIQ_USER_TOKEN");

  const handleSelect = (event) => {
    const selectedItem = waecList.find(item => item.description === event.target.value);
    if (selectedItem) {
      setWaecValue(selectedItem.description);
      setAmount(selectedItem.amount);
      setWaecAva(selectedItem.available)
    }
  };

  const handleSelect2 = (event) => {
    const selectedItem = jambList.find(item => item.product_code === event.target.value);
    if (selectedItem) {
      setJambValue(selectedItem.product_code);
      setAmount(selectedItem.amount);
    }
  };

 
useEffect(() =>{
    
  setLoading(true)
  axios
    .post(`/bills/bundles`,{
      "service_type": "waec"
    }, {
      headers: {
        authorization: token,
      },
    }).then(
    (response) => {
        console.log(response.data)
        setLoading(false)
       if(response.data.status === '2000'){
        setWaecList(response.data.data.data)
       
       }else{  
      
        toast.error(response.data.description,)
       }
       
      },
      (error) => {
        setLoading(false)
          return  toast.error('Login failed. Try again',)
        }

  )
axios
    .post(`/bills/jamb-pricing-option`,{
      "service_type": "jamb"
    }, {
      headers: {
        authorization: token,
      },
    }).then(
    (response) => {
        console.log(response.data)
        setLoading(false)
       if(response.data.status === '2000'){
        setJambList(response.data.data.data)
       
       }else{  
      
        toast.error(response.data.description,)
       }
       
      },
      (error) => {
        setLoading(false)
          return  toast.error('Login failed. Try again',)
        }
        
  )

},[token])

const onSubmit = (e) =>{
  e.preventDefault()
  setLoading(true)
  if(id === 'waec'){
    axios
    .post(config.api_url + '/api/v3/w3/waec-pin',{
      "service_type": "waec",
      "numberOfPins": 1,
      "pinValue":waecAva,
      "amount" : amount,
      "agentReference": "kfmc2c3d8987d223",
      "transactionPin": pin
    }, {
      headers: {
        authorization: token,
      },
    }).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
           
            toast.success(response.data.description)
            setTimeout(() => {
              window.location.reload()
            }, 2000);
           }else{  
            toast.error(response.data.description,)
           }
           
          },
          (error) => {
            setLoading(false)
              return  toast.error('Login failed. Try again',)
            }
      )
  }
  if(id === 'jamb'){
    axios
    .post(config.api_url + '/api/v3/w3/jamb-pin',{
      "service_type" : "jamb",
      "account_number" : acc,
      "product_code" : jambValue,
      "phone" : phone,
      "amount" : amount,
      "agentReference" : "100306543008",
      "transactionPin" : pin
    }, {
      headers: {
        authorization: token,
      },
    }).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
           
            toast.success(response.data.description)
            setTimeout(() => {
              window.location.reload()
            }, 2000);
           }else{  
            toast.error(response.data.description,)
           }
           
          },
          (error) => {
            setLoading(false)
              return  toast.error('Login failed. Try again',)
            }
      )
  }
 
}

  return (
    <DashModal
      width={{ md: "60%", base: "80%", lg: "450px" }}
      // height={"60%"}
      openModal={openModal}
      closeOnEsc={true}
      closeOnOverlayClick={true}
    >
      <Box
        w="full"
        fontFamily="clash"
        h="full"
        display={impData.flex}
        flexDirection="column"
      >
      <ToastContainer/>
        <Box
          display={impData.flex}
          fontFamily="clash"
          w="full"
          mt="1rem"
          h="60px"
          alignItems="center"
        >
          <Flex justifyContent="space-between" w="full" mx="1rem">
            <Text fontFamily="clash" fontSize={24} fontWeight={700}>
            School and Exams
            </Text>
            <button   onClick={closeModal} className="flex outline-none items-center w-[44px] h-[44px] rounded-full justify-center bg-[#2123371A] border-0">
              <CloseIcon />
            </button>
          </Flex>
        </Box>

        <div className="flex flex-col w-full my-[1rem] gap-[1rem] p-3 overflow-y-auto h-[75vh] ">
       
        <div>
      <label className=' block font-medium mb-2'>Avaliable Exam</label>
      <select value={id} onChange={(e) => {
       setId(e.target.value)
       setAmount(0)
       setJambValue('')
       setWaecValue('')
      } } required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select Exam</option>
        <option value={'waec'}>WAEC</option>
        <option value={'jamb'}>JAMB</option>
       </select>
      </div>
      {id === 'waec' ?
        <div>
      <label className=' block font-medium mb-2'>WAEC Exam type</label>
      <select value={waecValue} onChange={handleSelect} required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select type</option>
        {waecList.map((e, index) =>{
          return  <option key={index} value={e.description}>{e.description}</option>
        })}
       </select>
      </div>
      :id === 'jamb' ?<div>
      <label className=' block font-medium mb-2'>Jamb Exam type</label>
      <select value={jambValue} onChange={handleSelect2} required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select type</option>
        {jambList.map((e, index) =>{
          return  <option key={index} value={e.product_code}>{e.product_code}</option>
        })}
       </select>
      </div>: <></>}
      <Inputtt
       type='text' 
       disabled= {true}
       value={amount}

       label={'Amount'}
        placeholder='Amount' />
        {id === 'jamb' &&
          <Inputtt
       type='text' 
       value={acc}
       onChange={setAcc}
       label={'Account number'}
        placeholder='00000000' />
        }
      <Inputtt
       type='text' 
       value={phone}
       label={'Phone number'}
       onChange={setPhone}
        placeholder='090230000000' />
    
    
    
        <Inputtt
                    value={pin}
                    required
                    name={'pin'}
                    onChange={setPin}
                     type='password' label='PIN'/>  
        <Button
          fontSize={18}
          mt="2rem"
          bg="#263B95"
          h="56px"
          width={"200px"}
          color="#fff"
          fontWeight={600}
          onClick={onSubmit}
        >
          {
            loading ? <PulseLoader color="#fff" loading={loading} /> : "Buy"
          }
        </Button>
     
        </div>
      </Box>
    </DashModal>

  )
}



export default SchoolBills