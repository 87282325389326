import { Box, Flex, Text, HStack, Link } from "@chakra-ui/react";
import logo from "../assets/Icons/Logo.png";
import React, { useEffect, useRef, useState } from "react";

import { FaGift } from "react-icons/fa";
import { CgBell } from "react-icons/cg";
import { impData } from "../impData";
import cookie from "react-cookies";
import { FaBars } from "react-icons/fa";
import axios from "axios";
import { config } from "./config";
import { useSetRecoilState } from "recoil";
import { loginEmailState } from "../atoms/store";
const giftIcon = <FaGift fontSize={24} color="#22AD80" />;

const notificationIcon = <CgBell fontSize={35} />;
const DashHeader = ({ header, setOpenSidebar }) => {
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [status, setStatus] = useState("");
  const mounted = useRef(false);
  const setEmailAtom = useSetRecoilState(loginEmailState);
  useEffect(() => {
    setFullName(cookie.load("TOPUPCLIQ_USER_FULLNAME"));
    setEmailAddress(cookie.load("TOPUPCLIQ_USER_EMAIL"));
    setStatus(cookie.load("TOPUPCLIQ_USER_STATUS"));

    axios
      .get(`${config.api_url}/api/v3/w3/topupcliq-get-user`, {
        headers: {
          authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then((res) => {
        const userData = res.data;
        if (userData.status === "2000") {
          setFullName(
            userData.data[0].firstName + " " + userData.data[0].lastName
          );
          setEmailAddress(userData.data[0].emailAddress);
          setStatus(userData.data[0].status);
          setEmailAtom(userData.data[0].emailAddress);
          cookie.save("TOPUPCLIQ_USER_EMAIL", userData.data[0].emailAddress, {
            path: "/",
            expires: new Date(Date.now() + 60 * 14 * 1000),
          });
          cookie.save(
            "TOPUPCLIQ_USER_FULLNAME",
            userData.data[0].firstName + " " + userData.data[0].lastName,
            {
              path: "/",
              expires: new Date(Date.now() + 60 * 14 * 1000),
            }
          );
          cookie.save("TOPUPCLIQ_USER_STATUS", userData.data[0]?.status, {
            path: "/",
            expires: new Date(Date.now() + 60 * 14 * 1000),
          });
          // user token
        }
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
      });

    // console.log(
    //   fullName
    //     ? fullName.split(" ")[0].charAt(0) + fullName.split(" ")[1].charAt(0)
    //     : ""
    // );
  }, []);

  return (
    <div
      ref={mounted}
      className="flex w-full sm:px-[2rem] px-[1rem] min-w-full items-center justify-between h-[10%] bg-white py-[10px] shadow shadow-lg shadow-[rgba(0, 0, 0, 0.5)]"
    >
      <Box display={{ base: "flex", md: "none" }} href="/dashboard">
        <button
          className="w-fit sm:flex md:hidden lg:hidden p-[10px]"
          onClick={() => setOpenSidebar(true)}
        >
          <FaBars />
        </button>
        <img src={logo} alt="" />
      </Box>

      <Text
        display={{ base: "none", md: "flex", lg: "flex" }}
        style={{ fontSize: 24, fontWeight: 600 }}
        fontFamily="clash"
        w="full"
        color="#33333"
        alignItems="center"
      >
        {header}
      </Text>
      <Box
        display={impData.flex}
        gap={{ md: "1rem", sm: 10 }}
        marginRight={{ md: "", sm: 0 }}
        alignItems="center"
        bg="#fff"
      // justifyContent="flex-end"
      >
        {/* <a href="/">{giftIcon}</a> */}
        <a href="" className="" style={{ fontSize: 30 }}>
          <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.02 2.91c-3.31 0-6 2.69-6 6v2.89c0 .61-.26 1.54-.57 2.06L4.3 15.77c-.71 1.18-.22 2.49 1.08 2.93 4.31 1.44 8.96 1.44 13.27 0 1.21-.4 1.74-1.83 1.08-2.93l-1.15-1.91c-.3-.52-.56-1.45-.56-2.06V8.91c0-3.3-2.7-6-6-6Z"
              stroke="#263B95"
              strokeWidth={1.5}
              strokeMiterlimit={10}
              strokeLinecap="round"
            />
            <path
              d="M13.87 3.2a6.754 6.754 0 0 0-3.7 0c.29-.74 1.01-1.26 1.85-1.26.84 0 1.56.52 1.85 1.26Z"
              stroke="#263B95"
              strokeWidth={1.5}
              strokeMiterlimit={10}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.02 19.06c0 1.65-1.35 3-3 3-.82 0-1.58-.34-2.12-.88a3.01 3.01 0 0 1-.88-2.12"
              stroke="#263B95"
              strokeWidth={1.5}
              strokeMiterlimit={10}
            />
            {status === "active" ? (
              <circle cx={18.02} cy={5.939} r={4} fill="#FF4040" />
            ) : (
              ""
            )}
          </svg>
        </a>
        <Link
          href="#"
          minW="44px"
          ml={{ base: "10px" }}
          borderRadius={50}
          minH="44px"
          bg="#212337"
          color="#fff"
          fontWeight={600}
          alignItems="center"
          justifyContent="center"
          display={impData.flex}
          size={50}
        >
          {fullName ? fullName.match(/\b\w/g).join("") : ""}

        </Link>
        <Box
          display={{ base: "none", md: "none", lg: impData.flex }}
          flexDirection="column"
          justifyContent={"center"}
          alignItems="flex-start"
        >
          <Link
            w={impData.fitContent}
            href="#"
            color="#212337"
            fontSize={20}
            fontWeight={500}
            textAlign="right"
          >
            {fullName.split(" ")[0]}
          </Link>
          <Text display={{ base: "none", md: impData.flex, lg: "flex" }}>
            {emailAddress}
          </Text>
        </Box>
      </Box>
    </div>
  );
};

export default DashHeader;
