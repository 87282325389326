import {
  Box,
  Button,
  Text,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  color,
  createStandaloneToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  changePwdAtom,
  fundWalletDataAtom,
  purchaseVoucherAtom,
} from "../../../../atoms/store";
import { config } from "../../../../components/config";
import { impData } from "../../../../impData";
import cookie from "react-cookies";
const EnterTransPinModal = ({
  openTransPinModal,
  setOpenTransPinModal,
  closeTransPinModal,
}) => {
  const [transactionPin, setTransactionPin] = useState("");
  const [purchaseVoucherPayload, setPurchaseVoucherPayload] =
    useRecoilState(purchaseVoucherAtom);
  const { toast, ToastContainer } = createStandaloneToast();

  useEffect(() => {
    const data = { transactionPin };
    setPurchaseVoucherPayload((oldData) => {
      return { ...oldData, ...data };
    });
    console.log(purchaseVoucherPayload);
  }, [transactionPin]);
  const purchaseVouchersHandler = () => {
    axios
      .post(
        `${config.api_url}/api/v3/w3/topupcliq-buy-voucher`,
        purchaseVoucherPayload,
        {
          headers: {
            authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (
          res.data.status == "2000" &&
          res.data.data?.requestId !== undefined
        ) {
          window.location.reload();
        } else {
          toast({
            description: res.data.description,
            status: "error",
            position: "top",
            duration: 9000,
            isClosable: false,
          });
          console.log(res.data);
        }
      })
      .catch((e) => {
        toast({
          description: e,
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: false,
        });
        console.log(e);
      });
  };
  return (
    <Modal
      w={{ base: "80%", md: "607px" }}
      isOpen={openTransPinModal}
      onClose={closeTransPinModal}
      closeOnOverlayClick={true}
    >
      <ModalOverlay
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.1)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <ModalContent width={{ base: "90%", md: "607px" }} margin={"auto"}>
        <ModalBody>
          <ModalCloseButton
            border="1px thin #E9EAEC"
            m="1rem"
            w="50px"
            h="50px"
            borderRadius={50}
            background="#E9EAEC"
          />
          <ToastContainer />
          <Box
            m="2.5rem 1rem"
            display={impData.flex}
            flexDirection="column"
            alignItems="center"
            gap="1rem"
          >
            <Text>Enter Transaction PIN</Text>
            <Text color="#21233780">Enter PIN to validate transactions</Text>
            <HStack>
              <PinInput
                focusBorderColor="#212337"
                type="number"
                placeholder=""
                value={transactionPin}
                onChange={(value) => setTransactionPin(value)}
              >
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  w="71px"
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
              </PinInput>
            </HStack>
            <Button
              w="full"
              fontSize={18}
              bg="#263B95"
              borderRadius={50}
              h="56px"
              color="#fff"
              fontWeight={600}
              onClick={purchaseVouchersHandler}
            >
              Continue
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default EnterTransPinModal;
