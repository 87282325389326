import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { config } from "../../../components/config";
import axios from "axios";
import cookie from "react-cookies";

const TransactionHistories = () => {
  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatDateToIso = (date) => {
    const pad = (number) => number.toString().padStart(2, '0');
  
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1);
    const year = date.getFullYear();
  
    let hours = date.getHours();
    const minutes = pad(date.getMinutes());
    const ampm = hours >= 12 ? 'pm' : 'am';
  
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
  
    return `${day}-${month}-${year}  ${hours}:${minutes}${ampm}`;
  };
  const [currentTransactions, setCurrentTransactions] = useState([]);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
});

  useEffect(() => {
    axios
      .get(`${config.api_url}/api/v3/w3/topupmobile-custom-report?startDate=2024-01-01&endDate=${getFormattedDate()}`, {
        headers: {
          authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then((res) => {
        const transactionData = res.data;
        setCurrentTransactions([...transactionData.data.reverse()]);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <Table>
      <Thead w="full">
        <Tr borderBottom="2px solid #212337">
          <Th>Date</Th>
          <Th>Transaction Type</Th>
          <Th>Amount</Th>
          <Th>Status</Th>
        </Tr>
      </Thead>
      <Tbody >
        {currentTransactions.map((item, idx) => (
          <Tr key={idx} p>
            <Td fontSize={16} fontWeight={500}>{formatDateToIso(new Date(item?.date))}</Td>
            <Td fontSize={16} fontWeight={500}>{item.product}</Td>
            <Td fontSize={16} fontWeight={500}>NGN {formatter.format(item?.amount)}</Td>
            <Td fontSize={16} fontWeight={600} color={item.status !== "failed" ? "#22AD80" : item.status === undefined ? "#22AD80" : "#FF4040"}>
              {item.status ? item?.status : "Successful"}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};


export default TransactionHistories;
