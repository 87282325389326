import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { impData } from "../../../impData";
import { FaStore } from "react-icons/fa";
import QuickTransactions from "./QuickTransactions";
import RecentTransaction from "./RecentTransactions";
import { usePaystackPayment } from "react-paystack";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { config } from "../../../components/config";
import "react-toastify/dist/ReactToastify.css";
import cookie from "react-cookies";
import { useNavigate } from "react-router-dom";

const giftIcon = <FaStore fontSize={24} color="#005EEA" />;

const DashBody = () => {
  const [transactions, setTransactions] = useState([]);

  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const email = cookie.load("TOPUPCLIQ_USER_EMAIL");
  const initialConfig = { reference: null, email: cookie.load("TOPUPCLIQ_USER_EMAIL"), amount: 0, publicKey: "pk_live_a36043760d0eb68ef8a09f98553e405a85232cee", };
  const [configP, setConfig] = useState(initialConfig);
  let initializePayment = usePaystackPayment(configP);

  const verifyTransaction = (reference) => {
    setLoading(true);
    console.log(
      config.api_url + '/v3/w3/topupcliq-verify-wallet?reference=' + reference,
    );
    axios
      .get(
        config.api_url + '/api/v3/w3/topupcliq-verify-wallet?reference=' +
        reference,
        {
          headers: {
            Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
            Connection: 'keep-alive',
          },
        },
      )
      .then(res => {
        console.log(res.data);
        if (res.data.status == '2000') {
          toast.success(res.data.description);
          setTimeout(() => {
            navigate("/dashboard")
          }, 2000);
        } else {
          toast.error(res.data.description)
        }
      })
      .catch(err => {
        console.log(err);
        toast.show({
          type: 'error',
          text1: err.message,
        });
        setTimeout(() => {

        }, 1000);
      }).finally(res => {
        setLoading(false);
      });
  }

  const onSubmit = () => {
    setLoading(true);
    let amount_p = 5000 * 100;
    axios
      .post(
        config.api_url + '/api/v3/w3/topupcliq-fund-wallet',
        {
          email: email,
          callback_url: 'topupcliq.com',
          product:"upgrade",
          channel: [
            'card',
            'bank',
            'ussd',
            'qr',
            'mobile_money',
            'bank_transfer',
            'eft',
          ],
          amount: amount_p.toString(),
        },
        {
          headers: {
            Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
          },
        },
      )
      .then(res => {
        console.log(res.data);
        if (res.data.status == '2000') {
          // this.setState({
          //   reference: res.data.data?.reference,
          //   accessCode: res.data.data?.accessCode,
          // });
          // console.log(this.reference);
          //this.verifyTransaction(this.reference);
          // this.paystackWebViewRef.current.startTransaction();
          setConfig({
            ...configP, channel: [
              'card',
              'bank',
              'ussd',
              'qr',
            'mobile_money',
              'bank_transfer',
              'eft',
            ], email: email, reference: res.data.data?.reference, amount: amount_p,
          });
        } else {
          //this.closeSheet();
          setTimeout(() => {

          }, 1000);
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("Error while initializing transaction", {
          position: toast.POSITION.TOP_CENTER
        });
      }).finally(res => {
        setLoading(false);
      });
  }

  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    verifyTransaction(reference.reference)
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }




  useEffect(() => {
    console.log(configP)
    if (configP.reference === null || configP.email === undefined) return;
    initializePayment(onSuccess, onClose)
  }, [configP])
  return (
    <Box
      borderTop="1px solid #E9EAEC"
      w="full"
      display={impData.flex}
      flexDirection="row"
      flexWrap={{ base: "wrap", md: "wrap", lg: "nowrap" }}
      gap="1rem"
      px={{ base: 0, md: "1rem" }}
      pt="2rem"
      //   py={{ base: "2rem", md: "1rem" }}
    >
      <Box w={{ base: "full", md: "full" }} h="full">
        <Text
          fontSize={20}
          my="1rem"
          fontFamily="clash"
          fontWeight={600}
          color="#212337"
        >
          Quick Actions
        </Text>
        
        <div onClick={onSubmit} className=" cursor-pointer p-3 my-3 mr-5 rounded-md flex gap-3 border border-[#BCE6F7]  bg-[#E8F6FC]">
        {giftIcon}   <p className=" text-xl font-medium text-[#005EEA]">Become a reseller</p> 
        </div>
        
        <QuickTransactions />
      </Box>
      <Box
        w={{ base: "full", md: "full", lg: "50%" }}
        border="1px solid #E9EAEC"
        p="1rem"
        borderRadius={20}
        bg="rgba(233, 234, 236, 0.05)"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            fontSize={20}
            fontWeight={600}
            color="#212337"
            fontFamily="clash"
          >
            Recent transactions
          </Text>
          {transactions.length > 0 ? (
            <Text
              fontSize={20}
              fontWeight={500}
              color="#212337"
              fontFamily="clash"
            >
              22 Aug 2022
            </Text>
          ) : (
            ""
          )}
        </Flex>
        <RecentTransaction />
      </Box>
    </Box>
  );
};
export default DashBody;
