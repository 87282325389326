import { Box, Text } from "@chakra-ui/react";
import React from "react";
import bgImg from "../../../assets/aboutus_into.png";
const Intro = () => {
  return (
    <Box w="full" px={{ base: "0%", md: "20" }} py="10">
      <Text fontSize={{ base: "30px", md: "40px" }} fontWeight="bold">
        ABOUT US
      </Text>
      <Box
        p={{ base: "10px", md: "20" }}
        mt="1rem"
        textAlign={{ base: "center", md: "left" }}
        style={{
          color: "#fff",
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          height: 382,
          displlay: "block",
        }}
        backgroundSize={{ base: "", md: "cover" }}
      >

        <Text mt="1rem" style={{
          fontWeight: "bold"
        }}
          fontSize={{ base: "40px", md: "56px" }}
        >
          Top up Cliq,
        </Text>
        <Text style={{ fontWeight: "bold" }}
          fontSize={{ base: "45px", md: "56px" }}>
          Connect and Earn
        </Text>
      </Box>
    </Box>
  );
};

export default Intro;
