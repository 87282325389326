import headerBg from "../../../assets/contactusIntro.png";
import { Box, Text } from "@chakra-ui/react";

import React from 'react'

const Intro = () => {
  return (
		<Box w="full" px="20" py="10">
			<Box
				px="20"
				style={{
					color: "#fff",
					background: `url(${headerBg})`,
					height: 382.42,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<Text>Home / Contact us</Text>
				<Text fontSize={56}>Contact us</Text>
			</Box>
		</Box>
	);
}

export default Intro