import { Box, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import NewsletterForm from "./NewsletterForm";

import HomeSideBar from "./Templates/HomeSideBar/HomeSideBar";
const LandingPageWrappper = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <>
      <Box
      w="100vw"
      h={{base: "100%", md: "inherit"}}
        
        bg="#fafafa"
        d="block"
        p="0"
        m="0"
      >
        <VStack>
          <Header setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
          {children}
          <NewsletterForm />
          <Footer />
        </VStack>
      </Box>
        <HomeSideBar
          setShowSidebar={setShowSidebar}
          showSidebar={showSidebar}
        />
    </>
  );
};

export default LandingPageWrappper;
