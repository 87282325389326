import React, { useEffect, useState } from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import DashBody from "./body/DashBody";
import DashIntro from "./Intro/DashIntro";
import "../../App.css";
import FundingModal from "./Modals/FundingModal";
import EnterPinModal from "./Modals/EnterPinModal/EnterPinModal";
import EnterOtpModal from "./Modals/EnterOtpModal/EnterOtpModal";
import EnterTransPinModal from "./Modals/EnterTransPin/EnterTransPinModal";
import WalletFundedSuccessModal from "./Modals/WalletFundedSuccess/WalletFundedSuccessModal";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import cookie from "react-cookies";
import WalletFundedFailureModal from "./Modals/WalletFundedFailureModal/WalletFundedFailureModal";

const DashboardPage = () => {
  const navigate = useNavigate();
  // funding modal handlers
  const {
    isOpen: showFundingModal,
    onOpen: setSHowFundingModal,
    onClose: closeMe,
  } = useDisclosure();
  const [cvv, setCvv] = useState("");

  const {
    isOpen: showWithdrawModal,
    onOpen: setWithdrawModal,
    onClose: closeModal,
  } = useDisclosure();
  // pin modal handlers
  const {
    isOpen: showPinModal,
    onOpen: setShowPinModal,
    onClose: closePinModal,
  } = useDisclosure();
  const [transactionPin, setTransactionPin] = useState("");
  // otp modal handler
  const {
    isOpen: showOtpModal,
    onOpen: setShowOtpModal,
    onClose: closeOtpModal,
  } = useDisclosure();
  // wallet funded success modal
  const {
    isOpen: fundedModal,
    onOpen: setFundedModal,
    onClose: closeFundedModal,
  } = useDisclosure();

  // trasaction pin modal
  const {
    isOpen: transModal,
    onOpen: setTransModal,
    onClose: closeTransModal,
  } = useDisclosure();
  const {
    isOpen: fundFailureModal,
    onOpen: setFundFailureModal,
    onClose: closeFundFailureModal,
  } = useDisclosure();

  React.useEffect(() => {
    if (
      !cookie.load("TOPUPCLIQ_USER_TOKEN") ||
      cookie.load("TOPUCLIQ_USER_TOKEN") === ""
    ) {
      navigate("/login");
    }
  }, [])
  return (
    <>
    
      <DashboardWrapper header="Dashboard">
        <div className=" bg-white p-[1rem] w-full h-full overflowY-visible dashboardpage clash-display">
          <DashIntro
            setShowFundingModal={setSHowFundingModal}
            setWithdrawModal={showWithdrawModal}
          />
          <DashBody />
          {/* modals */}
          <FundingModal
            showFundingModal={showFundingModal}
            setShowFundingModal={setSHowFundingModal}
            closeMe={closeMe}
            setShowPinModal={setShowPinModal}
            setTransModal={setTransModal}
          />
          <EnterPinModal
            showPinModal={showPinModal}
            setShowOtpModal={setShowOtpModal}
            setShowPinModal={setShowPinModal}
            closePinModal={closePinModal}
          />
          <EnterOtpModal
            setTransModal={setTransModal}
            setShowOtpModal={setShowOtpModal}
            showOtpModal={showOtpModal}
            closeOtpModal={closeOtpModal}
          />
          <EnterTransPinModal
            transModal={transModal}
            closeTransModal={closeTransModal}
            setFundedModal={setFundedModal}
            setFundFailureModal={setFundFailureModal}
          />
        <WalletFundedSuccessModal  fundedModal={fundedModal} closeFundedModal={closeFundedModal} />
          <WalletFundedFailureModal
            setFundFailureModal={setFundFailureModal}
            fundFailureModal={fundFailureModal}
            closeFundFailureModal={closeFundFailureModal}
          />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default DashboardPage;
