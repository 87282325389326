import {
  Box,
  Button,
  Container,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Link,
} from "@chakra-ui/react";
import backgroundImg from "../../assets/authbg.png";
import React, { useEffect, useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import Logo from "../../assets/Icons/Logo.png";
import axios from "axios";
import { config } from "../../components/config";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { createStandaloneToast } from "@chakra-ui/react";
import AuthPagesWrapper from "../../components/Templates/AuthPagesWrapper";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loginEmailState, requestIdState, tokenState } from "../../atoms/store";
import cookie from "react-cookies";
import { impData } from "../../impData";
const LoginPage = () => {
  const { toast, ToastContainer } = createStandaloneToast();
  const setToken = useSetRecoilState(tokenState);
  const setRequestId = useSetRecoilState(requestIdState);
  const [makePwdVisisble, setMakePwdVisisble] = React.useState(false);
  const handlePwdVisibility = () => setMakePwdVisisble(!makePwdVisisble);
  const [emailAddress, setEmailAddress] = React.useState("");
  // email state atom
  const [emailAtom, setEmailAtom] = useRecoilState(loginEmailState);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    cookie.save("TOPUPCLIQ_USER_TOKEN", "");
  }, []);
  const navigate = useNavigate();
  // clear cureent user on component  mount

  // login handler
  const handleLogin = () => {
    if (emailAddress === "" || password === "") {
      setErrorMessage("all input fields must be filled");
      toast({
        description: errorMessage,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: false,
      });
    }
    setLoading(true);
    // post request axios
    axios
      .post(`${config.api_url}/api/v3/w3/topupcliq-user-login`, {
        emailAddress,
        password,
        channel: "web"
      }, 
      {
        headers: {
          anonymousId: "",
          businessName:"topupbox"
        }
      }
    )
      .then((res) => {
        const fetchData = res.data;
        if (fetchData.status === "4000") {
          toast({
            description: fetchData.description,
            status: "error",
            position: "top",
            duration: 9000,
            isClosable: false,
          });
        }
        if (fetchData.status === "2000") {
          toast({
            description: fetchData.description,
            status: "success",
            position: "top",
            duration: 6000,
            isClosable: false,
          });

          cookie.save("TOPUPCLIQ_USER_TOKEN", fetchData.data.token, {
            path: "/",
            expires: new Date(Date.now() + 60 * 14 * 1000),
          });
          cookie.save("TOPUPCLIQ_USER_EMAIL", fetchData.data.emailAddress, {
            path: "/",
            expires: new Date(Date.now() + 60 * 14 * 1000),
          });
          cookie.save(
            "TOPUPCLIQ_USER_FULLNAME",
            fetchData.data.firstName + " " + fetchData.data.lastName,
            {
              path: "/",
              expires: new Date(Date.now() + 60 * 14 * 1000),
            }
          );
          cookie.save(
            "TOPUPCLIQ_USER_REFERRAL_CODE",
            fetchData.data.firstName + "-" + fetchData.data.referalCode,
            {
              path: "/",
              expires: new Date(Date.now() + 60 * 30 * 1000),
            }
          );
          cookie.save("TOPUPCLIQ_USER_STATUS", fetchData.data.status, {
            path: "/",
            expires: new Date(Date.now() + 60 * 14 * 1000),
          });

          navigate("/dashboard");
          setLoading(false);
        }
      })
      .catch((e) => console.log(e));
  };
  // go to reset password handler

  // login component set up
  return (
    <AuthPagesWrapper>
      <Box
        mx="auto"
        bg=" #FCFCFC"
        display="block"
        py="20"
        w={{ base: "100%", md: "50%", lg: "50%" }}
      >
        <Image mx="auto" src={Logo} alt="" />

        <Box
          mt="5rem"
          // height="426px"
          mx="auto"
          w={{ base: "90%", md: "90%", lg: "60%" }}
          p={{ base: "10px", md: "2rem 1rem", lg: "2rem" }}
          background="#fff"
          boxShadow="0px -20px 40px rgba(38, 59, 149, 0.02), 0px 20px 40px rgba(38, 59, 149, 0.02)"
        >
          <Text
            as="h3"
            style={{
              fontSize: "24px",
              display: "block",
              textAlign: "center",
            }}
            fontWeight={500}
            color="#212337"
            my="1rem"
          >
            Login to your account
          </Text>
          <ToastContainer />

          <form action="">
            <FormLabel fontSize="12px">Email Address</FormLabel>
            <Input
              onChange={(e) => setEmailAddress(e.target.value)}
              type="email"
              placeholder="jonDoe@gmail.com"
              border="none"
              borderRadius="0"
              borderBottom="1px solid #212337"
              outline="none"
              style={{ outlineStyle: "none" }}
            />
            <FormLabel fontSize="12px" mt="10">
              Password
            </FormLabel>
            <InputGroup>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                style={{ outlineStyle: "none" }}
                type={makePwdVisisble ? "text" : "password"}
                placeholder="*************"
                border="none"
                borderRadius="0"
                borderBottom="1px solid #212337"
              />
              <InputRightElement>
                <Button
                  fontSize="20px"
                  bg="none"
                  style={{ background: "none", ":hover": { border: "none" } }}
                  border="none"
                  onClick={handlePwdVisibility}
                >
                  {makePwdVisisble ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Text
              fontSize={{ base: 14, md: 14, lg: 16 }}
              textAlign="right"
              mt="1rem"
            >
             <Link
              ml={"10px"}
              fontSize={18}
              fontWeight={600}
              href="/forget-password"
              style={{ textDecoration: "none" }}
              color="#263B95"
            >
               Forgot your password?
            </Link>
             
            </Text>
            <Button
              bg="#263B95"
              color="#fff"
              borderRadius="50px"
              mt="10"
              _hover={{ backgroundColor: "#263B95" }}
              _focus={{ backgroundColor: "#263B95" }}
              fontSize="18px"
              w="100%"
              fontWeight="700"
              lineHeight="23px"
              display={impData.flex}
              gap="1rem"
              h="56px"
              onClick={handleLogin}
            >
              Login
              <PulseLoader color="#fff" loading={loading} />
            </Button>
          </form>
          <Text textAlign="center" mt="1rem">
            Don’t have an account?
            <Link
              ml={"10px"}
              fontSize={18}
              fontWeight={600}
              href="/register"
              style={{ textDecoration: "none" }}
              color="#263B95"
            >
              Register
            </Link>
          </Text>
        </Box>
      </Box>
    </AuthPagesWrapper>
  );
};

export default LoginPage;
