import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaEye,FaEyeSlash } from "react-icons/fa";
import walletBg from "../../../assets/walletBg.png";
import referralBalance from "../../../assets/referral.png";
import { impData } from "../../../impData";
import { config } from "../../../components/config";
import axios from "axios";
import cookie from "react-cookies";
import WithdrawalModel from "../Modals/WithdrawalModel";

const DashIntro = ({ setShowFundingModal, setWithdrawModal }) => {
  const [accountDetails, setAccountDetails] = useState({
    accountBonus: "",
    accountBalance: "",
    referralBonus: "",
  });
  useEffect(() => {
    const token = cookie.load("TOPUPCLIQ_USER_TOKEN");
    axios
      .get(`${config.api_url}/api/v3/w3/topupcliq-get-balance`, {
        headers: {
          authorization: token,
        },
      })
      .then((res) => setAccountDetails(res.data.data))
      .catch((e) => console.log(e));
  }, [accountDetails]);
 const [show, setShow] = useState(false)
 const [show1, setShow1] = useState(false)
  const hideIcon = <FaEye />;
  const showIcon = <FaEyeSlash/>;
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <Box
      p="1rem"
      display={impData.flex}
      gap={{base:"1rem", md: "1rem", lg: "5%"}}
      flexDirection={{ base: "column", md: "row", lg: "row" }}
      flexWrap={{ base: "wrap", md: "wrap", lg: "nowrap" }}
      w="full"
      h="full"
    >

      {/* wallet balance  */}
      <div className="sm:w-full lg:w-[30%] md:w-[80%]">
        {/* <Box> */}
        <WithdrawalModel
          openModal={open}
          closeModal={handleClose}
        />
        <Box
          as="div"
          display={impData.flex}
          flexDirection="column"
          color="#fff"
          fontStyle="normal"
          fontWeight={500}
          p="1rem"
          gap="10px"
          borderRadius={10}
          style={{
            backgroundImage: `url(${walletBg})`,

            backgroundSize: "cover ",
            backgroundRepeat: "no-repeat",
          }}
          height="8rem"
        >
          <h3 syle={{ fontWeight: 600 }}>Wallet balance</h3>
          <Flex display="flex" justifyContent="space-between">
            <Text fontSize={24} fontWeight="bold" fontFamily="clash">
              NGN{!show1?`${accountDetails.accountBalance}`:' ***'}
            </Text>
            <button onClick={() => setShow1(!show1)}>{!show1? hideIcon:showIcon}</button>
          </Flex>
        </Box>
        <Flex mt="1rem" gap="10px">
          <button
            onClick={setShowFundingModal}
            className="cursor-pointer w-[50%] p-[10px] gap-[10px] flex items-center font-semibold text-white rounded-[50px] bg-[#263B95] justify-center"
          >
            Fund Wallet{fundIcon}
          </button>

          <Button
            w="50%"
            p="10px"
            
            style={{
              display: impData.flex,
              justifyContent: "center",
              padding: "10px",
              background: "#fff",
              cursor: "pointer",
              color: "#263B95",
              border: "1.5px solid #E9EAEC",
              borderRadius: "50px",
              alignItems: "center",
              fontWeight: 600,
              gap: "10px",
            }}
            onClick={handleOpen}
          >
            Withdraw{withdrawIcon}
          </Button>
        </Flex>
        {/* </Box> */}
      </div>

      {/* referral Balance */}
      <Box w={{ base: "100%", md: "80%", lg: "30%" }}>
        <Box
          bg={`url(${referralBalance})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          display={"flex" || "-webkit-flex"}
          flexDirection="column"
          color="#fff"
          p="1rem"
          gap="10px"
          borderRadius={10}
          height="8rem"
        >
          <Text fontWeight={500}>Referral Balance</Text>
          <Flex justifyContent="space-between">
            <Text fontFamily="clash" fontWeight="700" fontSize={24}>
              NGN{!show?`${accountDetails.referralBonus}`: '***'}
            </Text>
            <button onClick={() => setShow(!show)}>{!show? hideIcon:showIcon}</button>
          </Flex>
        </Box>

        <div className="flex items-center w-full">
        <Link
         href={'/sendmoney'}
        fontSize="16px"
        fontFamily="clash"
        fontWeight={500}
        color="#212337"
        >
        <button className="cursor-pointer flex items-center justify-center gap-[10px] mt-[1rem] rounded rounded-[50px] bg-white w-full border-[1px] border-solid border-[#E9EAEC] text-[#263B95] text-[18px] p-[10px]">
            Send to wallet {sendIcon}
          </button>
        </Link>
          
        </div>
      </Box>
      {/* bonus balance */}
      <Box
        w={{ base: "100%", md: "80%", lg: "30%" }}
        display={"flex" || "-webkit-flex" || "flex"}
        flexDirection="column"
        p="1rem"
        gap="10px"
        h="12rem"
        border="1px solid #E9EAEC"
        borderRadius={10}
        bg="#fff"
      >
        <Text fontWeight={600}>Bonus balance</Text>
        <Text fontSize={24} fontFamily="clash">
          NGN{accountDetails.accountBonus}
        </Text>
        <Link
          as="a"
          href="#"
          pb="5px"
          borderBottom="1px solid #263B95"
          w="fit-content"
          color="#263B95"
          fontWeight={600}
        >
          Complete tasks to earn bonus
        </Link>
        <Text display={"bloxk"} mt="1rem">
          Next payout date: <span style={{ fontWeight: 600 }}>MM/DD/YYYY</span>{" "}
        </Text>
      </Box>
    </Box>
  );
};
// header button

const withdrawIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    stroke="#263B95"
    viewBox="0 0 24 24"
  >
    <path
      stroke="#263B95"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity="0.6"
      strokeWidth="1.5"
      d="M7.4 6.32l8.49-2.83c3.81-1.27 5.88.81 4.62 4.62l-2.83 8.49c-1.9 5.71-5.02 5.71-6.92 0l-.84-2.52-2.52-.84c-5.71-1.9-5.71-5.01 0-6.92zM10.11 13.65l3.58-3.59"
    ></path>
  </svg>
);
const fundIcon = (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0109 12.8655H7.84424"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.92725 10.8318V14.9985"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6759 2.09853L10.6509 2.15686L8.23428 7.76519H5.85928C5.29261 7.76519 4.75094 7.88186 4.25928 8.09019L5.71761 4.60686L5.75094 4.52353L5.80928 4.39019C5.82594 4.34019 5.84261 4.29019 5.86761 4.24853C6.95928 1.72353 8.19261 1.14853 10.6759 2.09853Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1677 7.93172C14.7927 7.81506 14.3927 7.76506 13.9927 7.76506H8.23438L10.651 2.15672L10.676 2.09839C10.801 2.14006 10.9177 2.19839 11.0427 2.24839L12.8844 3.02339C13.9094 3.44839 14.626 3.89006 15.0594 4.42339C15.1427 4.52339 15.2094 4.61506 15.2677 4.72339C15.3427 4.84006 15.401 4.95672 15.4344 5.08172C15.4677 5.15672 15.4927 5.23172 15.5094 5.29839C15.7344 5.99839 15.601 6.85672 15.1677 7.93172Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0606 11.832V13.457C18.0606 13.6237 18.0522 13.7904 18.0439 13.957C17.8856 16.8654 16.2606 18.332 13.1772 18.332H6.67725C6.47725 18.332 6.27725 18.3154 6.08558 18.2904C3.43558 18.1154 2.01891 16.6987 1.84391 14.0487C1.81891 13.857 1.80225 13.657 1.80225 13.457V11.832C1.80225 10.157 2.81891 8.71538 4.26891 8.09038C4.76891 7.88205 5.30225 7.76538 5.86891 7.76538h34.0022C14.4106 7.76538 14.8106 7.82371 15.1772 7.93205C16.8356 8.44038 18.0606 9.99038 18.0606 11.832Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.71748 4.60669L4.25915 8.09002C2.80915 8.71502 1.79248 10.1567 1.79248 11.8317V9.39002C1.79248 7.02336 3.47581 5.04836 5.71748 4.60669Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0581 9.38963V11.8313C18.0581 9.99797 16.8415 8.43963 15.1748 7.93963C15.6081 6.8563 15.7331 6.0063 15.5248 5.29797C15.5081 5.22297 15.4831 5.14797 15.4498 5.0813C16.9998 5.8813 18.0581 7.52297 18.0581 9.38963Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const sendIcon = (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7091 9.99992V14.1666C18.7091 16.6666 17.0425 18.3333 14.5425 18.3333H6.20915C3.70915 18.3333 2.04248 16.6666 2.04248 14.1666V9.99992C2.04248 7.73325 3.40915 6.14992 5.53415 5.88325C5.75081 5.84992 5.97581 5.83325 6.20915 5.83325h34.5425C14.7591 5.83325 14.9675 5.84158 15.1675 5.87491C17.3175 6.12491 18.7091 7.71659 18.7091 9.99992Z"
      stroke="#263B95"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.169 5.87508C14.969 5.84175 14.7606 5.83342 14.544 5.83342H6.21064C5.97731 5.83342 5.75231 5.85009 5.53564 5.88342C5.65231 5.65009 5.81898 5.43342 6.01898 5.23342L8.72731 2.51675C9.86898 1.38341 11.719 1.38341 12.8606 2.51675L14.319 3.99176C14.8523 4.51676 15.1356 5.18341 15.169 5.87508Z"
      stroke="#263B95"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7091 10.4167h36.2091C15.2925 10.4167 14.5425 11.1667 14.5425 12.0834C14.5425 13.0001 15.2925 13.7501 16.2091 13.7501h38.7091"
      stroke="#263B95"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DashIntro;
