import {
  Box,
  Button,
  FormLabel,
  Image,
  Input,
  Text,
  FormControl,
  createStandaloneToast,
} from "@chakra-ui/react";
import backgroundImg from "../../assets/authbg.png";
import Logo from "../../assets/Icons/Logo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import leftBg from "../../assets/authLeftBg.png";
import { impData } from "../../impData";
import axios from "axios";
import { config } from "../../components/config";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AuthSuccessModal from "./Tab_components/Reg_pay_info_Partials/AuthSuccessModal";
import { useRecoilState, useRecoilValue } from "recoil";
import AuthPagesWrapper from "../../components/Templates/AuthPagesWrapper";
import { requestIdState } from "../../atoms/store";
const eyeIcon = <FaEye fontSize="20px" />;
const EmailAuthentication = () => {
  const requestIdValue = useRecoilValue(requestIdState);
  const [requestId, setRequestId] = useRecoilState(requestIdState);
  const [emailAddress, setEmailAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const { toast, ToastContainer } = createStandaloneToast();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(requestIdValue);
  }, []);
  const handleLogin = () => {
    if (emailAddress === "" || otpCode === "") {
      toast({
        description: "all input fields must be filled",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: false,
      });

    }
    setLoading(true);
    axios
      .post(`${config.api_url}/api/v3/w3/topupcliq-verify-email`, {
        userEmailAddress: emailAddress,
        otp: otpCode,
        requestId: requestIdValue,
      },
      {
        headers: {
          anonymousId: "",
          businessName:"topupbox"
        }
      }
    )
      .then((res) => {
        const fetchData = res.data;
        if (fetchData.status === "4000") {
          toast({
            description: fetchData.description,
            status: "error",
            position: "top",
            duration: 9000,
            isClosable: false,
          });
        }
        // storing the user response data
        if (fetchData.status === "2000") {
          localStorage.setItem("TOPUPCLIQ_USER_TOKEN", fetchData.data.token);
          
          console.log(fetchData.data);
          setOpenModal(true);
        }
      })
      .catch((e) => console.log(e));
  };
  const resendOtpHandler = () => {
    axios
      .post(`${config.api_url}/api/v3/w3//re-sendOtp?email=${emailAddress}`, {
        email: emailAddress,
      },
      {
        headers: {
          anonymousId: "",
          businessName:"topupbox"
        }
      }
    )
      .then((res) => {
        const resData = res.data;
        console.log(resData);
        if (resData.status === "4000") {
          toast({
            description: resData.description,
            status: "error",
            position: "top",
            duration: 9000,
            isClosable: false,
          });
        }
        if (resData.status === "2000") {
          toast({
            description: resData.description,
            status: "success",
            position: "top",
            duration: 9000,
            isClosable: false,
          });
          setRequestId(resData.data.requestId);
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <>
      <AuthPagesWrapper>
        <Box
          mx="auto"
          bg=" #FCFCFC"
          display="block"
          py="20"
          w={{ base: "100%", md: "55%" }}
        >
          <Image mx="auto" src={Logo} alt="" />
          <ToastContainer />
          <Box
            mt="5rem"
            // height="426px"
            mx="auto"
            w={{ base: "80%", md: "70%", lg: "60%" }}
            p={{ base: "10px", md: "40px " }}
            background="#fff"
            boxShadow="0px -20px 40px rgba(38, 59, 149, 0.02), 0px 20px 40px rgba(38, 59, 149, 0.02)"
          >
            <Text
              as="h3"
              style={{
                fontSize: "24px",
                fontWeight: "600",
                display: "block",

                textAlign: "center",
              }}
              my="1rem"
            >
              Verify your Email
            </Text>
            <form action="">
              <FormLabel fontSize="12px">Re-enter email</FormLabel>
              <Input
                onChange={(e) => setEmailAddress(e.target.value)}
                type="email"
                placeholder="jonDoe@gmail.com"
                border="none"
                borderRadius="0"
                borderBottom="1px solid #212337"
                outline="none"
                style={{ outlineStyle: "none" }}
              />
              <FormControl>
                <FormLabel fontSize="12px" mt="10">
                  Otp Code
                </FormLabel>

                <Input
                  onChange={(e) => setOtpCode(e.target.value)}
                  style={{ outlineStyle: "none" }}
                  type="number"
                  placeholder="enter in the otp code sent to you"
                  border="none"
                  borderRadius="0"
                  borderBottom="1px solid #212337"
                />
              </FormControl>

              <Button
                bg="#263B95"
                color="#fff"
                gap="1rem"
                borderRadius="50px"
                mt="10"
                _hover={{ backgroundColor: "#263B95" }}
                _focus={{ backgroundColor: "#263B95" }}
                fontSize="18px"
                w="100%"
                fontWeight="700"
                lineHeight="23px"
                h="68px"
                onClick={handleLogin}
              >
                Verify email
                <PulseLoader color="#fff" loading={loading} />
              </Button>
              <Button
                bg="#FF4040"
                color="#fff"
                gap="1rem"
                borderRadius={50}
                mt="10px"
                _hover={{
                  backgroundColor: "#FFF",
                  color: "#FF4040",
                  border: "1px solid #FF4040",
                }}
                fontSize="18px"
                w="100%"
                fontWeight="700"
                lineHeight="23px"
                h="68px"
                onClick={resendOtpHandler}
              >
                Resend otp Code
              </Button>
            </form>
          </Box>
        </Box>
      </AuthPagesWrapper>
      <AuthSuccessModal openModal={openModal} />
    </>
  );
};

export default EmailAuthentication;
