import React, { useEffect, useState } from "react";
import airtielLogo from "../../../assets/airtel.png";
import mtnLogo from "../../../assets/mtn.png";
import ninemobileLogo from "../../../assets/9mobile.png";
import gloLogo from "../../../assets/glo.png";
import { Box, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import { impData } from "../../../impData";
import { useRecoilState, useSetRecoilState } from "recoil";
import { chooseNetworkAtom } from "../../../atoms/store";
import PrintAirtimeFormModal from "../Modal/PrintAirtimeFormModal";
import EnterTransPinModal from "../Modal/EnterTransPinModal/EnterTransPinModal";

const PrintAirtimeIntro = () => {
  const {
    isOpen: openModal,
    onOpen: setOpenModal,
    onClose: closeModal,
  } = useDisclosure();
  const [network, setNetwork] = useState("");
  const [networkState, setNetworkState] = useRecoilState(chooseNetworkAtom);

  const chooseNetworkHandler = () => {
    // setNetwork(item.companyName);
    // setNetworkState(item.companyName);
    setOpenModal();
  };
  const {
    isOpen: openTransPinModal,
    onOpen: setOpenTransPinModal,
    onClose: closeTranspinModal,
  } = useDisclosure();
  return (
    <>
      <Box w="full" h="50%" background="#fff">
        <Flex
          w="full"
          flexDir="row"
          flexWrap={{ base: "wrap", md: "wrap", lg: "nowrap" }}
          gap={{ base: "1rem", md: "1rem", lg: "2%" }}
          alignItems="center"
          justifyContent={{ base: "", md: "space-between" }}
          m="10px"
        >
         
            <Box
              cursor={"pointer"}
              onClick={() => chooseNetworkHandler()}
              display={impData.flex}
              flexDirection="column"
              alignItems="center"
              
              padding="10px"
              width={{ base: "47%", md: "48%", lg: "23%" }}
              borderRadius="10px"
              border="1px solid #E9EAEC"
              gap="1rem"
              p="2rem"
            >
              
              <h3
                style={{ fontSize: 16, fontWeight: 500, textAlign: "center" }}
              >
                Buy EPINs
              </h3>
            </Box>
         
        </Flex>
      </Box>
      <PrintAirtimeFormModal
        openModal={openModal}
        closeModal={closeModal}
        setOpenModal={setOpenModal}
        setOpenTransPinModal={setOpenTransPinModal}
      />
      <EnterTransPinModal
        openTransPinModal={openTransPinModal}
        setOpenTransPinModal={setOpenModal}
        closeTransPinModal={closeTranspinModal}
      />
    </>
  );
};

const networksData = [
  {
    logo: ninemobileLogo,
    companyName: "9MOBILE",
  },
  {
    logo: airtielLogo,
    companyName: "AIRTEL",
  },
  {
    logo: mtnLogo,
    companyName: "MTN",
  },
  {
    logo: gloLogo,
    companyName: "GLO",
  },
];
export default PrintAirtimeIntro;
