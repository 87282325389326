import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  Input,
  useDisclosure,
  createStandaloneToast,
  PinInputField,
  PinInput,
  HStack
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { impData } from "../../../impData";
import EnterTransPinModal from "./EnterTransPinModal/EnterTransPinModal";
import { useRecoilState } from "recoil";
import { changePwdAtom } from "../../../atoms/store";
import { config } from "../../../components/config"; 
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import cookie from "react-cookies";
import { PulseLoader } from "react-spinners";
const ChangePasswordTab = ({ index }) => {
  const [idx, setIndex] = useState(0);
  const [newPassword, setNewPassword] = useState("");
  const [changePwdState, setChangePwd] = useRecoilState(changePwdAtom);
  const [oldPwd, setOldPwd] = useState("");
  const [transactionPin, setTransactionPin] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false)
  const {
    isOpen: showTransPinModal,
    onOpen: setShowTransPinModal,
    onClose: closeTransPinModal,
  } = useDisclosure();
  // handler for change password in the user dashboard
  const showTransPinModalHandler = () => {
    if (oldPwd === newPassword) {
      toast({
        description: "new and old password can't be the same",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: false,
      });
    }
    if (oldPwd === "" || newPassword === "" || confirmPassword === "") {
      toast({
        description: "input fields cannot be empty",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: false,
      });
    }
    if (newPassword !== confirmPassword) {
      toast({
        description: "password must match",
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: false,
      });
    }else{

      setShowTransPinModal()
    }
  };

  const onSubmit = () => {
    setLoading(true);
    const params = {
      "transactionPin": transactionPin,
      "newPassword": newPassword
    }
    console.log(params)
    axios
      .post(config.api_url + '/api/v3/w3/topupcliq-change-password', params, {
        headers: {
          Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then(res => {
        console.log(res.data);
        if (res.data.status == '2000') {
          toast.success(res.data.description, {
            position: toast.POSITION.TOP_CENTER
          })
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        } else {
          console.log(res.data)
          toast.error(res.data.description)
          setTimeout(() => {
            toast.error(res.data.description)
          });
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("Error while buying Airtime", {
          position: toast.POSITION.TOP_CENTER
        });
      })
      .finally(res => {
        setLoading(false);
      });
  }
  useEffect(() => {
    setIndex(3);
    setChangePwd(oldData=>{
      return {...oldData, newPassword:newPassword}
    })
  }, [newPassword]);
  return (
    <>
      <Box
        bg="#fff"
        fontFamily="clash"
        h="full"
        w="full"
        p={{ base: 0, md: "1rem" }}
        pt="2rem"
        display={idx === 3 ? impData.flex : 0}
        flexDir="column"
        gap="1.5rem"
      >
        <ToastContainer />{" "}
        <h1 className="text-[18px] font-[600] text-[#212337] md:text-center sm:text-center lg:text-left">
          Change your password
        </h1>
        <Box
          w={{ base: "100%", md: "100%", lg: "500px" }}
          m={{ base: 0, lg: "1.5rem 0", md: "1rem auto" }}
          display={impData.flex}
          flexDirection="column"
          borderRadius={10}
          gap="2rem"
          p="1.5rem 1rem"
        >
          <FormControl>
            <FormLabel fontSize={12} color="#263B95">
              Enter old password
            </FormLabel>
            <input
              className="py-[10px] border-b-[2px] focus:border-b-[#212337]  border-b-[#212337] placeholder:text-[#212337] placeholder:text-[600] px-[10px]  block w-full "
              value={oldPwd}
              type="text"
              onChange={(e) => setOldPwd(e.target.value)}
              name="fullName"
              placeholder="***********"
            />
          </FormControl>
          <FormControl>
            <input
              className="py-[10px] border-b-[2px] focus:border-b-[#212337]  border-b-[#212337] placeholder:text-[#212337] placeholder:text-[600] px-[10px]  block w-full "
              name="email"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Email new password"
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize={12}>confirm pasword</FormLabel>
            <input
              className="py-[10px] border-b-[2px] focus:border-b-[#212337]  border-b-[#212337] placeholder:text-[#212337] placeholder:text-[600] px-[10px]  block w-full "
              name="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
            />
          </FormControl>
          <HStack>
              <PinInput
                focusBorderColor="#212337"
                type="number"
                placeholder=""
                value={transactionPin}
                onChange={(value) => setTransactionPin(value)}
              >
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  w="71px"
                  borderRadius={16}
                />
                <PinInputField
                  background="#E9EAEC6B"
                  h="71px"
                  w="71px"
                  _focus={{ color: "#212337", border: "2px solid #263B95" }}
                  fontWeight={700}
                  fontSize={32}
                  borderRadius={16}
                />
              </PinInput>
            </HStack>
          <Button
            w="full"
            h="56px"
            bg="#263B95"
            borderRadius={56}
            color="#fff"
            fontSize={18}
            onClick={onSubmit}
            fontWeight="bold"
          >
           {
              loading ? <PulseLoader color="#fff" loading={loading} /> : "Change Password"
            }
          </Button>
        </Box>
      </Box>
      <EnterTransPinModal setShowTransPinModal={setShowTransPinModal} showTransPinModal={showTransPinModal} closeTransPinModal={closeTransPinModal}/>
    </>
  );
};

export default ChangePasswordTab;
