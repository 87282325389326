import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Text,
  HStack,
  Input,
  Link,
} from "@chakra-ui/react";
import React, { useState } from "react";

const Personal_Info = ({
  setPaging,
  setFirstName,
  setLastName,
  setDateOfBirth,
  setPhoneNumber,

  toastMessage: ToastMessage,
  setReferralId,
}) => {
  return (
    <Container
      w="full"
      minW="full"
      p={{ base: "1rem", md: "40px" }}
      border={"1px solid #f2f2f2"}
      borderRadius="2xl"
      mt="1rem"
      bg="#fff"
      boxShadow="0px -20px 40px rgba(38, 59, 149, 0.02), 0px 20px 40px rgba(38, 59, 149, 0.02"
    >
      <Text as="h1" fontSize={24} mb="2rem" fontWeight={500}>
        Create New Account
      </Text>
      <ToastMessage />
      <form>
        <Flex flexDir={"column"} gap="1.5rem">
          <HStack gap={10}>
            <FormControl>
              <FormLabel fontSize="12px">First name</FormLabel>
              <Input
                type="text"
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Alen"
                style={{
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid #212337",
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="12px">Last name</FormLabel>
              <Input
                type="text"
                name="lastName"
                placeholder="Walker"
                // onChange
                onChange={(e) => setLastName(e.target.value)}
                style={{
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid #212337",
                }}
              />
            </FormControl>
          </HStack>
          <FormControl>
            <FormLabel fontSize="12px">Phone number</FormLabel>
            <Input
              type="text"
              _placeholder={{ color: "black ", fontWeight: "100" }}
              name="phoneNumber"
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="eg.   09000000000"
              style={{
                border: "none",
                borderRadius: 0,
                borderBottom: "1px solid #212337",
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontSize="12px">Date of Birth</FormLabel>
            <Input
              type="date"
              name="date"
              onChange={(e) => setDateOfBirth(e.target.value)}
              style={{
                border: "none",
                borderRadius: 0,
                borderBottom: "1px solid #212337",
              }}
            />
          </FormControl>

          <Button
            onClick={() => setPaging(1)}
            bg="#263B95"
            borderRadius="50px"
            color="#fff"
            h="56px"
            d="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="700"
            fontSize="16px"
            _hover={{ background: "#263B95" }}
          >
            Next
          </Button>
          <Text>
            Already have an account?{" "}
            <Link
              href="/login"
              color="#263B95"
              fontSize={17}
              fontWeight={500}
              textDecor="none"
            >
              Login
            </Link>
          </Text>
        </Flex>
      </form>
    </Container>
  );
};

export default Personal_Info;
