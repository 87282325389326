import { Box, Text } from "@chakra-ui/react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { chooseNetworkAtom } from "../../atoms/store";
import { useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../../components/DashboardWrapper";
import { impData } from "../../impData";
import BuyAirtimeModal from "./Modals/BuyAirtime/BuyAirtimeModal";
import EnterTransactionPinModal from "./Modals/BuyAirtime/EnterTransactionPinModal";
import CableTvSubScribeFormModal from "./Modals/CableTvSubscribeModal/CableTvSubScribeFormModal";
import CableTvSubscribeModal from "./Modals/CableTvSubscribeModal/CableTvSubscribeModal";
import ElectricityBillsModal from "./Modals/ElectricityBills/ElectricityBillsModal";
import SelectPrepaid from "./Modals/ElectricityBills/SelectPrepaid";
import InternetServicesModal from "./Modals/InternetServices/InternetServicesModal";
import InternetSubscribeModal from "./Modals/InternetServices/InternetSubscribeModal";
import Networks from "./Networks/Networks";
import UssdCodes from "./Networks/UssdCodes";
import cookie from "react-cookies"
import PayBills from "./PayBills/PayBills";

const AirtimeAndBills = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (
      !cookie.load("TOPUPCLIQ_USER_TOKEN") ||
      cookie.load("TOPUCLIQ_USER_TOKEN") === ""
    ) {
      navigate("/login");
    }
  }, [])


  const {
    isOpen: openModal,
    onOpen: setOpenModal,
    onClose: closeModal,
  } = useDisclosure();

  const [network, setNetwork] = useState("");
  const [networkState, setNetworkState] = useRecoilState(chooseNetworkAtom);

  const chooseNetworkHandler = (item, index) => {
    setNetwork(item.companyName);
    setNetworkState(item.companyName);
    setOpenModal();
  };

  const {
    isOpen: openTransPinModal,
    onOpen: setOpenTransPinModal,
    onClose: closeTranspinModal,
  } = useDisclosure();

  return (
    <>
      <DashboardWrapper header="Airtime and Bills">
        <Box
          display={impData.flex}
          flexDirection={{ base: "column", md: "column", lg: "row" }}
          w="full"
          bg="#fff"
          py="1rem"
          gap="1rem"
          px="2rem"
          fontFamily="clash"
        >
          <Box
            w={{ base: "100%", md: "100%" }}
            flexDirection="column"
            display={impData.flex}
            gap="1rem"

          >
            <Text fontSize={18} fontWeight={600}>
              Buy airtime and Data
            </Text>
            <Text color="#21233799" fontSize={18}>
              Choose network
            </Text>
            <Networks chooseNetworkHandler={chooseNetworkHandler} />
            {/* <UssdCodes /> */}
            <Box w="50%"></Box>
          </Box>
          <Box
            w={{ base: "100%", md: "50%" }}
            display={impData.flex}
            flexDirection="column"
            gap="1rem"
          >
            <Text fontSize={18} fontWeight={600}>
              Pay bills
            </Text>
            <Text fontSize={16} color="#21233799" fontWeight={500}>
              Select bill
            </Text>
            <PayBills />
          </Box>
        </Box>
        <BuyAirtimeModal
          openModal={openModal}
          closeModal={closeModal}
          setOpenModal={setOpenModal}
          setOpenTransPinModal={setOpenTransPinModal} />
         
        <EnterTransactionPinModal />
        <ElectricityBillsModal />
        <SelectPrepaid />
        <InternetServicesModal />
        <InternetSubscribeModal />
        <CableTvSubscribeModal />
        <CableTvSubScribeFormModal />
      </DashboardWrapper>
    </>
  );
};

export default AirtimeAndBills;
