import {
  Box,
  Button,
  Flex,
  FormControl,
  Text,
  HStack,
  Input,
  Select,
} from "@chakra-ui/react";
import cookie from "react-cookies";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../../../../components/config";
import { impData } from "../../../../impData";
import { fundWalletDataAtom, loginEmailState } from "../../../../atoms/store";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { usePaystackPayment } from "react-paystack";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FundingModalFundCardForm = ({ setTransModal }) => {
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const [fundingData, setFundingData] = useRecoilState(fundWalletDataAtom);
  const [loading, setLoading] = useState(false);

  const email = cookie.load("TOPUPCLIQ_USER_EMAIL");
  const initialConfig = { reference: null, email: cookie.load("TOPUPCLIQ_USER_EMAIL"), amount: 0, publicKey: "pk_live_a36043760d0eb68ef8a09f98553e405a85232cee", };
  const [configP, setConfig] = useState(initialConfig);
  let initializePayment = usePaystackPayment(configP);
  const [cardNum, setCardNum] = useState("");
  // card number input design setting
  const handleCardDisplay = () => {
    const rawText = [...cardNum.split(" ").join("")]; // Remove old space
    const creditCard = []; // Create card as array
    rawText.forEach((t, i) => {
      if (i % 4 === 0) creditCard.push(" "); // Add space
      creditCard.push(t);
    });
    return creditCard.join(""); // Transform card array to string
  };

  const verifyTransaction = (reference) => {
    setLoading(true);
    console.log(
      config.api_url + '/v3/w3/topupcliq-verify-wallet?reference=' + reference,
    );
    axios
      .get(
        config.api_url + '/api/v3/w3/topupcliq-verify-wallet?reference=' +
        reference,
        {
          headers: {
            Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
            Connection: 'keep-alive',
          },
        },
      )
      .then(res => {
        console.log(res.data);
        if (res.data.status == '2000') {
          toast.success(res.data.description);
          setTimeout(() => {
            navigate("/dashboard")
          }, 2000);
        } else {
          toast.error(res.data.description)
        }
      })
      .catch(err => {
        console.log(err);
        toast.show({
          type: 'error',
          text1: err.message,
        });
        setTimeout(() => {

        }, 1000);
      }).finally(res => {
        setLoading(false);
      });
  }

  const onSubmit = () => {
    setLoading(true);
    let amount_p = parseInt(amount) * 100;
    axios
      .post(
        config.api_url + '/api/v3/w3/topupcliq-fund-wallet',
        {
          email: email,
          callback_url: 'topupcliq.com',
          channel: [
            'card',
            'bank',
            'ussd',
            'qr',
            'mobile_money',
            'bank_transfer',
            'eft',
          ],
          amount: amount_p.toString(),
        },
        {
          headers: {
            Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
          },
        },
      )
      .then(res => {
        console.log(res.data);
        if (res.data.status == '2000') {
          // this.setState({
          //   reference: res.data.data?.reference,
          //   accessCode: res.data.data?.accessCode,
          // });
          // console.log(this.reference);
          //this.verifyTransaction(this.reference);
          // this.paystackWebViewRef.current.startTransaction();
          setConfig({
            ...configP, channel: [
              'card',
              'bank',
              'ussd',
              'qr',
            'mobile_money',
              'bank_transfer',
              'eft',
            ], email: email, reference: res.data.data?.reference, amount: amount_p,
          });
        } else {
          //this.closeSheet();
          setTimeout(() => {

          }, 1000);
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("Error while initializing transaction", {
          position: toast.POSITION.TOP_CENTER
        });
      }).finally(res => {
        setLoading(false);
      });
  }

  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    verifyTransaction(reference.reference)
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }



  useEffect(() => {
    const data = { email, amount, callback_url: config.callback_url };
    setFundingData(oldData => {
      return { ...oldData, ...data }
    })

  }, [amount]);


  useEffect(() => {
    console.log(configP)
    if (configP.reference === null || configP.email === undefined) return;
    initializePayment(onSuccess, onClose)
  }, [configP])

  return (
    <Box
      w="full"
      h="full"
      bg="#fff"
      my="1rem"
      fontFamily="clash"
      display={impData.flex}
      flexDir="column"
    >
      <ToastContainer />
      <Text textAlign="center" fontSize={20} fontWeight={600} color="#21233799">
        ENTER AMOUNT
      </Text>
      <Box
        display={impData.flex}
        justifyContent="center" alignItems="flex-end"
        w="full"
        margin="auto"
      >
        <span
          style={{ fontSize: 25, color: "#21233780" }}
          className=""
        >
          NGN
        </span>
        <span className="bg-none border-0 outline-none text-[40px] ">{amount}</span>
      </Box>
      <form action="">
        <Flex flexDir="column" outline={0} gap="2rem">
          {/* <FormControl>
            <FormLabel fontSize={12}>Select card</FormLabel>
            <Select
              placeholder="New card"
              _placeholder={{ fontWeight: 600, fontSize: 15 }}
              border="none"
              borderRadius="none"
              outline={0}
              onChange={(e) => setCard(e.target.value)}
              borderBottom="2px solid #263B95"
            >
              <option value="GtBank">Gt Bank Plc</option>
            </Select>
          </FormControl>
          <FormControl mt={"10px"}>
            <FormLabel fontSize={12}>Card number</FormLabel>
            <Input
              placeholder="0000 0000 0000 0000"
              value={handleCardDisplay()}
              onChange={(e) => setCardNum(e.target.value)}
              _placeholder={{ fontWeight: 600, fontSize: 16 }}
              border="none"
              borderRadius="none"
              outline={"none"}
              fontWeight={600}
              letterSpacing="wider"
              borderBottom="2px solid #263B95"
            />
  </FormControl>*/}
          <FormControl>
            <HStack gap="1rem">
              {/*  <Input
                placeholder="cvv"
                _placeholder={{
                  color: "#21233740",
                  fontWeight: 700,
                  fontSize: 16,
                }}
                onChange={(e) => setCvv(e.target.value)}
                border="none"
                borderRadius="none"
                borderBottom="2px solid black"
              />
 */}{" "}
              <Input
                placeholder="Amount"
                onChange={(e) => {
                  let text = e.target.value;
                  if (isNaN(text)) {
                  } else {
                    setAmount(e.target.value)
                  }
                }}
                _placeholder={{
                  color: "#21233740",
                  fontWeight: 700,
                  fontSize: 16,
                }}
                value={amount}
                border="none"
                borderRadius="none"
                borderBottom="2px solid black"
              />
            </HStack>
          </FormControl>
          <Button
            fontSize={18}
            mt="2rem"
            bg="#263B95"
            borderRadius={50}
            h="56px"
            color="#fff"
            fontWeight={600}
            onClick={onSubmit}
          >
            {
              loading ? <PulseLoader color="#fff" loading={loading} /> : "Continue"
            }
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default FundingModalFundCardForm;
