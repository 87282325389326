import {
  Box,
  Button,
  Text,
  HStack,
  Image,
  
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import bankTransferIcon from "../../../../assets/bankTransfer.png";
import cookie from "react-cookies";
import { impData } from "../../../../impData";
import { toast } from "react-toastify";
import { config } from "../../../../components/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const BankTransfer = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [loading, setLoading] = useState(false);
   // copy to clipboard function
  const copyToClipBoard = async () => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(virtual?.accountNumber??'');
    } else {
      return document.execCommand("copy", true, "i am copied");
    }
  };

  
  const [virtual, setVirtual] = useState({})

  useEffect(()=>{
    axios
    .get(
      config.api_url + '/api/v3/w3/get-virtual-account',
      {
        headers: {
          Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
          Connection: 'keep-alive',
        },
      },
    )
    .then(res => {
      console.log(res.data);
      if (res.data.status == '2000') {
       setVirtual(res.data.data)
      } else {
        toast.error(res.data.description)
      }
    })
    .catch(err => {
      console.log(err);
      toast.show({
        type: 'error',
        text1: err.message,
      });
      setTimeout(() => {

      }, 1000);
    }).finally(res => {
      setLoading(false);
    });
  },[])

  return (
    <Box
      h="full"
      fontFamily="clash"
      display={impData.flex}
      flexDirection="column"
      alignItems="center"
      gap="2rem"
      bg="#ffffff"
      my="1rem"
      pt="20"
      w="inherit"
    >
      <Image src={bankTransferIcon} />
      <Text
        fontSize={20}
        fontWeight={600}
        color="#21233799"
        textAlign="center"
        fontStyle="normal"
      >
        Send money to the bank account details below to fund your wallet.
      </Text>
      <Box
        w="full"
        display={impData.flex}
        flexDirection="column"
        fontFamily="fonts.clash"
        gap="2rem"
      >
        <HStack justifyContent="space-between" fontSize={16}>
          <Text fontWeight={500} color="#21233799">
            {" "}
            Bank
          </Text>
          <Text color="#263B95" fontWeight={600}>
          {virtual?.bankName??''}
          </Text>
        </HStack>
        <HStack justifyContent="space-between" w="full">
          <Text fontWeight={500} color="#21233799">
            Account name
          </Text>
          <Text  color="#263B95" fontWeight={600} wordBreak="keep-all" fontSize={{base: 16, md: 18}}>
          {virtual?.accountName??''}
          </Text>
        </HStack>
        <HStack justifyContent="space-between" fontSize={16}>
          <Text fontWeight={500} color="#21233799">
            Account number
          </Text>
          <Text cursor={"none"}>
          {virtual?.accountNumber??''}
            <Button
              color="#fff"
              bg="#263B95"
              w="80px"
              h="32px"
              textAlign="center"
              borderRadius={50}
              onClick={() => {
                copyToClipBoard()
                  .then(() => {
                    setIsCopied(true);
                    setTimeout(() => setIsCopied(false), 10000);
                  })
                  .catch((err) => console.log(err));
              }}
            >
              {isCopied ? "copied" : "copy"}
            </Button>
          </Text>
        </HStack>
      </Box>
    </Box>
  );
};

export default BankTransfer;
