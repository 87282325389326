import {
    Box,
    Button,
    FormLabel,
    Input,
    Text,
    Link,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import axios from "axios";
  import { config } from "../../components/config";
  import { PulseLoader } from "react-spinners";
  import { useNavigate } from "react-router-dom";
  import { createStandaloneToast } from "@chakra-ui/react";
  import AuthPagesWrapper from "../../components/Templates/AuthPagesWrapper";
  import { useRecoilState, useSetRecoilState } from "recoil";
  import { loginEmailState, requestIdState, tokenState } from "../../atoms/store";
  import cookie from "react-cookies";
  import { impData } from "../../impData";
  const ForgetPassword = () => {
    const { toast, ToastContainer } = createStandaloneToast();
    const setRequestId = useSetRecoilState(requestIdState);
    const [emailAddress, setEmailAddress] = React.useState("");
    // email state atom
    const [emailAtom, setEmailAtom] = useRecoilState(loginEmailState);
   
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      cookie.save("TOPUPCLIQ_USER_TOKEN", "");
    }, []);
    const navigate = useNavigate();
    // clear cureent user on component  mount
  
 
    // go to reset password handler
    const goResetHandler = () => {
      if (emailAddress === "" || emailAddress === " ") {
        toast({
          description: "your must input you email in the input field",
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: false,
        });
      }
      setLoading(true);
      axios
        .post(
          `${config.api_url}/api/v3/w3/topupcliq-forgot-password?email=${emailAddress}`,
          {},
          {
            headers: {
              anonymousId: "",
              businessName:"topupbox"
            }
          }
        )
        .then((res) => {
          if (res.data.status === "2000") {
            setLoading(true);
            navigate("/resetpassword", {
              emailAddress: emailAddress,
              requestId: res.data.data.requestId,
            });
            setEmailAtom(emailAddress);
            setRequestId(res.data.data.requestId);
          }
          if (res.data.status === "4000") {
            toast({
              description: res.data.description,
              status: "error",
              position: "top",
              duration: 9000,
              isClosable: false,
            });
          }
        })
        .catch((e) => {
          toast({
            description: e,
            status: "error",
            position: "top",
            duration: 9000,
            isClosable: false,
          });
          console.log(e);
        });
    };
  
    // login component set up
    return (
      <AuthPagesWrapper>
        <Box
          mx="auto"
          bg=" #FCFCFC"
          display="block"
          py="20"
          w={{ base: "100%", md: "50%", lg: "50%" }}
        >
          <Box
            mt="5rem"
            // height="426px"
            mx="auto"
            w={{ base: "90%", md: "90%", lg: "60%" }}
            p={{ base: "10px", md: "2rem 1rem", lg: "2rem" }}
            background="#fff"
            boxShadow="0px -20px 40px rgba(38, 59, 149, 0.02), 0px 20px 40px rgba(38, 59, 149, 0.02)"
          >
            <Text
              as="h3"
              style={{
                fontSize: "24px",
                display: "block",
                textAlign: "center",
              }}
              fontWeight={500}
              color="#212337"
              my="1rem"
            >
              Forget Password
            </Text>
            <ToastContainer />
  
            <form action="">
              <FormLabel fontSize="12px">Email Address</FormLabel>
              <Input
                onChange={(e) => setEmailAddress(e.target.value)}
                type="email"
                placeholder="jonDoe@gmail.com"
                border="none"
                borderRadius="0"
                borderBottom="1px solid #212337"
                outline="none"
                style={{ outlineStyle: "none" }}
              />
             
             
              <Button
                bg="#263B95"
                color="#fff"
                borderRadius="50px"
                mt="10"
                _hover={{ backgroundColor: "#263B95" }}
                _focus={{ backgroundColor: "#263B95" }}
                fontSize="18px"
                w="100%"
                fontWeight="700"
                lineHeight="23px"
                display={impData.flex}
                gap="1rem"
                h="56px"
                onClick={goResetHandler}
              >
                Continue
                <PulseLoader color="#fff" loading={loading} />
              </Button>
            </form>
            <Text textAlign="center" mt="1rem">
              
              <Link
                ml={"10px"}
                fontSize={18}
                fontWeight={600}
                href="/login"
                style={{ textDecoration: "none" }}
                color="#263B95"
              >
                Login
              </Link>
            </Text>
          </Box>
        </Box>
      </AuthPagesWrapper>
    );
  };
  
  export default ForgetPassword;
  