import { Box, Flex, Link } from "@chakra-ui/react";
import React from "react";
import BuyAirtime from "../../assets/Buy airtime.png";
import {Link as RouterLink} from "react-router-dom"
import PayForUtils from "../../assets/PayForUtils.png";
import Referral from "../../assets/Rewards.png";
import { impData } from "../../impData";
const WhySection = () => {
  return (
    <Box px={{base: "5%", md: "20"}} mt="16" w={"full"}>
      <h1
      className="sm:text-[30px] md:text-[32px]"
        style={{
          textAlign: "center",
          
          padding: "40px 0px",
          color: "#263B95",
          fontWeight: "bold",
        }}
      >
        Why use Top up Cliq?
      </h1>
      <Box
        style={{
          display:impData.flex,
          gap: 10,
          marginTop: 10,
        }}
        justifyContent={{base: "center",md:"space-between"}}
        flexDirection={{base: "column", md: "row"}}
      >
        {data.map((item, i) => (
          <Box
            key={i}
            w={{base: "100%", md: "29%"}}
            h={"476px"}
            marginBottom="20"
            border="1px"
            borderColor="gray.200"
            borderRadius={12}
            bg="white"
            style={{
              position: "relative",
            }}
          >
            <img
              src={item.image}
              style={{
                width: "100%",
                marginTop: 0,
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            />
            <Box
              py="15px"
              px="30px"
              style={{
                display: impData.flex,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              alignItems={{base: "center", md: ""}}
            >
              <h2
                style={{
                  fontSize: 20,
                  marginTop: 10,
                  fontWeight: "600",
                }}
              >
                {item.header}
              </h2>
              <p
                style={{
                  marginTop: 10,
                  color: `rgba(0, 0, 0, 0.6)`,
                  width: "100%",
                  textAlign: "justify",
                }}
              >
                {item.bodyText}
              </p>
              <RouterLink to={item.link}>

              <Box
                border="1px solid #E9EAEC "
                display="block"
                mt="12"
                rounded="3xl"
                href="#"
                style={{
                  position: "absolute",
                  bottom: 20,
                  padding: "0.5rem 1rem",
                  color: "#253d95",
                  fontWeight: "600",
                  width: "70%",
                  height: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                >
                {item.btnText}
              </Box>
                </RouterLink>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const data = [
  {
    image: BuyAirtime,
    header: "Buy airtime & Data bundles",
    bodyText:
      "You can buy Data bundle and Airtime of any network from us at a very affordable prices.",
    btnText: "Buy now",
    link: "/airtimeandbills"
  },

  {
    image: PayForUtils,
    header: "Pay for utilities",
    bodyText:
      "Pay your bills like Cable subscriptions, Electricity bills etc. And earn some % discount..",
    btnText: "Pay now",
    link: "/airtimeandbills"
  },
  {
    image: Referral,
    header: "Refer & earn",
    bodyText:
      "You can make extra cash with referral bonuses when you invite a friend to TopupBox CONNECT.    See T’s & C’s ",
    btnText: "Share your referral link",
    link: "/dashboard"
  },
];
export default WhySection;
