import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import succes_icon from "../../../../assets/Suuccess_Icon.png";
import { impData } from "../../../../impData";
import failureImg from "../../../../assets/ERROR.png"
const WalletFundedFailureModal = ({
  fundFailureModal,
  closeFundFailureModal,
}) => {
  return (
    <Modal isOpen={fundFailureModal} onClose={closeFundFailureModal} w={548}>
      <ModalOverlay
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.1)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <ModalCloseButton
        as="button"
        border="1px thin #E9EAEC"
        m="1rem"
        w="50px"
        h="50px"
        borderRadius={50}
        background="#E9EAEC"
      />
      <ModalContent w={{ base: "90%", md: 548 }} m="auto" py="1rem">
        <ModalBody>
          <Flex
            display={impData.flex}
            flexDirection="column"
            textAlign="center"
            p="1rem"
            gap="1rem"
            alignItem="center"
          >
            <Box
              h={"156px"}
              w={"156px"}
              borderRadius={"50%"}
              margin="auto"
              bg="rgba(255, 0, 0, 0.6);"
              color="#fff"
              display={impData.flex}
              justifyContent="center"
              alignItems="center"
              fontSize={75}
            >
              &#x1F5D9;
            </Box>
            <Text fontSize={24} fontWeight={600}>
              Wallet funded
            </Text>
            <Text>Failure to fund wallet</Text>
            <Button
              fontSize={18}
              bg="#263B95"
              borderRadius={50}
              h="56px"
              color="#fff"
              fontWeight={600}
            >
              Continue
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WalletFundedFailureModal;
