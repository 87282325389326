import { CloseIcon } from "@chakra-ui/icons";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import BankTransfer from "./BankTransfer/BankTransfer";
import FundingModalFundCardForm from "./FundingModalFundCardForm/FundingModalFundCardForm";
import { impData } from "../../../impData";

const FundingModal = ({ showFundingModal, closeMe, setShowPinModal, setTransModal }) => {
  return (
    <Modal
      isOpen={showFundingModal}
      closeOnOverlayClick={true}
      onClose={closeMe}
    >
      <ModalOverlay
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.1)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <ModalContent m="auto" width={{ base: "95%", md: "50%" }}>
        <ModalBody fontFamily="clash" py="1.5rem" px="1rem">
          <ModalCloseButton
            border="1px thin #E9EAEC"
            m={{ base: "1.5rem 6px", md: "1rem" }}
            w={{ md: "50px", base: "40px" }} a
            h={{ md: "50px", base: "40px" }}
            borderRadius={50}
            background="#E9EAEC"
          />
          <Tabs
            fontFamily="fonts.clash"
            h={{ base: "60%", md: "70%" }}
            size={{ base: "md", lg: "lg" }}
            variant="soft-rounded"
            align="start"
            colorScheme="#fff"
          >
            <Flex>
              <TabList
                fontFamily="fonts.clash"
                background={"#E9EAEC"}
                borderRadius={50}
                p="5px"
              >
                <Tab
                  _selected={{
                    bg: "white",
                    color: "#263B95",
                    border: "none",
                    fontWeight: 500,
                  }}
                >
                  Fund with card
                </Tab>
                <Tab
                  _selected={{
                    bg: "white",
                    color: "#263B95",
                    border: "none",
                    fontWeight: 500,
                  }}
                >
                  Bank Transfer
                </Tab>
              </TabList>
            </Flex>
            <TabPanels>
              <TabPanel w="full" position="">
                <FundingModalFundCardForm setTransModal={setTransModal} />
              </TabPanel>
              <TabPanel>
                <BankTransfer />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FundingModal;
