import {
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  HStack,
  FormHelperText,
  createStandaloneToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { config } from "../../../components/config";
import axios from "axios";

const Account_info = ({
  setPaging,
  setEmail,
  setConfirmPassword,
  toastMessage: ToastMessage,
  setPassword,
  password,
  bvn,
  phoneNumber,
  setReferralId,
  setBvn,
  setPhoneNumber, referralId, email, setRequestId, setUuid
}) => {

  const [isloading, setIsLoading] = useState(false);
  console.log(phoneNumber, referralId, email, bvn);
  const { toast, ToastContainer } = createStandaloneToast();

  const verifyEmailonSignUp = () => {
    setIsLoading(true);
    const params = {
      "emailAddress": email,
      "phoneNumber": phoneNumber,
      "bvn": bvn,
      "password": password,
      "referalId": referralId,
    };

    axios
      .post(
        config.api_url + '/api/v3/w3/signup-reseller',
        params,
        {
          headers: {
            anonymousId: "",
            businessName:"topupbox"
          }
        }
      )
      .then(res => {
        let iflog = res.data.status == 2000 ? true : false;
        if (iflog && res.data.data !== null) {
          setRequestId(res.data.data.requestId);
          setUuid(res.data.data.uuid);
          setPaging(1)
        } else {
          console.log(params);
          toast({
            description: res.data.description,
            status: "error",
            position: "top",
            duration: 6000,
            isClosable: false,
          });
        }
      }).catch(err => {
        console.log(err);
        toast({
          description: "An error occured while verifying email",
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: false,
        });

      }).finally(res => {
        setIsLoading(false);
      });
  }

  return (
    <Container
      p={{ base: "1rem", md: "40px" }}
      minW="full"
      border={"1px solid #f2f2f2"}
      borderRadius="2xl"
      mt="1rem"
      bg="#fff"
      boxShadow="0px -20px 40px rgba(38, 59, 149, 0.02), 0px 20px 40px rgba(38, 59, 149, 0.02"
    >
      <Text as="h1" fontSize={24} mb="2rem" fontWeight={500}>
        Create New Account
      </Text>
      <ToastMessage />
      <form>
        <Flex flexDir={"column"} gap="1.5rem">
          <FormControl>
          <FormLabel fontSize="12px">Email</FormLabel>
            <Input
              type="text"
              name="email"
              placeholder="jonDoe@gmail.com"
              bg="none"
              style={{
                border: "none",
                borderRadius: 0,
                borderBottom: "1px solid #212337",
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <HStack gap={10}>
            <FormControl>
             <FormLabel fontSize="12px">BVN</FormLabel>
              <Input
                type="text"
                name="bvn"
                background="none"
                style={{
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid #212337",
                }}
                onChange={(e) => setBvn(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="12px">Phone number</FormLabel>
              <Input
                type="text"
                name="phoneNumber"
                onChange={(e) => setPhoneNumber(e.target.value)}
                background="none"
                style={{
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid #212337",
                }}
              />
            </FormControl>
          </HStack>
          <HStack gap={10}>
            <FormControl>
             <FormLabel fontSize="12px">Password</FormLabel>
              <Input
                type="password"
                name="password"
                background="none"
                style={{
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid #212337",
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="12px">Repeat password</FormLabel>
              <Input
                type="password"
                name="repeatPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
                background="none"
                style={{
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid #212337",
                }}
              />
            </FormControl>
          </HStack>
          <FormControl>
            <FormLabel fontSize="12px" mb={0}>
              Referral ID
            </FormLabel>
            <Input
              marginTop="0"
              type="text"
              name="referralID"
              background="none"
              placeholder="2354446"
              style={{
                border: "none",
                borderRadius: 0,
                background: "none",
                borderBottom: "1px solid #212337",
              }}
              onChange={(e) => setReferralId(e.target.value)}
            />
            <FormHelperText textAlign="left">[optional]</FormHelperText>
          </FormControl>
          <Button
            onClick={() => {
              verifyEmailonSignUp()
            }}
            bg="#263B95"
            borderRadius="50px"
            color="#fff"
            h="56px"
            d="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="700"
            fontSize="16px"
            _hover={{ background: "#263B95" }}
            isLoading={isloading}
          >
            Next
          </Button>
          <Button
            onClick={() => setPaging(0)}
            bg="#263B9500"
            borderRadius="50px"
            borderColor={"#f2f2f2"}
            borderWidth="1px"
            color="#263B95"
            h="56px"
            d="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="700"
            fontSize="16px"
            _hover={{ background: "#f2f2f2" }}
          >
            Previous
          </Button>
          <Text>
            Already have an account?{" "}
            <Link
              color="#263B95"
              fontSize={17}
              fontWeight={500}
              textDecor="none"
              href="/login"
            >
              Login
            </Link>
          </Text>
        </Flex>
      </form>
    </Container>
  );
};

export default Account_info;
