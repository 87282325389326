import {
  Input,
  Box,
  FormControl,
  FormLabel,
  Text,
  Avatar,
  Flex,
  Button,
  useDisclosure,
  PinInputField, HStack, PinInput
} from "@chakra-ui/react";
import cookie from "react-cookies";
import React, { useState } from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import { impData } from "../../impData";
import EnterTransactionPinModal from "./Modal/EnterTransactionPinModal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { PulseLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../components/config";
import axios from "axios";


const SendMoneyPage = (props) => {
  const {
    isOpen: openTransPinModal,
    onOpen: setOpenTransPinModal,
    onClose: closeTranspinModal,
  } = useDisclosure();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (
      !cookie.load("TOPUPCLIQ_USER_TOKEN") ||
      cookie.load("TOPUCLIQ_USER_TOKEN") === ""
    ) {
      navigate("/login");
    }
  }, []);

  const [loading, setloading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState(0);
  const [pin, setPin] = useState("");

  const onSend = () => {
    setloading(true);
    const params = {
      amountfunded: amount,
      description: "string",
      recieverEmailAddress: phoneNumber,
      transactionPin: pin,
      deviceId: "",
    };
    axios
      .post(config.api_url + '/api/v3/w3/transfer-fund', params, {
        headers: {
          Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
          deviceId: "",
        },
      })
      .then(res => {
        if (res.data.status == '2000') {
          toast.success(res.data.description)
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        } else {
          console.log(res.data);
          toast.error(res.data.description)
          setTimeout(() => {
          });
        }
      })
      .catch(err => {
        console.log(err);
        // check if error code is 401
        toast.error("An Error Occured");
      })
      .finally(res => {
        setloading(false);
      });
  }

  return (
    <>
      <DashboardWrapper header="Send Money">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Box
          bg="#FAFAFA"
          w="full"
          h="full"
          display={impData.flex}
          mt={0}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            bg="#fff"
            p="1rem 3rem"
            pt="2rem"
            display={impData.flex}
            flexDirection="column"
            gap="2rem"
            h="80%"
            w={{ base: "90%", md: "85%", lg: "40%" }}
            fontFamily="clash"
          >
            <Text
              fontFamily="clash"
              fontSize={20}
              fontWeight="600"
              textAlign="center"
            >
              Send money to cliq users
            </Text>
            <Text
              fontFamily="clash"
              fontSize={20}
              fontWeight="600"
              textAlign="center"
            >
              ENTER AMOUNT
            </Text>
            <Text
              fontFamily="clash"
              textAlign="center"
              fontSize="40px"
              fontWeight="700"
            >
              <span className="text-[50px] font-bold  text-[#212337]">NGN </span>
              <span className="text-[70px] font-bold ">{amount}</span>
            </Text>

            <FormControl>
              <FormLabel>Email address</FormLabel>
              <Input
                borderRadius="none"
                placeholder="eg. jonDoe@gmail.com"
                _placeholder={{
                  fontWeight: 100,
                  fontSize: 16,
                  color: "#212337",
                }}
                onChange={(e) =>
                  setPhoneNumber(e.target.value)
                }
                value={phoneNumber}
                py="10px"
                border="0"
                borderBottom="2px solid #263B95"
                _focus={{ borderBottomColor: "" }}
              />
              <FormLabel className="mt-3">Amount</FormLabel>
              <Input
                borderRadius="none"
                placeholder="eg. jonDoe@gmail.com"
                _placeholder={{
                  fontWeight: 100,
                  fontSize: 16,
                  color: "#212337",
                }}
                py="10px"
                border="0"
                onChange={(e) => {
                  let text = e.target.value;
                  if (isNaN(text)) {
                  } else {
                    setAmount(e.target.value)
                  }
                }}
                value={amount}
                borderBottom="2px solid #263B95"
                _focus={{ borderBottomColor: "" }}
              />
              <FormLabel
                className="mt-3"
                fontSize={12} color="#263B95">Transaction Pin</FormLabel>
              <HStack>
                <PinInput
                  focusBorderColor="#212337"
                  type="number"
                  manageFocus={true}
                  placeholder=""
                  value={pin}
                  onChange={(value) => setPin(value)}
                >
                  <PinInputField
                    background="#E9EAEC6B"
                    h="50px"
                    w="50px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    borderRadius={16}
                  />
                  <PinInputField
                    background="#E9EAEC6B"
                    h="50px"
                    w="50px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    borderRadius={16}
                  />
                  <PinInputField
                    background="#E9EAEC6B"
                    mr="10px"
                    h="50px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    w="50px"
                    borderRadius={16}
                  />
                  <PinInputField
                    background="#E9EAEC6B"
                    h="50px"
                    w="50px"
                    _focus={{ color: "#212337", border: "2px solid #263B95" }}
                    fontWeight={700}
                    fontSize={32}
                    borderRadius={16}
                  />
                </PinInput>
              </HStack>
              <Button
                bg="#263B95"
                fontWeight="700"
                h="56px"
                fontSize="18px"
                borderRadius="50px"
                mt="2rem"
                color="#fff"
                onClick={() => {
                  onSend()
                }}
              >
                {
                  loading ? <PulseLoader color="#fff" loading={loading} /> : "Send money"
                }
              </Button>
            </FormControl>
          </Box>
        </Box>
        <EnterTransactionPinModal
          openTransPinModal={openTransPinModal}
          setOpenTransPinModal={setOpenTransPinModal}
          closeTransPinModal={closeTranspinModal}
        />
      </DashboardWrapper>
    </>
  );
};

export default SendMoneyPage;
