import { Box, Flex, Link, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { impData } from "../../../impData";

const QuickTransactions = () => {
  return (
    <Box
      w="full"
      h="full"
      display={impData.flex}
      flexWrap="wrap"
      gap={{ base: "10px", md: "1rem", lg: "1.5rem" }}
    >
      {data.map((item, idx) => (
        <Card
          key={idx}
          icon={item.icon}
          title={item.title}
          desc={item.desc}
          link={item.link}
          iconBg={""}
        />
      ))}
    </Box>
  );
};

const Card = ({ icon, title, desc, iconBg, link }) => {
  return (
    <Box
      width={{ base: "47%", md: "47%", lg: "47%" }}
      h={{ base: "160px" }}
      p={{ base: 0, md: "10px", lg: "10px" }}
      textAlign="center"
      borderRadius={15}
      display={impData.flex}
      flexDirection="column"
      alignItems="center"
      border="1px solid #E9EAEC"
      bg="#fff"
    >
      <Box
        w="70px"
        h="70px"
        display={"flex" || "-ms-flexbox" || "-webkit-flex"}
        bg={iconBg}
        borderRadius="50%"
        alignItems="center"
        justifyContent="center"
        fontFamily="clash"
      >
        {icon}
      </Box>
      <Link
        href={link}
        fontSize="16px"
        fontFamily="clash"
        fontWeight={500}
        color="#212337"
      >
        {title}
      </Link>
      <Text
        overflowWrap={{ base: "break-word", md: "unset" }}
        w="full"
        wordBreak={{ base: "break-all", md: "keep-all" }}
        fontSize={{ base: 14, md: 18, lg: 18 }}
      >
        {desc}
      </Text>
    </Box>
  );
};
export default QuickTransactions;
const data = [
  {
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.86625 8.42663L21.1862 4.65329C26.2662 2.95996 29.0262 5.73329 27.3462 10.8133L23.5729 22.1333C21.0396 29.7466 16.8796 29.7466 14.3462 22.1333L13.2262 18.7733L9.86625 17.6533C2.25292 15.12 2.25292 10.9733 9.86625 8.42663Z"
          stroke="#22AD80"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4795 18.2L18.2528 13.4133"
          stroke="#22AD80"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    link: "/sendmoney",
    title: "Send money",
    desc: "Send money to cliq users",
  },
  {
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 13.28C12.84 9.5333 19.16 9.5333 24 13.28"
          stroke="#FF5A00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1333 17.3998C13.6933 14.6532 18.32 14.6532 21.88 17.3998"
          stroke="#FF5A00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.0669 21.52C14.8402 20.1467 17.1602 20.1467 18.9336 21.52"
          stroke="#FF5A00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9998 29.3334H19.9998C26.6665 29.3334 29.3332 26.6667 29.3332 20.0001V12.0001C29.3332 5.33341 26.6665 2.66675 19.9998 2.66675H11.9998C5.33317 2.66675 2.6665 5.33341 2.6665 12.0001V20.0001C2.6665 26.6667 5.33317 29.3334 11.9998 29.3334Z"
          stroke="#FF5A00"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    link: "/airtimeandbills",
    title: "Buy Airtime & Data",
    desc: "MTN,GLO,AIRTEL,9MOBILE",
  },
  {
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6665 27.3334H9.33317C5.33317 27.3334 2.6665 25.3334 2.6665 20.6667V11.3334C2.6665 6.66675 5.33317 4.66675 9.33317 4.66675H22.6665C26.6665 4.66675 29.3332 6.66675 29.3332 11.3334V20.6667C29.3332 25.3334 26.6665 27.3334 22.6665 27.3334Z"
          stroke="#263B95"
          strokeWidth="2"
          strokemmiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
          stroke="#263B95"
          strokeWidth="2"
          strokemmiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3335 9.33325H21.3335"
          stroke="#263B95"
          strokeWidth="2"
          strokemmiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6665 22.6667H6.6665"
          stroke="#263B95"
          strokeWidth="2"
          strokemmiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    link: "/airtimeandbills",
    title: "Pay bills",
    desc: "JAMB,WAEC,Electricity, Cable TV, Internet",
  },
  {
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.3332 17.3334V12.0001C29.3332 5.33341 26.6665 2.66675 19.9998 2.66675H11.9998C5.33317 2.66675 2.6665 5.33341 2.6665 12.0001V20.0001C2.6665 26.6667 5.33317 29.3334 11.9998 29.3334H18.6665"
          stroke="#21ADE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3335 21.3333V29.3333L28.0002 26.6666"
          stroke="#21ADE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3332 29.3334L22.6665 26.6667"
          stroke="#21ADE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.2667 8.34668L11.8667 20.9733"
          stroke="#21ADE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.4801 8.34668L16.0801 20.9733"
          stroke="#21ADE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.70654 12.5601H21.3332"
          stroke="#21ADE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 16.7734H20.6267"
          stroke="#21ADE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    link: "/printairtime",
    title: "Print airtime vouchers",
    desc: "MTN,GLO,AIRTEL,9MOBILE",
  },
];
