import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import succes_icon from "../../../../assets/Suuccess_Icon.png";
import { impData } from "../../../../impData";
const WalletFundedSuccessModal = ({fundedModal, closeFundedModal}) => {
  return (
    <Modal isOpen={fundedModal} onClose={closeFundedModal} closeOnOverlayClick={true} closeOnEsc={true} w={548}>
      <ModalOverlay
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.1)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <ModalCloseButton
        border="1px thin #E9EAEC"
        m="1rem"
        w="50px"
        h="50px"
        borderRadius={50}
        background="#E9EAEC"
      />
      <ModalContent w={{ base: "80%", md: 548 }} m="auto">
        <ModalBody>
          <Flex
            display={impData.flex}
            flexDirection="column"
            textAlign="center"
            p="1rem"
            gap="1rem"
            alignItem="center"
          >
            <Image src={succes_icon} mx="auto" w={156} h={156} />
            <Text fontSize={24} fontWeight={600}>
              Wallet funded
            </Text>
            <Text>
              You have successfully funded your wallet, Go back to dashboard and
              continue.
            </Text>
            <Button
              fontSize={18}
              bg="#263B95"
              borderRadius={50}
              h="56px"
              color="#fff"
              fontWeight={600}
            >
              Continue
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WalletFundedSuccessModal;
