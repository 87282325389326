import {
  Box,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import downloadIcon from "../../../assets/Icons/download.png";
import { config } from "../../../components/config";
import cookie from "react-cookies";
import PrintTransModal from "../Modal/EnterTransPinModal/PrintTransModal";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
const AvailableAirtimeTable = () => {
  const [vouchers, setVouchers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const [narration, setNarrration] = useState("");
  useEffect(() => {
    axios
      .get(`${config.api_url}/api/v3/w3/unicard-voucher-history`, {
        headers: {
          authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then((res) => {
        console.log(res.data.data);
        const resData = res.data.data !== null ? res.data.data.reverse() : [];
        setVouchers(resData);
      })
      .catch((e) => console.log(e));
  }, []);

  let closeTranspinModal = () => {
    setOpenModal(false);
  };

  const getPin = (id) => {
    axios
      .get(`${config.api_url}/api/v3/w3/unicard-history?keyId=${id}`, {
        headers: {
          authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then((res) => {
        console.log(res.data.data);
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width;
        const margin = 6;
        const itemWidth = (pageWidth - 4 * margin) / 3; // Calculate the width of each item to fit 2 per row
        const itemPadding = 3; // Padding inside each item
  
        let xPosition = margin;
        let yPosition = margin;
  
        doc.setFontSize(10);
  
        res.data.data.forEach((item, index) => {
          // Calculate the height of the item based on the content
          const lines = [
            `Business name: ${item.businessName}`,
            `Price: ${item.price}`,
            `Pin: ${item.pin}`,
            `Serial No: ${item.serialNo}`,
            `Date Created: ${(item.dateCreated)?.split("T")[0]}`,
            `Powered by: ${item.poweredBy}`,
            `To Use: ${item.toUse}`,
          ];
          const itemHeight = (lines.length * 7) + 2 * itemPadding; // Adjust height based on the number of lines
  
          // Add new page if height exceeds page limit
          if (yPosition + itemHeight > doc.internal.pageSize.height - margin) {
            doc.addPage();
            yPosition = margin;
          }
  
          // Draw border for each item
          doc.setLineWidth(0.5);
          doc.rect(xPosition, yPosition, itemWidth, itemHeight);
  
          // Add text inside the item
          let textYPosition = yPosition + itemPadding + 5; // Start text a bit down from the top
          lines.forEach(line => {
            doc.text(line, xPosition + itemPadding, textYPosition, { maxWidth: itemWidth - 2 * itemPadding });
            textYPosition += 7; // Adjust text position
          });
  
          xPosition += itemWidth + margin; // Move to the next item position
  
          // Reset xPosition and move yPosition down if we've filled a row
          if ((index + 1) % 3 === 0){
            xPosition = margin;
            yPosition += itemHeight + margin;
          }
        });
  
        doc.save('response_data.pdf');
      })
      .catch((e) => console.log(e));
  }
  

  return (
    <Box fontFamily="clash">
      <TableContainer>
        <Table
          variant="simple"
          overflowX={{ base: "scroll", md: "scroll", lg: "none" }}
        >
          <Thead borderBottom="2px solid #212337">
            <Tr>
              <Th fontSize={16} fontWeight={600} fontStyle="normal">
                Batch Id
              </Th>
              <Th fontSize={16} fontWeight={600} fontStyle="normal">
                Date
              </Th>
              <Th fontSize={16} fontWeight={600} fontStyle="normal">
                Amount
              </Th>
              <Th fontSize={16} fontWeight={600} fontStyle="normal">
                Status
              </Th>
              {/* <Th fontSize={16} fontWeight={600} fontStyle="normal">
                Network
              </Th>
              <Th fontSize={16} fontWeight={600} fontStyle="normal">
                Date
              </Th> */}
              <Th fontSize={16} fontWeight={600} fontStyle="normal">
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {vouchers.map((item, idx) => (
              <>
                <Tr key={idx}>
                  <Td>{item.keyId}</Td>
                  <Td>{item["purchased Date"]?.split("T")[0]}</Td>
                  <Td>{item.amount}</Td>
                  <Td>{item.isUsed ? 'Used':"Unused"}</Td>
                  {/* <Td>{item.narration?.split("/")[1]}</Td>
                  <Td>{item.createdDate?.split("T")[0]}</Td> */}
                  <Td className=" flex gap-2 cursor-pointer" onClick={() =>getPin(item.keyId)}>
                  Download <img src={downloadIcon} alt="" />
                    {/* <a
                      className="flex text-[#22AD80] items-center gap-[20px]"
                      href="#"
                      onClick={() => {
                        setNarrration(item.narration);
                        setRequestId(item.batchId);
                        setOpenModal(true);
                      }}
                    >
                      Download <img src={downloadIcon} alt="" />
                    </a> */}
                  </Td>
                </Tr>
              </>
            ))}
          </Tbody>
        </Table>
        <PrintTransModal
          requestId={requestId}
          openTransPinModal={openModal}
          closeTransPinModal={closeTranspinModal}
          narration={narration}
        />
      </TableContainer>
    </Box>
  );
};

const tablesData = [
  {
    batchId: 20637,
    description: "Eddy Airtel 100 4pcs 23/08/2022",
    units: 40,
    denomination: "NGN 100",
    network: "Network",
    filePin: "*****",
  },
];
const duplicatedArrs = [];
export default AvailableAirtimeTable;
