import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { impData } from "../../impData";
import ReferralLink from "./ReferralLink";

const ReferralIntro = () => {
  return (
    <Box
      display={impData.flex}
      flexDir={{ base: "column", md: "column", lg: "row" }}
      justifyContent={{ base: "", md: "", lg: "" }}
      borderTop="1.5px solid #E9EAEC"
      borderBottom="1.5px solid #E9EAEC"
      py="1rem"
      m="1.5rem 1rem"
      alignItems="center"
      gap="2rem"
    >
      <ReferralLink />
      <Box
        display={impData.flex}
        flexWrap="wrap"
        w={{ base: "100%", md: "100%", lg: "47%" }}
        justifyContent="center"
        py="10px"
        alignItems="center"
        gap="10px"
      >
        {referralThings.map((item, idx) => (
          <Box
            w="47%"
            borderRadius={7}
            h="95px"
            display={impData.flex}
            flexDir="column"
            gap="10px"
            alignItems="center"
            border="1.5px solid #E9EAEC"
            p="1rem"
            key={idx}
          >
            <Text fontSize={16} fontWeight={500}>
              {item.title}
            </Text>
            <Text fontSize={18} fontFamily="clash" fontWeight={600}>
              {item.amount}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const referralThings = [
  {
    title: "Total referrals",
    amount: 0,
  },
  {
    title: "Referral Level",
    amount: "Level 1",
  },
  {
    title: "Amount Earned",
    amount: "NGN0",
  },
  { title: "Next Payout Date", amount: "N/A" },
];
export default ReferralIntro;
