import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Text,
  HStack,
  ModalCloseButton,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import React from "react";
import DashModal from "../../../components/DashModal";
import { impData } from "../../../impData";

const EnterTransactionPinModal = ({
  openTransPinModal,
  setOpenTransPinModal,
  closeTransPinModal,
}) => {
  return (
    <DashModal
      openModal={openTransPinModal}
      onClose={closeTransPinModal}
      h="365px"
      width={{ base: "80%", md: "80%", lg: "607px" }}
    >
      <Box
        display={impData.flex}
        fontFamily="clash"
        flexDirection="column"
        alignItems="center"
        gap="1rem"
        py="1.5rem"
        px="1rem"
        w="full"
        h="full"
      >
        <button
          className="flex items-center justify-center ml-auto w-[44px] h-[44px] border-0 rounded-full bg-[#2123371A]"
          as={ModalCloseButton}
          onClick={closeTransPinModal}
        >
          <CloseIcon />
        </button>
        <Text fontFamily="clash">Enter Transaction pin</Text>
        <HStack>
          <PinInput
            focusBorderColor="#212337"
            type="number"
            manageFocus={true}
            placeholder=""
          >
            <PinInputField
              background="#E9EAEC6B"
              h="71px"
              w="71px"
              _focus={{ color: "#212337", border: "2px solid #263B95" }}
              fontWeight={700}
              fontSize={32}
              borderRadius={16}
            />
            <PinInputField
              background="#E9EAEC6B"
              h="71px"
              w="71px"
              _focus={{ color: "#212337", border: "2px solid #263B95" }}
              fontWeight={700}
              fontSize={32}
              borderRadius={16}
            />
            <PinInputField
              background="#E9EAEC6B"
              h="71px"
              _focus={{ color: "#212337", border: "2px solid #263B95" }}
              fontWeight={700}
              fontSize={32}
              w="71px"
              borderRadius={16}
            />
            <PinInputField
              background="#E9EAEC6B"
              h="71px"
              w="71px"
              _focus={{ color: "#212337", border: "2px solid #263B95" }}
              fontWeight={700}
              fontSize={32}
              borderRadius={16}
            />
          </PinInput>
        </HStack>
        <button className="flex items-center justify-center h-[56px] rounded-[50px] bg-[#263B95] text-[#fff] text-[18px] font-semibold lg:w-[50%] md:w-60% sm:w-[80%]">
          continue
        </button>
      </Box>
    </DashModal>
  );
};

export default EnterTransactionPinModal;
