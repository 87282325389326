import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import { impData } from "../../impData";
import EditProfileTab from "./EditProfile/EditProfileTab";
import ChangePasswordTab from "./ChnagePassword/ChangePasswordTab";
import ChangeTransactionPin from "./ChangeTransactionPin/ChangeTransactionPin";
import { useNavigate } from "react-router-dom";
import cookie from "react-cookies"
const SettingsPage = () => {
  const [active, setActive] = useState("Edit profile");
  const [tabIndex, setTabIndex] = useState(0);
  // const activeStyle = {
  //   display: "block",
  //   borderBottom: "2px solid #263B95",
  //   top: "100%",
  //   width: "50px",
  //   content: '" "',
  //   position: "absolute",
  // };
  const navigate = useNavigate();
  React.useEffect(() => {
    if (
      !cookie.load("TOPUPCLIQ_USER_TOKEN") ||
      cookie.load("TOPUCLIQ_USER_TOKEN") === ""
    ) {
      navigate("/login");
    }
  }, [])

  return (
    <DashboardWrapper header="Settings">
      <Box
        w="full"
        h="full"
        // m={{ base: "0", md: "1rem" }}
        mt="10px"
        p={{ base: "2rem 10px", md: "2rem 1rem" }}
        bg="#fAfAfA"
        display={impData.flex}
        flexDir="column"
        gap="1.5rem"
      >
        <h1
          fontFamily="clash"
          className="text-[20px] font-[800] flex gap-[1rem] "
        >
          Settings
          <span className="flex item-end text-[#263B95] font-extrabold">
            /{active}
          </span>
        </h1>
        <Box w="full">
          <Tabs defaultIndex={0} index={tabIndex} maxW="full" width="full">
            <TabList overflowX={{ base: "scroll", md: "unset" }}>
              <Tab
                as="button"
                onClick={() => {
                  setTabIndex(0);
                  console.log("working");
                }}
                whiteSpace="nowrap"
              >
                Edit profile
              </Tab>
              {/* <Tab
                as="button"
                onClick={() => setTabIndex(1)}
                whiteSpace="nowrap"
              >
                Cards
              </Tab>
              <Tab
                as="button"
                onClick={() => setTabIndex(2)}
                whiteSpace="nowrap"
              >
                Banks
              </Tab> */}
              <Tab
                as="button"
                onClick={() => setTabIndex(1)}
                whiteSpace="nowrap"
              >
                change password
              </Tab>
              <Tab
                as="button"
                onClick={() => setTabIndex(2)}
                whiteSpace="nowrap"
              >
                Change/reset transaction pin
              </Tab>
            </TabList>
            <TabPanels width={"full"} >
              <TabPanel>
                <EditProfileTab />
              </TabPanel>
              {/* <TabPanel>
                <CardsTab />
              </TabPanel>
              <TabPanel>
                <BanksTab />
              </TabPanel> */}
              <TabPanel>
                <ChangePasswordTab />
              </TabPanel>
              <TabPanel>
                <ChangeTransactionPin />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </DashboardWrapper>
  );
};

export default SettingsPage;
