import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  createStandaloneToast,
  PinInputField,
  PinInput,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { impData } from "../../../impData";
import axios from "axios";
import { config } from "../../../components/config"; 
import { ToastContainer, toast } from "react-toastify";
import { PulseLoader } from "react-spinners";
import cookie from "react-cookies";
import "react-toastify/dist/ReactToastify.css";
import { useRecoilState, useRecoilValue } from "recoil";
import { chooseNetworkAtom, purchaseVoucherAtom } from "../../../atoms/store";
import { useNavigate } from "react-router-dom";
const PrintAirtimeFormModal = ({
  openModal,
  closeModal,
  setOpenModal,
  setOpenTransPinModal,
}) => {
  const networkName = useRecoilValue(chooseNetworkAtom);
  const [purchaseVoucherPayload, setPurchaseVoucherPayload] =
    useRecoilState(purchaseVoucherAtom);
  const [unitAmount, setUnitAmount] = useState(0);
  const [units, setUnits] = useState(0);
  // const [totalAmount, setTotalAmount] = useState(0);
  const [des, setDes] = useState('');
  const [pin, setPin] = useState("");
  // const { toast, ToastContainer } = createStandaloneToast();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
 
  const onSubmit = () => {
    setLoading(true);
    const params = {
     "description": des,
      "unit": units,
      "value": unitAmount,
      "transactionPin": pin
    };
    console.log(params)
    axios
      .post(config.api_url + '/api/v3/w3/unicard-mobile', params, {
        headers: {
          Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then(res => {
        console.log(res.data);
        if (res.data.status == '2000') {
          toast.success(res.data.description, {
            position: toast.POSITION.TOP_CENTER
          })
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        } else {
          console.log(res.data)
          toast.error(res.data.description)
          setTimeout(() => {
            toast.error(res.data.description)
          });
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("Error while buying Airtime", {
          position: toast.POSITION.TOP_CENTER
        });
      })
      .finally(res => {
        setLoading(false);
      });
  }

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      closeOnEsc={true}
      closeOnOverlayClick={true}
    >
      <ModalOverlay
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.3)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <Box
        as={ModalContent}
        m="auto"
        w={{ base: "90%", md: "40%", lg: "30%" }}
        fontFamily="clash"
        p="1rem"
      >
      <ToastContainer/>
        <ModalHeader
          fontSize={24}
          w="full"
          display={impData.flex}
          justifyContent="space-between"
          alignItems="center"
          fontFamily="clash"
        >
          <Text fontSize={24}>Buy airtime vouchers</Text>
          {/* <ToastContainer /> */}
          <ModalCloseButton
            w="44px"
            borderRadius="50%"
            h="44px"
            m="1rem"
            as="button"
            bg="#2123371A"
          />
        </ModalHeader>
        <Box
          flexDirection="column"
          as={ModalBody}
          fontFamily="clash"
          h="70%"
          display={impData.flex}
          p="1rem"
          gap="1.5rem"
        >
       

          <FormControl mt="1rem">
            <div>Unit</div>
            <input
              placeholder="unit"
              style={{ borderBottom: "2px solid #263B95" }}
              onChange={(e) =>setUnits(e.target.value)}
              value={units}
              className=" inputs block placeholder:text-[#21233740] placeholder:font-lg focus:border-b-[#263B95] w-full p-2 border-none border-b-2 border-solid border-[#263B95]"
            />
          </FormControl>
          <FormControl mt="1rem">
            <div>Price</div>
            <input
              placeholder="Amount"
              style={{ borderBottom: "2px solid #263B95" }}
              onChange={(e) =>setUnitAmount(e.target.value)}
              value={unitAmount}
              className=" inputs block placeholder:text-[#21233740] placeholder:font-lg focus:border-b-[#263B95] w-full p-2 border-none border-b-2 border-solid border-[#263B95]"
            />
          </FormControl>
          <FormControl mt="1rem">
            <div>Description</div>
            <input
              placeholder="Enter description"
              style={{ borderBottom: "2px solid #263B95" }}
              onChange={(e) =>setDes(e.target.value)}
              value={des}
              className=" inputs block placeholder:text-[#21233740] placeholder:font-lg focus:border-b-[#263B95] w-full p-2 border-none border-b-2 border-solid border-[#263B95]"
            />
          </FormControl>
          <FormLabel fontSize={12} color="#263B95"
            className="mt-3"
          >Transaction Pin</FormLabel>
          <HStack>
            <PinInput
              focusBorderColor="#212337"
              type="number"
              manageFocus={true}
              placeholder=""
              value={pin}
              onChange={(value) => setPin(value)}
            >
              <PinInputField
                background="#E9EAEC6B"
                h="50px"
                w="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                h="50px"
                w="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                mr="10px"
                h="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                w="50px"
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                h="50px"
                w="50px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
            </PinInput>
          </HStack>
          <Button
            h="56px"
            borderRadius="50px"
            mt="1.5rem"
            fontWeight={700}
            fontSize="18px"
            bg="#263B95"
            color="#fff"
            w="90%"
            mx="auto"
            display="block"
            onClick={onSubmit}
          >
            {
            loading ? <PulseLoader color="#fff" loading={loading} /> : "Buy"
          }
          </Button>
          {/* </form> */}
        </Box>
      </Box>
    </Modal>
  );
};

const denominations = ["100", "200", "500", "1000"];
export default PrintAirtimeFormModal;
