import { atom, useRecoilValue } from "recoil";
const requestIdState = atom({
  key: "requestId",
  default: "",
});

const loginEmailState = atom({
  key: "emailAddress",
  default: "",
});

const userDetailsAtom = atom({
  key: "user",
  default: {},
});
const tokenState = atom({ key: "authToken", default: "" });

const fundWalletDataAtom = atom({
  key: "fundWallet",
  default: {},
});

const sharelinkAtom = atom({
  key: "sharelink",
  default: false,
});

const changePwdAtom = atom({
  key: "changePwdAtom",
  default: {},
});
const chooseNetworkAtom = atom({ key: "change netwrok", default: "" });
const purchaseVoucherAtom = atom({
  key: "purchase vouchers",
  default: {},
});
const landingpageSideBarAtom = atom({
  key: "landing page sidebar Atom",
  default: false,
});
export {
  requestIdState,
  loginEmailState,
  userDetailsAtom,
  tokenState,
  fundWalletDataAtom,
  changePwdAtom,
  chooseNetworkAtom,
  purchaseVoucherAtom,
  landingpageSideBarAtom
};
