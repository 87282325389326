import { Box, Text, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { impData } from "../../../impData";
import electricityBills from "../../../assets/electricity.png";
import internetServices from "../../../assets/internet services.png";
import cableTv from "../../../assets/cable tv.png";
import ElectricityBillsModal from "../Modals/ElectricityBills/ElectricityBillsModal";
import CableTvSubscribeModal from "../Modals/CableTvSubscribeModal/CableTvSubscribeModal";
import SchoolBills from "../Modals/InternetServices/InternetServicesModal";
const PayBills = () => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [open1, setOpen1] = useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => setOpen1(false)
  const [open2, setOpen2] = useState(false)
  const handleOpen2 = () => setOpen2(true)
  const handleClose2 = () => setOpen2(false)

  const serviceData = [
    {
      title: "Pay electricity bills",
      icon: electricityBills,
      desc: "EKEDC, IKEDC, IBEDC",
      open:open,
      handleOpen:handleOpen
    },
   
    {
      title: "Cable TV subscription",
      icon: cableTv,
      desc: "DSTV, GOTV.....",
      open:open2,
      handleOpen:handleOpen2
    },
     {
      title: "School and Exams",
      icon: internetServices,
      desc: "JAMB, WAEC",
      open:open1,
      handleOpen:handleOpen1
    },
  ];
  return (
    <>
     <ElectricityBillsModal
     openModal={open}
     closeModal={handleClose}
      />
      <SchoolBills
        openModal={open1}
        closeModal={handleClose1}
       />
       <CableTvSubscribeModal 
        openModal={open2}
        closeModal={handleClose2}
       />
    <Box display={impData.flex} flexDirection="column" gap="1rem">
      {serviceData.map((item, idx) => (
        <Box
          w="full"
          p="2rem  1rem"
         onClick={item.handleOpen}
          display={impData.flex}
          flexDirection="column"
          gap="1rem"
          border="1.5px solid #E9EAEC"
          borderRadius={10}
          key={idx}
        >
          <Image src={item.icon} w="44px" h="44px" />
          <Text fontSize="20px" fontWeight={600}>{item.title}</Text>
          <Text fontSize={16} fontWeight={500}>{item.desc}</Text>
        </Box>
      ))}
    </Box>
    </>
  );
};



export default PayBills;
