import {
  Box,
  Text,
  Container,
  Tab,
  Tabs,
  Link,
  Image,
  TabList,
  TabPanels,
  TabPanel,
  useTab,
  useMultiStyleConfig,
  FormControl,
  HStack,
  FormLabel,
  Input,
  Flex,
  FormHelperText,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaDotCircle } from "react-icons/fa";
import Personal_Info from "./Tab_components/Personal_Info";
import Reg_Info from "./Tab_components/Reg_Pay_Info";
import leftBg from "../../assets/authLeftBg.png";
import Account_info from "./Tab_components/Account_info";
import logo from "../../assets/Icons/Logo.png";
import AuthSuccessModal from "./Tab_components/Reg_pay_info_Partials/AuthSuccessModal";
import axios from "axios";
import {
  useToast,
  createStandaloneToast,
  UseToastOptions,
} from "@chakra-ui/react";
import { impData } from "../../impData";
import { config } from "../../components/config";
import ErrorMessage from "../../components/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { requestIdState } from "../../atoms/store";
import AuthPagesWrapper from "../../components/Templates/AuthPagesWrapper";
import VerifyEmail from "./Tab_components/verifyEmail";
const RegisterPage = () => {
  const { toast, ToastContainer } = createStandaloneToast();
  const [paging, setPaging] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [bvn, setBvn] = useState("");
  const [cvv, setCvv] = useState("");
  const [password, setPassword] = useState("");
  const [cardPin, setCardPin] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralId, setReferralId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [transactionPin, setTransactionPin] = useState("");
  const [requestId, setRequestId] = useRecoilState(requestIdState);
  const [token, setJWT] = useState("");
  const [uuid, setUuid] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // fullName use Effect
  useEffect(() => {
    setFullName(
      firstName.charAt(0).toString() +
      firstName.slice(1) +
      " " +
      lastName.charAt(0).toString() +
      lastName.slice(1)
    );
  }, [fullName, lastName, fullName]);

  useEffect(() => { }, [
    lastName,
    firstName,
    emailAddress,
    password,
    phoneNumber,
    selectedState,
    fullName,
    dateOfBirth,
    transactionPin,
  ]);

  const onSubmitPassword = () => {
    //if username and password is not provided
    setIsLoading(true);
    const params = {
      password: this.state.password,
    };
    axios
      .post(
        config.api_url + '/api/v3/w3/topupcliq-password',
        params,
        {
          headers: {
            Authorization: token,
             businessName:"topupbox"
          },
        }
      )
      .then(res => {
        let iflog = res.data.status == 2000 ? true : false;
        if (iflog) {
          onSubmitPin()
        } else {
          console.log(params);
          toast({
            description: res.data.description,
            status: "error",
            position: "top",
            duration: 6000,
            isClosable: false,
          });
        }
      })
      .catch(err => {
        console.log(err);
        toast({
          description: "An error occured while setting password",
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: false,
        });
      })
      .finally(res => {
        setIsLoading(false);
      });
  };


  const onSubmitPin = () => {
    //if username and password is not provided
    setIsLoading(true);
    const params = {
      transactionPin: transactionPin,
    };
    axios
      .post(
        config.api_url + '/api/v3/w3/topupcliq-transaction-pin',
        params,
        {
          headers: {
            Authorization: token,
             businessName:"topupbox"
          },
        }
      )
      .then(res => {
        let iflog = res.data.status == 2000 ? true : false;
        if (iflog) {
          toast({
            description: res.data.description,
            status: "success",
            position: "top",
            duration: 6000,
            isClosable: false,
          });
          navigate("/login");
        } else {
          console.log(params);
          toast({
            description: res.data.description,
            status: "error",
            position: "top",
            duration: 6000,
            isClosable: false,
          });
        }
      })
      .catch(err => {
        console.log(err);
        toast({
          description: "An error occured setting transaction pin",
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: false,
        });
      })
      .finally(res => {
        setIsLoading(false);
      });
  };


  const handleSignUp = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      dateOfBirth === "" ||
      phoneNumber === "" ||
      emailAddress === "" ||
      password === ""
    ) {
      toast({
        description: "all fields must be filled",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: false,
      });
    }
    if (password !== confirmPassword) {
      toast({
        description: "passwords must match",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: false,
      });
    }
    if (phoneNumber.length !== 11 || phoneNumber.length < 11) {
      toast({
        description: "invalid phone number",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: false,
      });
    }

    let data = {
      "emailAddress": emailAddress,
      "phoneNumber": phoneNumber,
      "bvn": bvn,
      "password": password,
      "referalId": referralId,
      // firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
      // lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
      // dateOfBirth: dateOfBirth,
      // phoneNumber: phoneNumber,
      // referralId: referralId,
      // emailAddress: emailAddress,
      // password: password,
      // uuid: uuid,
      // contactAddress: "",
      // businessName: "TopUpbox",
      // localGovArea: "None",
      // state: selectedState,
    };

    axios
      .post(`${config.api_url}/api/v3/w3/signup-reseller`, data, {
        headers: {
          Authorization: token,
           businessName:"topupbox"
        },
      })
      .then((res) => {
        const resData = res.data;
        if (resData.status == "2000") {
          console.log(resData.data);

          toast({
            description: resData.description,
            status: "success",
            position: "top",
            duration: 9000,
            isClosable: false,
          });
          onSubmitPassword();
        } else {
          console.log(resData.description);
          setErrorMessage(resData.description);
          toast({
            description: errorMessage,
            status: "error",
            position: "top",
            duration: 9000,
            isClosable: false,
          });
        }
      })
      .catch((e) => {
        toast({
          description: e,
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: false,
        });
        console.log(e);
      });
  };


  const verifyEmailonSignUp = () => {

  }

  return (
    <>
      <AuthPagesWrapper>
        <Box
          w={{ base: "100%", md: "55%", lg: "55%" }}
          h="full"
          bg="#fcfcfc"
          display={impData.flex}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt="2rem"
        >
          <Image src={logo} mt="2rem" />

          {/* tabs */}

          <Box mt="1rem" w="full" h="full">
            {/* sign up form tab */}
            <Tabs
              align="center"
              defaultIndex={0}
              mx="auto"
              index={paging}
              w={{ base: "90%", md: "90%", lg: "100%" }}
              variant="unstyled"
              mt="2rem"
            >
              <TabList
                overflowX={{ base: "scroll", md: "hidden" }}
                bg="#E9EAEC40"
                py="10px"
                gap={{ base: "10px", md: "1rem" }}
                w={{ base: "full", md: "90%", lg: "90%" }}
              >
                {/* <CustomTabsButtons
                  fontSize="16px"
                  _selected={{ color: "#263B95", fontWeight: 500 }}
                >
                  Onboarding information
                </CustomTabsButtons> */}
                <CustomTabsButtons
                  fontSize="16px"
                  _selected={{ color: "#263B95", fontWeight: 500 }}
                >
                  Account information
                </CustomTabsButtons>
                <CustomTabsButtons
                  fontSize="16px"
                  _selected={{ color: "#263B95", fontWeight: 500 }}
                >
                  Verification
                </CustomTabsButtons>
               
              </TabList>
              <TabPanels w={{ base: "100%", md: "90%", lg: "60%" }}>
                {/* <TabPanel>
                  <Personal_Info
                    toastMessage={ToastContainer}
                    setPaging={setPaging}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setDateOfBirth={setDateOfBirth}
                    setPhoneNumber={setPhoneNumber}
                  />
                </TabPanel> */}

                {/* second panel */}
                <TabPanel>
                  <Account_info
                    toastMessage={ToastContainer}
                    setPaging={setPaging}
                    setEmail={setEmail}
                    email={emailAddress}
                    bvn={bvn}
                    password={password}
                    setBvn={setBvn}
                    setPhoneNumber={setPhoneNumber}
                    setPassword={setPassword}
                    setConfirmPassword={setConfirmPassword}
                    setReferralId={setReferralId}
                    phoneNumber={phoneNumber}
                    referralId={referralId}
                    setRequestId={setRequestId}
                    setUuid={setUuid}
                  />
                </TabPanel>
                <TabPanel>
                  <VerifyEmail
                    setPaging={setPaging}
                    toastMessage={ToastContainer}
                    requestId={requestId}
                    email={emailAddress}
                    phoneNumber={phoneNumber}
                    setJWT={setJWT}
                    uuid={uuid}
                  />
                </TabPanel>
                
              </TabPanels>
            </Tabs>
          </Box>
          <AuthSuccessModal />
        </Box>
      </AuthPagesWrapper>
    </>
  );
};

// custom tab buttons
const CustomTabsButtons = React.forwardRef((props, ref) => {
  const tabsProps = useTab({ ...props, ref });
  const isSelectedTab = !!tabsProps["aria-selected"];

  const styles = useMultiStyleConfig("Tab", tabsProps);
  return (
    <Button __css={styles.tab} {...tabsProps}>
      <Box w={impData.fitContent} as="span" mr="15px" fontSize="20px">
        {isSelectedTab ? <span>&#x2022;</span> : ""}
      </Box>
      {tabsProps.children}
    </Button>
  );
});

export default RegisterPage;
