import {
 
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import successIcon from "../../../../assets/Suuccess_Icon.png";
import { impData } from "../../../../impData";
import {useNavigate} from 'react-router-dom';
const AuthSuccessModal = ({ openModal }) => {
  const navigate = useNavigate()
  return (
    <Modal isOpen={openModal} id="auth-modal">
      <ModalOverlay
        border="none"
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.1)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <ModalContent m="auto" maxWidth={{ base: "90%", md: 530 }} h={439}>
        <ModalBody
          display={impData.flex}
          flexDirection={"column"}
          fontFamily="clash"
          alignItems="center"
          gap="1rem"
          p=" 2rem 1rem"
        >
          <Image w="156px" h="156px" src={successIcon} alt="#" />
          <Text fontsize={24} fontWeight="500" textAlignCenter>
            Account created
          </Text>
          <Text fontSize={16} color="#21233780">
            You have successfully registered on TopupCliq connect
          </Text>
          <Button
            style={{
              background: "#263B95",
              color: "#fff",
              fontSize: 18,
              fontWeight: 700,
              width: "100%",
              height: 68,
              borderRadius: 50,
              marginTop: " 1.5rem",
            }}
            onClick={()=>navigate("/dashboard")}
          >
            Go to dashboard
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AuthSuccessModal;
