import { Box, Text } from "@chakra-ui/react";
import React from "react";
import bgImg from "../../../assets/faq.png";
const Intro = () => {
  return (
    <Box w="full" px={{ base: "10px", md: "20" }} py="10">
      <Text
        fontSize={{ base: "20px", md: "40px" }}
        mx={{ base: "", md: "inherit" }}
        fontWeight="bold"
      >
        FREQUENTLY ASKED QUESTIONS
      </Text>
      <Box

        p={{ base: "2rem 5%", md: "20" }}
        mt="1rem"
        style={{
          color: "#fff",
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: 350,
          displlay: "block",
        }}
      >
        <Text fontSize="18px">FAQS</Text>
        <Text mt="" style={{ fontWeight: "800" }} fontSize={{ base: "30px", md: "56px" }}>
          Top up connect Answers
        </Text>
        <Text style={{ fontWeight: "400", fontSize: "18px" }} w={{ base: "full", md: "428.98px" }}>
          TopupBox connect has changed the lives of business owners, but people
          still wonder, “What is TopBox connect?” We’re here to tell you this
          and more.
        </Text>
      </Box>
    </Box>
  );
};

export default Intro;
