import { Box, Text } from "@chakra-ui/react";
import React from "react";
import Avatar from "../../assets/man.png";
import NewsletterForm from "../../components/NewsletterForm";
import { impData } from "../../impData";

const Testimonialsection = () => {
  return (
    <Box
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgba(33, 173, 227, 0.05) 70%, #fff )",
      }}
      w="full"
      py="10"
    >
      <h1
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          fontWeight: "500",
        }}
      >
        Testimonials
      </h1>
      <h1
        style={{
          marginTop: "1rem",
          textAlign: "center",
          fontWeight: "800",
          fontSize: "30px",
        }}
      >
        Feedback from users
      </h1>
      <Box w="full" my="10">
        <Box w="full">
          <Box
            flexWrap="nowrap"
            width="100%"
            style={{
              display: impData.flex,
              flexDirection: "row",
              gap: "24px",
              justifyContent: "center",
              position: "relative",
              overflowX: "auto",
            }}
            pl={{ base: "2rem", md: "" }}
            flexShrink="none"
          >
            {reviews.map((item, idx) => (
              <Box
                key={idx}
                style={{
                  display: impData.flex,
                  flexDirection: "column",
                  alignItems: "space-between",
                  gap: 32,
                  background: "#fff",
                  borderRadius: "10px",
                  padding: "20px",
                  minWidth: "312px",
                  maxWidth: "312px",
                  height: "194px",

                  boxShadow: "0px 10px 20px rgba(33, 35, 55, 0.02)",
                }}
              >
                <Text lineHeight="5" color="#212337" fontSize="16px">
                  <span style={{ fontSize: "24px", color: "#253d95" }}>"</span>
                  {item.comment}
                  <span style={{ fontSize: "24px", color: "#253d95" }}>"</span>
                </Text>
                <Box
                  className="reviewer"
                  style={{
                    display: "flex" || "-ms-flexbox" || "-webkit-flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img
                    src={item.image}
                    alt=""
                    style={{ width: 50, height: 50 }}
                  />
                  <Box w="full" d="block" textAlign="left">
                    <Text fontSize="18px">{item.customerName}</Text>
                    <Text fontSize="16px" fontWeight="normal">
                      {item.role}
                    </Text>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const reviews = [
  {
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque in dolorem blanditiis delectus facere ducimus animi officia rem ",
    image: Avatar,
    customerName: "Jon doe",
    role: "Product Designer",
  },
  {
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque in dolorem blanditiis delectus facere ducimus animi officia rem ",
    image: Avatar,
    customerName: "Jon doe",
    role: "Product Designer",
  },
  {
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque in dolorem blanditiis delectus facere ducimus animi officia rem ",
    image: Avatar,
    customerName: "Jon doe",
    role: "Product Designer",
  },
  {
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque in dolorem blanditiis delectus facere ducimus animi officia rem ",
    image: Avatar,
    customerName: "Jon doe",
    role: "Product Designer",
  },
];
export default Testimonialsection;
