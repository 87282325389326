import {
  Box,
  Button,
  Text,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
 
} from "@chakra-ui/react";
import React from "react";
import { impData } from "../../../../impData";

const EnterOtpModal = ({showOtpModal, setShowOtpModal, closeOtpModal}) => {
  return (
    <Modal w={{ base: "80%", md: "607px" }} isOpen={showOtpModal} onClose={closeOtpModal}>
      <ModalOverlay
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.1)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <ModalContent margin={"auto"}>
        <ModalCloseButton
          as="button"
          border="1px thin #E9EAEC"
          m="1rem"
          w="50px"
          h="50px"
          borderRadius={50}
          background="#E9EAEC"
        />
        <Box
          as={ModalBody}
          w={{ base: "80%", md: 607 }}
          m="2.5rem 1rem"
          display={impData.flex}
          fontFamily="clash"
          flexDirection="column"
          alignItems="center"
          gap="1rem"
        >
          <Text fontFamily="clash">Enter OTP</Text>
          <Text color="#21233780">
            Enter thr 4-digit PIN to validate your card.
          </Text>
          <HStack>
            <PinInput
              focusBorderColor="#212337"
              type="number"
              manageFocus={true}
              placeholder=""
            >
              <PinInputField
                background="#E9EAEC6B"
                h="71px"
                w="71px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                h="71px"
                w="71px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                h="71px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                w="71px"
                borderRadius={16}
              />
              <PinInputField
                background="#E9EAEC6B"
                h="71px"
                w="71px"
                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                fontWeight={700}
                fontSize={32}
                borderRadius={16}
              />
            </PinInput>
          </HStack>
          <Button
            w="full"
            fontSize={18}
            bg="#263B95"
            borderRadius={50}
            h="56px"
            color="#fff"
            fontWeight={600}
          >
            Continue
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};
export default EnterOtpModal;
