import { Box, Text, Flex, Image, cookieStorageManager } from "@chakra-ui/react";
import emptyTransaction from "../../../assets/emtyTransaction.png";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../../components/config";
import cookie from "react-cookies";
const RecentTransaction = ({ data, emtptyBg }) => {
  const [currentTransactions, setCurrentTransactions] = useState([]);
  useEffect(() => {
    axios
      .get(`${config.api_url}/api/v3/w3/topupcliq-transaction-history`, {
        headers: {
          authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then((res) => {
        const transactionData = res.data;
        setCurrentTransactions([...transactionData.data]);
      })
      .catch((e) => console.log(e));
  }, [currentTransactions]);
  return (
    <Box
      w="full"
      h="full"
      bg="#fff"
      textAlign="center"
    >
      {currentTransactions === null ? (
        <>
          <Image mx="auto" mt="10" src={emptyTransaction} alt="" />
          <Text as="h2" fontSize={20} fontWeight="600">
            You have no transactions
          </Text>
          <Text>
            You haven't made any transactions this month. when you do, they will
            appear here.
          </Text>
        </>
      ) : (
        currentTransactions.splice(0, 9).map((item, idx) => (
          <div className="flex space-x-3  mx-auto w-[100%] justify-evenly border py-2 px-2 my-1 rounded-lg text-sm">
        
          <div className=" w-2">{idx + 1}</div>
            <div  className="font-medium w-[40%]">{item.narration?.split("/")[0]}</div>
            <div className=" w-[20%]">NGN {item?.amount}</div>
            <div className=" w-[25%]">{item.status === "Successful" ? <span className=" text-[#22AD80]"> {item?.status} </span>:item.status === undefined ? <span className=" text-[#22AD80]">Successful</span> : <span className=" text-[#FF4040]">{item.status}</span>}</div>
          </div>
           
         
        ))
      )}

    </Box>
  );
};

export default RecentTransaction;
