let origin = window.origin;
console.log(origin);
var WEB_BASE_URL = "";
if (origin === "http://localhost:3000") {
  WEB_BASE_URL = origin + "";
} else {
  WEB_BASE_URL = origin + "/api";
}
export const config = {
  api_url: WEB_BASE_URL,
  callback_url: "https://sandbox.api.topupcliq.com/services",
  //"https://sandbox.dash.topupcliq.com",
  PayStackKey: "pk_live_a36043760d0eb68ef8a09f98553e405a85232cee",
};
