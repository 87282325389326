import {
  Box,
  Button,
  FormLabel,
  Image,
  Input,
  Text,
  FormControl,
  createStandaloneToast,
  Link,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Logo from "../../assets/Icons/Logo.png";
import cookie from "react-cookies";
import { impData } from "../../impData";
import axios from "axios";
import { config } from "../../components/config";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import AuthSuccessModal from "./Tab_components/Reg_pay_info_Partials/AuthSuccessModal";
import { useRecoilValue, useRecoilState } from "recoil";
import { loginEmailState, requestIdState, tokenState } from "../../atoms/store";
import AuthPagesWrapper from "../../components/Templates/AuthPagesWrapper";

const ResetPassword = () => {
  const [requestId, setRequestId] = useRecoilState(requestIdState);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { toast, ToastContainer } = createStandaloneToast();
  const [otpCode, setOtpCode] = useState("");
  const tokenAtomValue = useRecoilValue(tokenState);
  const emailAddress = useRecoilValue(loginEmailState)
  const resendOtp = () => {
    if (emailAddress === "" || emailAddress === " ") {
      toast({
        description: "your must input your email address",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: false,
      })
      navigate("/login")
    }
    axios
      .post(
        `${config.api_url}/api/v3/w3/topupcliq-forgot-password?email=${emailAddress}`
      )
      .then((res) => {
        if (res.data.status === "2000") {
          toast({
            description: res.data.description,
            status: "success",
            position: "top",
            duration: 9000,
            isClosable: false,
          })

          setRequestId(res.data.data.requestId)
        }
        if (res.data.status === "4000") {
          toast({
            description: res.data.description,
            status: "error",
            position: "top",
            duration: 9000,
            isClosable: false,
          })
          navigate("/login")
        }
      })
  };
  // password resent handler
  const handleReset = () => {
    setLoading(true);
    if (confirmPassword !== password) {
      toast({
        status: "error",
        position: "top",
        description: "the passwords must match",
        duration: 6000,
        isClosable: false,
      });
    }
    if (password === "" || otpCode === "" || confirmPassword === "") {
      toast({
        status: "error",
        position: "top",
        description: "all input fields must be filled",
        duration: 6000,
        isClosable: false,
      });
    }
    axios
      .post(
        `${config.api_url}/api/v3/w3/topupcliq-reset-password`,
        {
          password: password,
          requestId: requestId,
          otp: otpCode,
          emailAddress:emailAddress
        },
        {
          headers: {
            authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
            businessName:"topupbox"
          },
        }
      )
      .then((res) => {
        if (res.data.status === "4000") {
          console.log(res.data);
          toast({
            status: "error",
            position: "top",
            description: res.data.description,
            duration: 6000,
            isClosable: false,
          });
        }
        if (res.data.status === "2000") {
          toast({
            status: "success",
            position: "top",
            description: res.data.description,
            duration: 6000,
            isClosable: false,
          });
          navigate("/login");
        }
      })
      .catch((e) => console.log(e));

    setLoading(false);
  };
  useEffect(() => {
    console.log(password, requestId, otpCode);
  }, [password, requestId, otpCode]);

  return (
    <AuthPagesWrapper>
      <Box mx="auto" bg=" #FCFCFC" py="2rem" w={{ base: "100%", md: "55%" }}>
        <Image mx="auto" src={Logo} alt="" />

        <Box
          mt="5rem"
          display={impData.flex}
          flexDir="column"
          gap="1rem"
          mx="auto"
          w={{ base: "80%", md: "80%", lg: "60%" }}
          p={{ base: "10px", md: "40px " }}
          background="#fff"
          boxShadow="0px -20px 40px rgba(38, 59, 149, 0.02), 0px 20px 40px rgba(38, 59, 149, 0.02)"
        >
          <ToastContainer />
          <Text
            as="h3"
            style={{
              fontSize: "24px",
              fontWeight: "600",
              display: "block",

              textAlign: "center",
            }}
          >
            Reset your password
          </Text>

          <FormControl>
            <FormLabel fontSize="12px">Enter new password</FormLabel>
            <Input
              onChange={(e) => setPassword(e.target.value)}
              type="email"
              placeholder="password"
              border="none"
              borderRadius="0"
              borderBottom="1px solid #212337"
              outline="none"
              style={{ outlineStyle: "none" }}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize="12px">Re-enter password</FormLabel>
            <Input
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="email"
              placeholder="reset password"
              border="none"
              borderRadius="0"
              borderBottom="1px solid #212337"
              outline="none"
              style={{ outlineStyle: "none" }}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize="12px" mt="10">
              enter in the otp code sent to you
            </FormLabel>

            <Input
              onChange={(e) => setOtpCode(e.target.value)}
              style={{ outlineStyle: "none" }}
              type="text"
              placeholder="Otp Code"
              border="none"
              borderRadius="0"
              borderBottom="1px solid #212337"
            />
          </FormControl>

          <Button
            bg="#263B95"
            color="#fff"
            gap="1rem"
            borderRadius="50px"
            mt="10"
            _hover={{ backgroundColor: "#263B95" }}
            _focus={{ backgroundColor: "#263B95" }}
            fontSize="18px"
            w="100%"
            fontWeight="700"
            lineHeight="23px"
            h="68px"
            onClick={handleReset}
          >
            Reset password
            <PulseLoader color="#fff" loading={loading} />
          </Button>
          <Text fontSize={{ sm: 14, md: 16 }} textAlign="right">
            Didn't get get any otp code ?{" "}
            <Link
              fontSize={{ sm: 16, md: 18 }}
              onClick={resendOtp}
              color="#263B95"
              fontWeight={600}
            >
              Resend Otp
            </Link>
          </Text>
        </Box>
      </Box>
    </AuthPagesWrapper>
  );
};

export default ResetPassword;
