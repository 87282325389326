import { CloseIcon, SearchIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Text,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  VStack,
} from "@chakra-ui/react";

import React from "react";
import DashModal from "../../../../components/DashModal";
import { impData } from "../../../../impData";
import Ikeja from "../../../../assets/ikeja.png";



import CustomSelect from "../../../../components/CustomSelect";

const SelectPrepaid = () => {
  return (
    <DashModal
      width={{ base: "80%", md: "30%", lg: "450px" }}
      openModal={false}
    >
      <Box
        fontFamily="clash"
        display={impData.flex}
        flexDirection="column"
        alignItems="center"
        w="full"
        gap="1rem"
      >
        <Box
          display={impData.flex}
          fontFamily="clash"
          w="full"
          mt="1rem"
          h="60px"
          alignItems="center"
        >
          <Flex justifyContent="space-between" w="full" mx="1rem">
            <Text fontFamily="clash" fontSize={24} fontWeight={700}>
              Electricity
            </Text>
            <Button
              bg="#2123371A"
              w="44px"
              border={"none"}
              outline="none"
              style={{ border: 0 }}
              borderRadius={50}
              h="44px"
            >
              <CloseIcon />
            </Button>
          </Flex>
        </Box>

        <Box
          h="full"
          w="full"
          p="10px"
          gap="10px"
          bg="#E9EAEC"
          mt="2rem"
          display={impData.flex}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Image src={Ikeja} alt="" />
          <Text fontSize="16px">Ikeja electric prepaid</Text>
        </Box>
        <Box display={impData.flex}  w="full" p="2rem" flexDirection="column" gap="2.5rem">
          {/* <form action=""> */}
          <FormControl>
            <CustomSelect bgText="select a package" />
          </FormControl>
          <FormControl>
            <FormLabel fontSize={12}>meter number</FormLabel>
            <Input
              placeholder="uwi8sos"
              border={0}
              borderBottom="2px solid #212337"
              _focus={{ borderBottomColor: "#263B95" }}
              borderRadius="none"
              py="10px"
            />
          </FormControl>

          <FormControl>
            <Input
              placeholder="Amount(NGN)"
              _placeholder={{ fontWeight: 600, fontSize: 16, color: "#212337" }}
              border={0}
              borderBottom="2px solid #212337"
              _focus={{ borderBottomColor: "#263B95" }}
              borderRadius="none"
              py="10px"
            />
          </FormControl>
          {/* proceed button */}
          <Button
            color="#fff"
            bg="#263B95"
            borderRadius="50px"
            h="56px"
            w="full"
            mt="3rem"
          >
            Proceed
          </Button>
          {/* </form> */}
        </Box>
      </Box>
    </DashModal>
  );
};

export default SelectPrepaid;
