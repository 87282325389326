import {
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Link,
    Text,
    HStack,
    FormHelperText,
    createStandaloneToast,
    PinInputField,
    PinInput,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { config } from "../../../components/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const VerifyEmail = ({
    setPaging,
    toastMessage: ToastMessage,
    email, requestId, setJWT, uuid
}) => {
    const [otp, setOtp] = useState("");
    const [isloading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { toast, ToastContainer } = createStandaloneToast();
    const verifyEmail = () => {
        setIsLoading(true);
        const params = {
            userEmailAddress: email,
            requestId: requestId,
            otp: otp,
        };

        axios
            .post(
                config.api_url + '/api/v3/w3/topupcliq-verify-email',
                params,
            )
            .then(res => {
                if (res.data.status === '2000' ) {
                    setJWT(res.data.data.jwtToken);
                    toast({
                        description: res.data.description,
                        status: "success",
                        position: "top",
                        duration: 6000,
                        isClosable: false,
                    });
                    navigate("/login");
                } else {
                    console.log(params);
                    toast({
                        description: res.data.description,
                        status: "error",
                        position: "top",
                        duration: 6000,
                        isClosable: false,
                    });
                }
            }).catch(err => {
                console.log(err);
                toast({
                    description: "An error occured while verifying email",
                    status: "error",
                    position: "top",
                    duration: 9000,
                    isClosable: false,
                });

            }).finally(res => {
                setIsLoading(false);
            });
    }

    return (
        <Container
            p={{ base: "1rem", md: "40px" }}
            minW="full"
            border={"1px solid #f2f2f2"}
            borderRadius="2xl"
            mt="1rem"
            bg="#fff"
            boxShadow="0px -20px 40px rgba(38, 59, 149, 0.02), 0px 20px 40px rgba(38, 59, 149, 0.02"
        >
            <Text as="h1" fontSize={24} mb="2rem" fontWeight={500}>
                Create New Account
            </Text>
            <ToastMessage />
            <form>
                <Flex flexDir={"column"} gap="1.5rem">
                    <Text color="#21233780">Enter PIN to validate transactions</Text>
                    <HStack>
                        <PinInput
                            focusBorderColor="#212337"
                            type="number"
                            placeholder=""
                            value={otp}
                            onChange={(value) => setOtp(value)}
                        >
                            <PinInputField
                                background="#E9EAEC6B"
                                h="71px"
                                w="71px"
                                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                                fontWeight={700}
                                fontSize={32}
                                borderRadius={16}
                            />
                            <PinInputField
                                background="#E9EAEC6B"
                                h="71px"
                                w="71px"
                                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                                fontWeight={700}
                                fontSize={32}
                                borderRadius={16}
                            />
                            <PinInputField
                                background="#E9EAEC6B"
                                h="71px"
                                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                                fontWeight={700}
                                fontSize={32}
                                w="71px"
                                borderRadius={16}
                            />
                            <PinInputField
                                background="#E9EAEC6B"
                                h="71px"
                                w="71px"
                                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                                fontWeight={700}
                                fontSize={32}
                                borderRadius={16}
                            />
                            <PinInputField
                                background="#E9EAEC6B"
                                h="71px"
                                w="71px"
                                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                                fontWeight={700}
                                fontSize={32}
                                borderRadius={16}
                            />
                            {/* <PinInputField
                                background="#E9EAEC6B"
                                h="71px"
                                w="71px"
                                _focus={{ color: "#212337", border: "2px solid #263B95" }}
                                fontWeight={700}
                                fontSize={32}
                                borderRadius={16}
                            /> */}
                        </PinInput>
                    </HStack>
                    <Button
                        onClick={() => {
                            verifyEmail()
                        }}
                        bg="#263B95"
                        borderRadius="50px"
                        color="#fff"
                        h="56px"
                        d="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontWeight="700"
                        fontSize="16px"
                        _hover={{ background: "#263B95" }}
                        isLoading={isloading}
                    >
                        Next
                    </Button>
                    <Button
                        onClick={() => setPaging(0)}
                        bg="#263B9500"
                        borderRadius="50px"
                        borderColor={"#f2f2f2"}
                        borderWidth="1px"
                        color="#263B95"
                        h="56px"
                        d="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontWeight="700"
                        fontSize="16px"
                        _hover={{ background: "#f2f2f2" }}
                    >
                        Previous
                    </Button>
                    <Text>
                        Already have an account?{" "}
                        <Link
                            color="#263B95"
                            fontSize={17}
                            fontWeight={500}
                            textDecor="none"
                            href="/login"
                        >
                            Login
                        </Link>
                    </Text>
                </Flex>
            </form>
        </Container>
    );
};

export default VerifyEmail;
