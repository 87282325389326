export const sideBarData = [
  {
    Icon: ({ stroke }) => (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 10.9V4.1c0-1.5-.64-2.1-2.23-2.1h-4.04c-1.59 0-2.23.6-2.23 2.1v6.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1ZM22 19.9v-1.8c0-1.5-.64-2.1-2.23-2.1h-4.04c-1.59 0-2.23.6-2.23 2.1v1.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1ZM10.5 13.1v6.8c0 1.5-.64 2.1-2.23 2.1H4.23C2.64 22 2 21.4 2 19.9v-6.8c0-1.5.64-2.1 2.23-2.1h4.04c1.59 0 2.23.6 2.23 2.1ZM10.5 4.1v1.8c0 1.5-.64 2.1-2.23 2.1H4.23C2.64 8 2 7.4 2 5.9V4.1C2 2.6 2.64 2 4.23 2h4.04c1.59 0 2.23.6 2.23 2.1Z"
          stroke={stroke}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    text: "Dashboard",
    link: "/dashboard",
  },
  {
    Icon: ({ stroke }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        // stroke="#292D3299"
        stroke={stroke}
        viewBox="0 0 24 24"
      >
        <path
          // stroke="#292D32"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeOpacity="0.6"
          strokeWidth="1.5"
          d="M17.15 13.82l-3.04 3.04M6.85 13.82h10.3M6.85 10.18l3.04-3.04M17.15 10.18H6.85"
        ></path>
        <path
          stroke="#292D32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.6"
          strokeWidth="1.5"
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        ></path>
      </svg>
    ),
    text: "Transactions",
    link: "/transactions",
  },
  {
    Icon: ({ stroke }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        // stroke="#292D3299"
        stroke={stroke}
        viewBox="0 0 24 24"
      >
        <path
          // stroke="#263B95"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M22 6v2.42C22 10 21 11 19.42 11H16V4.01C16 2.9 16.91 2 18.02 2c1.09.01 2.09.45 2.81 1.17C21.55 3.9 22 4.9 22 6z"
        ></path>
        <path
          //   stroke="#263B95"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M2 7v14c0 .83.94 1.3 1.6.8l1.71-1.28c.4-.3.96-.26 1.32.1l1.66 1.67c.39.39 1.03.39 1.42 0l1.68-1.68c.35-.35.91-.39 1.3-.09l1.71 1.28c.66.49 1.6.02 1.6-.8V4c0-1.1.9-2 2-2H6C3 2 2 3.79 2 6v1z"
        ></path>
        <path
          //   stroke="#263B95"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6 9h6M6.75 13h4.5"
        ></path>
      </svg>
    ),
    text: "Airtime and Bills",
    link: "/airtimeandbills",
  },
  {
    Icon: ({ stroke }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        // stroke="#292D3299"
        stroke={stroke}
        viewBox="0 0 24 24"
      >
        <path
          // stroke="#292D32"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.6"
          strokeWidth="1.5"
          d="M22 13V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7h5"
        ></path>
        <path
          // stroke="#292D32"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.6"
          strokeWidth="1.5"
          d="M19 16v6l2-2M19 22l-2-2M9.95 6.26L8.9 15.73M13.11 6.26l-1.05 9.47M6.53 9.42H16M6 12.58h9.47"
        ></path>
      </svg>
    ),
    text: "Print airtime",
    link: "/printairtime",
  },
  {
    Icon: ({ stroke }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        // stroke="#292D3299"
        stroke={stroke}
        viewBox="0 0 24 24"
      >
        <path
          // stroke="#292D32"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.6"
          strokeWidth="1.5"
          d="M7.4 6.32l8.49-2.83c3.81-1.27 5.88.81 4.62 4.62l-2.83 8.49c-1.9 5.71-5.02 5.71-6.92 0l-.84-2.52-2.52-.84c-5.71-1.9-5.71-5.01 0-6.92zM10.11 13.65l3.58-3.59"
        ></path>
      </svg>
    ),
    text: "Send money",
    link: "/sendmoney",
  },
  {
    Icon: ({ stroke }) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        // stroke="#292D3299"
        stroke={stroke}
        viewBox="0 0 24 24"
      >
        <path
          // stroke="#292D32"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.6"
          strokeWidth="1.5"
          d="M9.16 10.87c-.1-.01-.22-.01-.33 0a4.42 4.42 0 01-4.27-4.43C4.56 3.99 6.54 2 9 2a4.435 4.435 0 01.16 8.87zM16.41 4c1.94 0 3.5 1.57 3.5 3.5 0 1.89-1.5 3.43-3.37 3.5a1.13 1.13 0 00-.26 0M4.16 14.56c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0zM18.34 20c.72-.15 1.4-.44 1.96-.87 1.56-1.17 1.56-3.1 0-4.27-.55-.42-1.22-.7-1.93-.86"
        ></path>
      </svg>
    ),
    text: "Referral History",
    link: "/referrals",
  },
  {
    Icon: ({ stroke }) => (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
          stroke={stroke}
          strokeWidth={1.5}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 12.88v-1.76c0-1.04.85-1.9 1.9-1.9 1.81 0 2.55-1.28 1.64-2.85-.52-.9-.21-2.07.7-2.59l1.73-.99c.79-.47 1.81-.19 2.28.6l.11.19c.9 1.57 2.38 1.57 3.29 0l.11-.19c.47-.79 1.49-1.07 2.28-.6l1.73.99c.91.52 1.22 1.69.7 2.59-.91 1.57-.17 2.85 1.64 2.85 1.04 0 1.9.85 1.9 1.9v1.76c0 1.04-.85 1.9-1.9 1.9-1.81 0-2.55 1.28-1.64 2.85.52.91.21 2.07-.7 2.59l-1.73.99c-.79.47-1.81.19-2.28-.6l-.11-.19c-.9-1.57-2.38-1.57-3.29 0l-.11.19c-.47.79-1.49 1.07-2.28.6l-1.73-.99a1.899 1.899 0 0 1-.7-2.59c.91-1.57.17-2.85-1.64-2.85-1.05 0-1.9-.86-1.9-1.9Z"
          stroke={stroke}
          strokeWidth={1.5}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    text: "Settings",
    link: "/settings",
  },
  {
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#212337"
          d="M13.29 9.29l-4 4a.999.999 0 000 1.42 1 1 0 001.42 0l4-4a1.004 1.004 0 00-1.42-1.42z"
        ></path>
        <path
          fill="#212337"
          d="M12.28 17.4L11 18.67a4.2 4.2 0 01-5.58.4 4 4 0 01-.27-5.93l1.42-1.43a.999.999 0 000-1.42 1 1 0 00-1.42 0l-1.27 1.28a6.15 6.15 0 00-.67 8.07 6.06 6.06 0 009.07.6l1.42-1.42a1.004 1.004 0 10-1.42-1.42zm7.38-14.18a6.18 6.18 0 00-8.13.68L10.45 5a1.091 1.091 0 00-.17 1.61 1 1 0 001.42 0L13 5.3a4.17 4.17 0 015.57-.4 4 4 0 01.27 5.95l-1.42 1.43a1.001 1.001 0 00.325 1.639.999.999 0 001.095-.219l1.42-1.42a6.06 6.06 0 00-.6-9.06z"
        ></path>
      </svg>
    ),
    text: "Share your link",
    type: "share",
    link: "#",
  },
  {
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#FF4040"
          d="M19 5h-6c-.55 0-1-.45-1-1s.45-1 1-1h6c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2h-6c-.55 0-1-.45-1-1s.45-1 1-1h6V5z"
        ></path>
        <path
          fill="#FF4040"
          d="M3.35 11.65l2.79-2.79a.5.5 0 01.86.35V11h7c.55 0 1 .45 1 1s-.45 1-1 1H7v1.79c0 .45-.54.67-.85.35l-2.79-2.79c-.2-.19-.2-.51-.01-.7z"
        ></path>
      </svg>
    ),
    text: "Logout",
    link: "/login",
  },
];


