import {
  Box,
  FormControl,
  Input,
  InputGroup,
  InputRightAddon,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { FaAngleDown } from "react-icons/fa";
const CustomSelect = ({ bgText }) => {
  const angleDown = <FaAngleDown />;
  return (
    <Box >
      <InputGroup
        border="0"
        borderBottom="2px solid #212337"
        _focus={{ borderBottom: "2px solid " }}
      >
        <Input
          fontSize={16}
          style={{ border: 0 }}
          border="none"
          type="text"
          placeholder={bgText}
          className="w-full outline-none border-none block relative border-b-[2px] "
        />
        <InputRightAddon
          border={"none"}
          bg="none"
          as={Button}
          _hover={{background: "none"}}
          style={{ border: 0 }}
        >
          {angleDown}
        </InputRightAddon>
      </InputGroup>

      <Box className="flex flex-col"></Box>
    </Box>
  );
};

export default CustomSelect;
