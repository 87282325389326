import { Box } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../../components/DashboardWrapper";
import { impData } from "../../impData";
import TransactionsIntro from "./Intro/TransactionsIntro";
import cookie from "react-cookies"
import TransactionHistories from "./TransactionHistories/TransactionHistories";
const TransactionsPage = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (
      !cookie.load("TOPUPCLIQ_USER_TOKEN") ||
      cookie.load("TOPUCLIQ_USER_TOKEN") === ""
    ) {
      navigate("/login");
    }
  }, [])
  return (
    <DashboardWrapper header="Transactions">
      <Box bg="#fafafa" p="1rem 2rem" w="full" h="full" fontFamily="clash">
        <TransactionsIntro />
        <Box
          w="full"
          
          h="full"
          display={impData.flex}
          flexDirection="column"
          gap="1.5rem"
        >
          <div className=" w-full mt-[3rem] border-t-[2px] pt-[1.5rem] border-t-[#E9EAEC] ">
            <div className="w-full   flex justify-between items-end ">
              <h1 className="text-xl font-[600]  text[24px] text-[#212337]  ">
                Transaction History
              </h1>
              {/* <a
                href=""
                className=" text-[#263B95] w-fit justify-self-end underline"
              >
                See all transactions
              </a> */}
            </div>
          </div>

          <TransactionHistories />
        </Box>
      </Box>
    </DashboardWrapper>
  );
};

export default TransactionsPage;
