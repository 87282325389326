import React, { useState, useEffect, useRef } from "react";
import { impData } from "../../../impData";
import Logo from "../../../assets/Icons/Logo.png";
import { Image, Link, useDisclosure, Box, createStandaloneToast } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import cookie from "react-cookies";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { sideBarData } from "./data";
import { config } from "../../config";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom"
const DashSidebar = ({ openSideBar, setOpenSidebar }) => {
  const { toast, ToastContainer } = createStandaloneToast();

  const activeLinkStyles = {
    background: " #263B950D",
    borderRight: "4px solid #263B95",
    fontWeight: 500,
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [refreshToken, setRefreshToken] = useState("");
  const mounted = useRef(false);
  useEffect(() => {
    axios
      .get(`${config.api_url}/api/v3/w3/topupcliq-refresh-token`, {
        headers: {
          Authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then((res) => {
        var tokenResponse = res.data.data;
        if (res.data.status == 401) {
          navigate("/Login")
          return;
        }

        cookie.save("TOPUPCLIQ_USER_TOKEN", tokenResponse?.Token, {
          path: "/",
          expires: new Date(Date.now() * 60 * 10 * 1000),
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status == 401 || e.response.status == 500) {
          navigate("/Login")
        }
      });
  }, []);
  return (
    <Box
      ref={mounted}
      transition="2s ease"
      width={{ base: "full", md: "250px", lg: "270px" }}
      pos="fixed"
      zIndex={100}
      h="100vh"
      bg="#FCFCFC"
      borderRightWidth={1}
      borderRightColor={"#E9EAEC"}
      fontFamily="clash"
      display={{
        base: openSideBar ? "flex" : "none",
        md: impData.flex,
        lg: impData.flex,
      }}
      justifyContent="space-between"
      flexDirection="col"
    >
      <div className="flex flex-col items-between  py-[1rem] pl-[1.5rem] w-full">
        <div className="flex items-center w-full justify-between px-[10px]">
          <Link to="/" as={RouterLink}>
            <Image src={Logo} />
          </Link>
          <button
            className="outline-none border-none md:hidden lg:hidden sm:flex p-[10px] rounded-[50%]"
            onClick={() => setOpenSidebar(false)}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="flex flex-col w-full mt-[2rem]">
          {sideBarData
            .filter((item, i) => i < 7)
            .map((Item, idx) => (
              <Link
                key={idx}
                as={RouterLink}
                to={Item.link}
                className="sidebar-link"
                _hover={{ background: "#263b950d", color: "#263b95", borderRight: "4px solid #263b95", fontWeight: 500 }}
                _active={{ background: "#263b950d", color: "#263b95", borderRight: "4px solid #263b95", fontWeight: 500 }}

                style={{
                  marginBottom: 10,
                  display: impData.flex,
                  alignItems: "center",
                  background:
                    Item.link === location.pathname
                      ? activeLinkStyles.background
                      : "",
                  padding: 10,
                  borderRight:
                    Item.link === location.pathname
                      ? activeLinkStyles.borderRight
                      : "",
                  fontSize: 16,
                }}
              >
                <span style={{ marginRight: "1rem", color: "##263B95" }}>
                  <Item.Icon
                    stroke={
                      Item.link === location.pathname ? "#263B95" : "#292D3299"
                    }
                  />
                </span>
                <span
                  style={{
                    color:
                      Item.link === location.pathname ? "#212337" : "#21233799",
                  }}
                >
                  {Item.text}
                </span>
              </Link>
            ))}
        </div>
        <div className="w-full mt-auto">
          {sideBarData
            .filter((item, i) => i > 6)
            .map((item, idx) => (
              <Link
                key={idx}
                as={RouterLink}
                to={item.link}
                onClick={e => {
                  if (item?.type == "share") {
                    e.preventDefault();
                    navigate("/referrals");
                    var copyText = document.getElementById("refferal_code");
                    copyText.select();
                    copyText.setSelectionRange(0, 99999);
                    document.execCommand("copy");
                    toast({
                      title: "Referral link has been copied to clipboard",
                      description: window.origin + "/register/" + cookie.load("TOPUPCLIQ_USER_REFERRAL_CODE"),

                      status: "success",
                      duration: 7000,
                      position: "top",
                      isClosable: true,
                    })
                  }
                }}
                className="sidebar-link"
                _hover={{ fontWeight: idx === 1 ? 500 : "", color: "#212337" }}
                _active={{ fontWeight: idx === 1 ? 500 : "", color: "#212337" }}

                style={{
                  fontSize: 16,
                  display: impData.flex,
                  background: idx === 0 ? "#22AD800D" : "",
                  borderRight: idx === 0 ? "4px solid #22AD80 " : "",
                  alignItems: "center",
                  color: idx === 1 ? "#FF4040" : "#212337",
                  fontWeight: 500,
                  justifySelf: idx === 5 ? "flexEnd" : "",
                }}
              >
                <span
                  style={{
                    marginRight: "1rem",
                    color: idx === 1 ? "#FF4040" : "",
                  }}
                >
                  {item.Icon}
                </span>
                <span>{item.text}</span>
              </Link>
            ))}
        </div>
      </div>
    </Box>
  );
};

export default DashSidebar;
