import { Box, Flex, Link as ChakraLink } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { impData } from "../../../impData";
import { landingpageSideBarAtom } from "../../../atoms/store";
import Logo from "../../../assets/Icons/Logo.png";
import { CloseIcon } from "@chakra-ui/icons";
export default function HomeSideBar({setShowSidebar, showSidebar}) {
 
  return (
    <Box
      width={"100vw"}
      position="fixed"
      height="100vh"
      background="rgba(0, 0, 0, 0.3)"
      transition="ease-in-out 1s"
      left={0}
      display={{ base: showSidebar ? impData.flex : "none", md: "none" }}
      justifyContent="flex-end"
      top={0}
    >
      <Box
        display={impData.flex}
        flexDir="column"
        height="inherit"
        _before={{ background: "rgba(0, 0, 0, 0.5)" }}
        width="80%"
        bg="#fff"
        right={0}
        marginRight={0}
      >
        <Box
          display={impData.flex}
          alignItems="center"
          p="1rem"
          justifyContent="space-between"
        >
          <Link to="/">
            <img src={Logo} style={{ width: "auto", height: "48px" }} alt="" />
          </Link>
          <button onClick={setShowSidebar(false)} className=" outline-none border-none flex">
            <CloseIcon />
          </button>
        </Box>
        {navLinks.map((item, idx) => (
          <Link
            style={{ width: "100%", height: impData.fitContent }}
            key={idx}
            to={item.link}
          >
            <Box as="span"
              width="100%"
              height="100%"
              p="10px"
              textTransform="capitalize"
              fontSize={18}
              fontWeight={500}
              _hover={{ background: "rgba(0, 0, 0, 0.3)", fontWeight: 600 }}
            >
              {item.text}
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
}

const navLinks = [
  {
    link: "/",
    text: "Home",
  },
  {
    link: "/howitworks",
    text: "How It Works",
  },
  {
    link: "/aboutus",
    text: "About Us",
  },
  {
    link: "/faq",
    text: "FAQ",
  },
];
