
import React from 'react'
import { VStack, Box, Flex, Link, Text, Image, } from '@chakra-ui/react'
import InviteIcon from "../../../assets/Icons/inviteIcon.png"
import Earnicon from '../../../assets/Icons/Earn.png'
import registerIcon from "../../../assets/Icons/register.png"
import { impData } from "../../../impData"
const FIrstSection = () => {
  return (
    <Box style={{ display: impData.flex, justifyContent: "center" }} w="100%">
      <Box style={{ display: impData.flex }} flexDir={{ base: 'column', md: "row" }} my="10" mx="20" gap="1.7rem">
        {data.map((item, idx) => (
          <Box
            key={idx}
            w={{ base: "100%", md: "32%" }}
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
            p="1rem"
          >
            <Image src={item.icon} alt="" w="83px" h="83px" />
            <Link
              style={{
                color: "#263B95",
                display: "block",
                textDecoration: "none",
              }}
              fontWeight="bold"
              fontSize="24px"
              my="1rem"
            >
              {item.header}
            </Link>
            <Text color="gray.600" fontSize="16px">
              {item.desc}
              {idx === 1 ? (
                <Link href="#" textDecoration="none" color="#263B95">
                  See T & C’s
                </Link>
              ) : (
                ""
              )}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
}


const data = [
  {
    icon: registerIcon,
    header: "Register",
    desc: "You can make extra cash with referral bonuses when you invite a friend to TopupCliq.  ",
  },
  {
    icon: InviteIcon,
    header: "Invite friends with  link/code",
    desc: "You can make extra cash with referral bonuses when you invite a friend to TopupCliq.   ",
  },
  {
    icon: Earnicon,
    header: "Earn money from referrals",
    desc: "You can make extra cash with referral bonuses when you invite a friend to TopupCliq.    ",
  },
];
export default FIrstSection;
