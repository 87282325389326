import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { impData } from "../../../impData";
import withdrawalIcon from "../../../assets/withdrawal.png";
import persons from "../../../assets/persons.png";
import deposit from "../../../assets/deposit.png";
import gift from "../../../assets/gifts.png";
import { config } from "../../../components/config";
import axios from "axios";
import cookie from "react-cookies";
const TransactionsIntro = () => {
  const [totalReferralEarning, setTotalReferralEarning] = useState(0)
  const [referralCodeCount, setReferralCodeCount] = useState(0)
  useEffect(() => {
    axios
      .get(`${config.api_url}/api/v3/w3/referal-history`, {
        headers: {
          authorization: cookie.load("TOPUPCLIQ_USER_TOKEN"),
        },
      })
      .then((res) => {
        if(res.data.status === "2000"){
          setTotalReferralEarning(res.data.data.totalReferralBonus)
          setReferralCodeCount(res.data.data.referralCodeCount)
        }
      })
      .catch((e) => console.log(e));
  }, []);


  let intros = [
    {
      title: "Total referral earnings",
      icon: persons,
      amount: `NGN ${totalReferralEarning}`,
    },
  
    {
      title: "Deposit",
  
      icon: deposit,
      amount: "NGN 0",
    },
  
    {
      title: "Withdrawal",
  
      icon: withdrawalIcon,
      amount: "NGN 0",
    },
    {
      title: "Bonus earned",
  
      icon: gift,
      amount: referralCodeCount,
    },
  ];
  return (
    <Box
      display={impData.flex}
      flexDirection="row"
      gap="1rem"
      flexWrap={{ base: "wrap", md: "wrap", lg: "nowrap" }}
      alignItems="center"
    >
      {intros.map((item, idx) => {
        return (
          <Box
            w={{ base: "47%", md: "47%", lg: "30%" }}
            p="15px"
            borderRadius="10px"
            border="1.5px solid #E9EAEC"
            h="131px"
            display={impData.flex}
            flexDirection="column"
            gap="10px"
            justifyContent="center"
          >
            <img src={item.icon} className="w-[44px] h-[44px]" alt="" />
            <p className="text-[#212337] text-sm">{item.title}</p>
            <h1 className="text-[18px] text-[#212337] font-bold">{item.amount}</h1>
          </Box>
        );
      })}
    </Box>
  );
};



export default TransactionsIntro;
