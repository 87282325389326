import {
  Box,
  Button,
  Text,
  Input,
  InputAddon,
  InputGroup,
  InputRightAddon,
  createStandaloneToast,

} from "@chakra-ui/react";
import React from "react";
import { impData } from "../../impData";
import cookie from "react-cookies";

const ReferralLink = () => {
  const [referralCode, setReferralCode] = React.useState(cookie.load("TOPUPCLIQ_USER_REFERRAL_CODE"));
  const { toast, ToastContainer } = createStandaloneToast();

  return (
    <Box
      display={impData.flex}
      flexDir="column"
      p="1.5rem 2rem"
      fontFamily="clash"
      border="1.5px solid #E9EAEC"
      h="206px"
      borderRadius="10px"
      bg="#fff"
      alignItems="center"
      gap="1rem"
      w={{ base: "full", md: "100%", lg: "44%" }}
    >
      <Text w="70%" textAlign="center" color="rgba(33, 35, 55, 0.6)" fontSize="16px" fontWeight="500">
        You can invite a friend by sharing your code below or the link address
      </Text>
      <Text fontSize={24} fontWeight={600} color="#263B95">
        {
          cookie.load("TOPUPCLIQ_USER_REFERRAL_CODE") ? cookie.load("TOPUPCLIQ_USER_REFERRAL_CODE") : "No referral code"
        }
      </Text>
      <InputGroup>
        <Input
          readOnly
          borderRight="none"
          value={window.origin + "/register/" + referralCode}
          color="#263B95"
          fontWeight={"bold"}
        />

        <InputRightAddon
          onClick={() => {
            navigator.clipboard.writeText(window.origin + "/register/" + referralCode);
            toast({
              title: "Referral link has been copied to clipboard",
              description: window.origin + "/register/" + cookie.load("TOPUPCLIQ_USER_REFERRAL_CODE"),

              status: "success",
              duration: 7000,
              position: "top",
              isClosable: true,
            })
          }}
          as="button"
          //   overflow="hidden"
          fontFamily="clash"
          bg="#263B95"
          color="#fff"
          fontWeight={"bold"}
          w="25%"
          textAlign="center"
          display={impData.flex}
          justifyContent="center"
          alignItems="center"
          borderRadius={10}
          fontSize={16}
        >
          Share link
        </InputRightAddon>
      </InputGroup>
    </Box>
  );
};

export default ReferralLink;
