import React, { useState } from "react";
import nineMobile from "../../../assets/9mobile.png";
import mtnLogo from "../../../assets/mtn.png";
import airtel from "../../../assets/airtel.png";
import glo from "../../../assets/glo.png";
import { impData } from "../../../impData";

import { Box, Image, Link, Text, useDisclosure } from "@chakra-ui/react";

const Networks = ({ chooseNetworkHandler }) => {
  const [active, setActive] = useState(false);

  return (
    <>
      <Box display={impData.flex} h="50%" gap="1rem" flexWrap="wrap">
        {networks.map((item, idx) => (
          <Box
            cursor="pointer"
            w={{ base: "47%", md: "45%" }}
            onClick={() => chooseNetworkHandler(item, idx)}
            key={idx}
            textTransform="uppercase"
            borderRadius="10px"
            // h="140px"
            border={active ? "1px solid #263B95" : "1.5px solid #E9EAEC "}
            _hover={{ cursor: "pointer" }}
            display={impData.flex}
            flexDirection="column"
            p="1rem"
            gap="1rem"
            alignItems="center"
          >
            <Image w="80px" h="80px" src={item.image} mx="auto" />
            <Text fontWeight={500} as="h1" fontSize="16px">
              {item.companyName}
            </Text>
          </Box>
        ))}
      </Box>
    </>
  );
};

const networks = [
  {
    image: nineMobile,
    companyName: "9mobile",
  },
  {
    image: mtnLogo,
    companyName: "MTN",
  },
  {
    image: airtel,
    companyName: "Airtel",
  },
  {
    image: glo,
    companyName: "GLO",
  },
];

export default Networks;
