import React, { useMemo, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useRecoilValue } from "recoil";
import nineMobile from "../assets/9mobile.png";
import airtel from "../assets/airtel.png";
import glo from "../assets/glo.png";
import mtn from "../assets/mtn.png";
import { chooseNetworkAtom } from "../atoms/store";
const PrintAirtimeDropDown = ({ value }) => {
  const angleDown = <FaAngleDown />;
  const [dropDown, setdropDown] = useState(false);
  const [companyImage, setCompanyImage] = useState();
  const [inpValue, setInpValue] = useState("9mobile");
  // const [networkImage, setNetworkImage] = useState("")

  const setCompanyHandler = (companyName, companyImage, value) => {
    setCompanyImage(companyImage);
    setInpValue(companyName);
  };
  const networkName = useRecoilValue(chooseNetworkAtom);

  const showDropdownHandler = () => setdropDown(!true);
  useMemo(() => {
    if (networkName === "9MOBILE") {
      setCompanyImage(nineMobile)
    }
    if (networkName === "AIRTEL") {
      setCompanyImage(airtel)
    }
    if (networkName === "GLO") {
      setCompanyImage(glo)
    }
    if (networkName === "MTN") {
      setCompanyImage(mtn)
    }
  }, []);
  return (
    <div className="dropDown-parent p-0 block sm:w-full md:w-[259px] lg:w-[259px]">
      <div

        className="flex px-[10px] w-[259px]  rounded-full h-[52px]  overflow-hidden items-center bg-[#E9EAEC]"
      >

        <img
          src={companyImage ? companyImage : nineMobile}
          className="w-[40px] h-[40px] "
          alt=""
        />
        <div
          className="cursor-pointer border-0 outline-none  bg-none font-medium pl-[10px]"
        >
          {/* {value ? value.name: "9Mobile"} */}
          {networkName}
        </div>
        {/* <span onClick={showDropdownHandler}>{angleDown}</span> */}
      </div>
      <div
        className={`drop-down mt-[1rem] ${dropDown ? "block" : "hidden"
          } overflow-hidden bg-white rounded-[10px] shadow shadow-2xl`}
      >
        {companies.map((item, idx) => {
          return (
            <div
              className="w-full hover:bg-[#E9EAEC] p-[10px] flex justify-between  border-b-[1.5px]"
              onClick={() => setCompanyHandler(item.companyName, item.image)}
              key={idx}
            >
              <span>{item.companyName}</span>
              <img src={item.image} alt="" className="w-[40px] h-[37px]" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const companies = [
  {
    companyName: "9mobile",
    image: nineMobile,
  },
  {
    companyName: "Airtel",
    image: airtel,
  },
  {
    companyName: "glo",
    image: glo,
  },
  {
    companyName: "MTN",
    image: mtn,
  },
];
export default PrintAirtimeDropDown;
