import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
const DashModal = ({ children, height, width, openModal, onClose }) => {
  return (
    <Modal isOpen={openModal} onClose={onClose} closeOnOverlayClick={true}>
      <ModalOverlay
        background="rgba(33, 35, 55, 0.1)"
        style={{
          background: "rgba(33, 35, 55, 0.7)",
          backdropFilter: "blur(3px)",
        }}
        backdropBlur="5px"
      />
      <ModalContent m="auto" className=" h-[80vh] overflow-y-auto"  p="0" maxW={width} w={width}>
        <ModalBody m="auto" w="full" p="0">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DashModal;
